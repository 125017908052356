import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchRouteList = createAsyncThunk('routeList/getAllRouteList', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleMasterService.getAllRouteList(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addRouteList = createAsyncThunk('routeList/addRouteList', async (routeList, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addRouteList(routeList)
    dispatch(fetchRouteList(getState()?.routeList.params))
    return routeList
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateRouteList = createAsyncThunk('routeList/updateRouteList', async (routeList, { dispatch, getState }) => {
  const response = await VehicleMasterService.updateRouteList(routeList)
  dispatch(fetchRouteList(getState()?.routeList.params))
  return response.data
})

export const deleteRouteList = createAsyncThunk('routeList/deleteRouteList', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteRouteList(id)
  await dispatch(fetchRouteList(getState()?.routeList.params))
  return id
})

const routeListSlice = createSlice({
  name: 'routeList',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchRouteList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateRouteList.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const routeList = state.routeList.filter((routeList) => routeList.id !== id)
        state.routeList = [...routeList, action.payload]
      })
  }
})

export const selectRouteListById = (state, id) => state.routeList.data.find((routeList) => routeList.id === id)

export default routeListSlice.reducer

import api from '@services'

const serviceBaseUrl = process.env.REACT_APP_VEHICLE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle/api`

// const servicePrefix = '/svc/vehicle/api'
import { stripEmptyObjects } from '@utils'

//Color
const getAllOfficeLocations = async () => {
  const response = await api.get(`${servicePrefix}/office-locations/all`)
  return response.data
}

//Color
const getAllColor = async (params) => {
  const response = await api.get(`${servicePrefix}/colors`, { params: stripEmptyObjects(params) })
  return response.data
}

const getColorAll = async () => {
  const response = await api.get(`${servicePrefix}/colors/all`)
  return response
}

const getColor = async (id) => {
  const response = await api.get(`${servicePrefix}/colors/${id}`)
  return response.data
}

const addColor = async (color) => {
  const response = await api.post(`${servicePrefix}/colors`, color)
  return response.data
}

const updateColor = async (color) => {
  const { id } = color
  const response = await api.patch(`${servicePrefix}/colors/${id}`, color)
  return response.data
}

const deleteColor = async (id) => {
  const response = await api.delete(`${servicePrefix}/colors/${id}`)
  return response.data
}

//Dealers
const getAllDealer = async (params) => {
  const response = await api.get(`${servicePrefix}/dealers`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDealerAll = async () => {
  const response = await api.get(`${servicePrefix}/dealers/all`)
  return response
}

const getDealer = async (id) => {
  const response = await api.get(`${servicePrefix}/dealers/${id}`)
  return response.data
}

const addDealer = async (dealer) => {
  const response = await api.post(`${servicePrefix}/dealers`, dealer)
  return response.data
}

const updateDealer = async (dealer) => {
  const { id } = dealer
  const response = await api.patch(`${servicePrefix}/dealers/${id}`, dealer)
  return response.data
}

const deleteDealer = async (id) => {
  const response = await api.delete(`${servicePrefix}/dealers/${id}`)
  return response.data
}

//Engine Type
const getAllEngineType = async (params) => {
  const response = await api.get(`${servicePrefix}/engine-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getEngineType = async (id) => {
  const response = await api.get(`${servicePrefix}/engine-types/${id}`)
  return response.data
}

const addEngineType = async (engineType) => {
  const response = await api.post(`${servicePrefix}/engine-types`, engineType)
  return response.data
}

const updateEngineType = async (engineType) => {
  const { id } = engineType
  const response = await api.patch(`${servicePrefix}/engine-types/${id}`, engineType)
  return response.data
}

const deleteEngineType = async (id) => {
  const response = await api.delete(`${servicePrefix}/engine-types/${id}`)
  return response.data
}

//Registration code
const getAllRegistrationCode = async (params) => {
  const response = await api.get(`${servicePrefix}/registration-codes`, { params: stripEmptyObjects(params) })
  return response.data
}

const getRegistrationCode = async (id) => {
  const response = await api.get(`${servicePrefix}/registration-codes/${id}`)
  return response.data
}

const addRegistrationCode = async (code) => {
  const response = await api.post(`${servicePrefix}/registration-codes`, code)
  return response.data
}

const updateRegistrationCode = async (code) => {
  const { id } = code
  const response = await api.patch(`${servicePrefix}/registration-codes/${id}`, code)
  return response.data
}

const deleteRegistrationCode = async (id) => {
  const response = await api.delete(`${servicePrefix}/registration-codes/${id}`)
  return response.data
}

//Country Diplomat Codes

const getAllCountryDiplomatCodes = async () => {
  const response = await api.get(`${servicePrefix}/country-diplomat-codes/all`)
  return response.data
}

//Vehicle Companies
const getAllVehicleCompany = async (params) => {
  const response = await api.get(`${servicePrefix}/vehicle-companies`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleCompanyAll = async () => {
  const response = await api.get(`${servicePrefix}/vehicle-companies/all`)
  return response
}

const getVehicleCompany = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicle-companies/${id}`)
  return response.data
}

const addVehicleCompany = async (company) => {
  const response = await api.post(`${servicePrefix}/vehicle-companies`, company)
  return response.data
}

const updateVehicleCompany = async (company) => {
  const { id } = company
  const response = await api.patch(`${servicePrefix}/vehicle-companies/${id}`, company)
  return response.data
}

const deleteVehicleCompany = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicle-companies/${id}`)
  return response.data
}

//Vehicle Model Names
const getAllVehicleModelName = async (params) => {
  const response = await api.get(`${servicePrefix}/vehicle-model-names`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleModelNameAll = async () => {
  const response = await api.get(`${servicePrefix}/vehicle-model-names/all`)
  return response
}

const getVehicleModelName = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicle-model-names/${id}`)
  return response
}

const addVehicleModelName = async (modelName) => {
  const response = await api.post(`${servicePrefix}/vehicle-model-names`, modelName)
  return response.data
}

const updateVehicleModelName = async (modelName) => {
  const { id } = modelName
  const response = await api.patch(`${servicePrefix}/vehicle-model-names/${id}`, modelName)
  return response.data
}

const deleteVehicleModelName = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicle-model-names/${id}`)
  return response.data
}

//Vehicle Model variant Details
const getAllVehicleModel = async (params) => {
  const response = await api.get(`${servicePrefix}/vehicle-models`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleModelByName = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicle-models/search-by-model-name/${id}`)
  return response
}

const getVehicleModel = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicle-models/${id}`)
  return response.data
}

const addVehicleModel = async (model) => {
  const response = await api.post(`${servicePrefix}/vehicle-models`, model)
  return response.data
}

const updateVehicleModel = async (model) => {
  const { id } = model
  const response = await api.patch(`${servicePrefix}/vehicle-models/${id}`, model)
  return response.data
}

const deleteVehicleModel = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicle-models/${id}`)
  return response.data
}

//Vehicle Type Details
const getAllVehicleType = async (params) => {
  const response = await api.get(`${servicePrefix}/vehicle-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const fetchAllVehicleType = async () => {
  const response = await api.get(`${servicePrefix}/vehicle-types/all`)
  return response
}

const getVehicleType = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicle-types/${id}`)
  return response.data
}

const addVehicleType = async (type) => {
  const response = await api.post(`${servicePrefix}/vehicle-types`, type)
  return response.data
}

const updateVehicleType = async (type) => {
  const { id } = type
  const response = await api.patch(`${servicePrefix}/vehicle-types/${id}`, type)
  return response.data
}

const deleteVehicleType = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicle-types/${id}`)
  return response.data
}

//Vehicle Fitnesses
const getAllFitnesses = async (params) => {
  const response = await api.get(`${servicePrefix}/fitnesses`, { params: stripEmptyObjects(params) })
  return response.data
}

const getFitnessesAll = async () => {
  const response = await api.get(`${servicePrefix}/fitnesses`)
  return response.data
}

const getFitnesses = async (id) => {
  const response = await api.get(`${servicePrefix}/fitnesses/${id}`)
  return response.data
}

const getVehicleFitnessAll = async () => {
  const response = await api.get(`${servicePrefix}/fitnesses/all`)
  return response.data
}

const addFitnesses = async (type) => {
  const response = await api.post(`${servicePrefix}/fitnesses`, type)
  return response.data
}

const updateFitnesses = async (type) => {
  const { id } = type
  const response = await api.patch(`${servicePrefix}/fitnesses/${id}`, type)
  return response.data
}

const deleteFitnesses = async (id) => {
  const response = await api.delete(`${servicePrefix}/fitnesses/${id}`)
  return response.data
}

//cancellation type
const getAllCancellationType = async (params) => {
  const response = await api.get(`${servicePrefix}/cancellation-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getCancellationTypeAll = async () => {
  const response = await api.get(`${servicePrefix}/cancellation-types/all`)
  return response.data
}

const getCancellationType = async (id) => {
  const response = await api.get(`${servicePrefix}/cancellation-types/${id}`)
  return response.data
}

const addCancellationType = async (type) => {
  const response = await api.post(`${servicePrefix}/cancellation-types`, type)
  return response.data
}

const updateCancellationType = async (type) => {
  const { id } = type
  const response = await api.patch(`${servicePrefix}/cancellation-types/${id}`, type)
  return response.data
}

const deleteCancellationType = async (id) => {
  const response = await api.delete(`${servicePrefix}/cancellation-types/${id}`)
  return response.data
}

//cancellation reasons
const getAllCancellationReasons = async (params) => {
  const response = await api.get(`${servicePrefix}/cancellation-reasons`, { params: stripEmptyObjects(params) })
  return response.data
}

const getCancellationReasonsAll = async () => {
  const response = await api.get(`${servicePrefix}/cancellation-reasons`)
  return response.data
}

const getCancellationReasons = async (id) => {
  const response = await api.get(`${servicePrefix}/cancellation-reasons/${id}`)
  return response.data
}

const addCancellationReasons = async (reasons) => {
  const response = await api.post(`${servicePrefix}/cancellation-reasons`, reasons)
  return response.data
}

const updateCancellationReasons = async (reasons) => {
  const { id } = reasons
  const response = await api.patch(`${servicePrefix}/cancellation-reasons/${id}`, reasons)
  return response.data
}

const deleteCancellationReasons = async (id) => {
  const response = await api.delete(`${servicePrefix}/cancellation-reasons/${id}`)
  return response.data
}

//Transfer types
const getAllTransferTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/transfer-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getTransferTypesAll = async () => {
  const response = await api.get(`${servicePrefix}/transfer-types/all`)
  return response.data
}

const getTransferType = async (id) => {
  const response = await api.get(`${servicePrefix}/transfer-types/${id}`)
  return response.data
}

const addTransferType = async (types) => {
  const response = await api.post(`${servicePrefix}/transfer-types`, types)
  return response.data
}

const updateTransferType = async (types) => {
  const { id } = types
  const response = await api.patch(`${servicePrefix}/transfer-types/${id}`, types)
  return response.data
}

const deleteTransferType = async (id) => {
  const response = await api.delete(`${servicePrefix}/transfer-types/${id}`)
  return response.data
}

//Conversion types
const getAllConversionTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/conversion-types`, { params: stripEmptyObjects(params) })
  return response.data
}

// const getConversionTypesAll = async () => {
//   const response = await api.get(`${servicePrefix}/conversion-types/all`)
//   return response.data
// }

const getConversionType = async (id) => {
  const response = await api.get(`${servicePrefix}/conversion-types/${id}`)
  return response.data
}

const getConversionTypeByRegistrationCodeId = async (registrationCodeId) => {
  const response = await api.get(`${servicePrefix}/conversion-types/getBy/${registrationCodeId}`)
  return response.data
}

const addConversionType = async (conversion) => {
  const response = await api.post(`${servicePrefix}/conversion-types`, conversion)
  return response.data
}

const updateConversionType = async (conversion) => {
  const { id } = conversion
  const response = await api.patch(`${servicePrefix}/conversion-types/${id}`, conversion)
  return response.data
}

const deleteConversionType = async (id) => {
  const response = await api.delete(`${servicePrefix}/conversion-types/${id}`)
  return response.data
}

//Route-lists
const getAllRouteList = async (params) => {
  const response = await api.get(`${servicePrefix}/route-lists`, { params: stripEmptyObjects(params) })
  return response.data
}

const getRouteListAll = async () => {
  const response = await api.get(`${servicePrefix}/route-lists/all`)
  return response.data
}

const getRouteList = async (id) => {
  const response = await api.get(`${servicePrefix}/route-lists/${id}`)
  return response.data
}

const addRouteList = async (route) => {
  const response = await api.post(`${servicePrefix}/route-lists`, route)
  return response.data
}

const updateRouteList = async (route) => {
  const { id } = route
  const response = await api.patch(`${servicePrefix}/route-lists/${id}`, route)
  return response.data
}

const deleteRouteList = async (id) => {
  const response = await api.delete(`${servicePrefix}/route-lists/${id}`)
  return response.data
}

//Bus category types
const getAllBusCategoryTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/bus-category-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getBusCategoryTypesAll = async () => {
  const response = await api.get(`${servicePrefix}/bus-category-types/all`)
  return response.data
}

const getBusCategoryType = async (id) => {
  const response = await api.get(`${servicePrefix}/bus-category-types/${id}`)
  return response.data
}

const addBusCategoryType = async (category) => {
  const response = await api.post(`${servicePrefix}/bus-category-types`, category)
  return response.data
}

const updateBusCategoryType = async (category) => {
  const { id } = category
  const response = await api.patch(`${servicePrefix}/bus-category-types/${id}`, category)
  return response.data
}

const deleteBusCategoryType = async (id) => {
  const response = await api.delete(`${servicePrefix}/bus-category-types/${id}`)
  return response.data
}

//vehicle-life-spans
const getAllVehicleLifeSpans = async (params) => {
  const response = await api.get(`${servicePrefix}/vehicle-life-spans`, { params: stripEmptyObjects(params) })
  return response.data
}

// const getVehicleLifeSpansAll = async () => {
//   const response = await api.get(`${servicePrefix}/vehicle-life-spans/all`)
//   return response.data
// }

const getVehicleLifeSpans = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicle-life-spans/${id}`)
  return response.data
}

const addVehicleLifeSpans = async (liveSpan) => {
  const response = await api.post(`${servicePrefix}/vehicle-life-spans`, liveSpan)
  return response.data
}

const updateVehicleLifeSpans = async (liveSpan) => {
  const { id } = liveSpan
  const response = await api.patch(`${servicePrefix}/vehicle-life-spans/${id}`, liveSpan)
  return response.data
}

const deleteVehicleLifeSpans = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicle-life-spans/${id}`)
  return response.data
}

//Registration Number Sequences
const getAllVehicNumberSequence = async (params) => {
  const response = await api.get(`${servicePrefix}/registration-number-sequences`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleNumberSequence = async (id) => {
  const response = await api.get(`${servicePrefix}/registration-number-sequences/${id}`)
  return response.data
}

const addVehicleNumberSequence = async (numberSequence) => {
  const response = await api.post(`${servicePrefix}/registration-number-sequences`, numberSequence)
  return response.data
}

const updateVehicleNumberSequence = async (numberSequence) => {
  const { id } = numberSequence
  const response = await api.patch(`${servicePrefix}/registration-number-sequences/${id}`, numberSequence)
  return response.data
}

const deleteVehicleNumberSequence = async (id) => {
  const response = await api.delete(`${servicePrefix}/registration-number-sequences/${id}`)
  return response.data
}

const VehicleMasterService = {
  getAllOfficeLocations,

  //conversion types
  getAllConversionTypes,
  getConversionType,
  getConversionTypeByRegistrationCodeId,
  addConversionType,
  updateConversionType,
  deleteConversionType,

  //Transfer Types
  getAllTransferTypes,
  getTransferTypesAll,
  getTransferType,
  addTransferType,
  updateTransferType,
  deleteTransferType,

  //Cancellation Reasons
  getAllCancellationReasons,
  getCancellationReasonsAll,
  getCancellationReasons,
  addCancellationReasons,
  updateCancellationReasons,
  deleteCancellationReasons,

  //Cancellation Type
  getAllCancellationType,
  getCancellationTypeAll,
  getCancellationType,
  addCancellationType,
  updateCancellationType,
  deleteCancellationType,

  //Fitnesses
  getAllFitnesses,
  getFitnesses,
  addFitnesses,
  updateFitnesses,
  deleteFitnesses,
  getFitnessesAll,
  getVehicleFitnessAll,

  //Color
  getAllColor,
  getColorAll,
  getColor,
  addColor,
  updateColor,
  deleteColor,

  //Dealers
  getAllDealer,
  getDealerAll,
  getDealer,
  addDealer,
  updateDealer,
  deleteDealer,

  //Engine Type
  getAllEngineType,
  getEngineType,
  addEngineType,
  updateEngineType,
  deleteEngineType,

  //Registration Code
  getAllRegistrationCode,
  getRegistrationCode,
  addRegistrationCode,
  updateRegistrationCode,
  deleteRegistrationCode,

  //Country Diplomat Codes
  getAllCountryDiplomatCodes,

  //Vehicle Company
  getAllVehicleCompany,
  getVehicleCompanyAll,
  getVehicleCompany,
  addVehicleCompany,
  updateVehicleCompany,
  deleteVehicleCompany,

  //Vehicle Model Names
  getAllVehicleModelName,
  getVehicleModelNameAll,
  getVehicleModelName,
  addVehicleModelName,
  updateVehicleModelName,
  deleteVehicleModelName,

  //Vehicle Model Details
  getAllVehicleModel,
  getVehicleModelByName,
  getVehicleModel,
  addVehicleModel,
  updateVehicleModel,
  deleteVehicleModel,

  //Vehicle Type
  getAllVehicleType,
  getVehicleType,
  addVehicleType,
  updateVehicleType,
  deleteVehicleType,
  fetchAllVehicleType,

  //Route-list
  getAllRouteList,
  getRouteListAll,
  getRouteList,
  addRouteList,
  updateRouteList,
  deleteRouteList,

  //Bus category types
  getAllBusCategoryTypes,
  getBusCategoryTypesAll,
  getBusCategoryType,
  addBusCategoryType,
  updateBusCategoryType,
  deleteBusCategoryType,

  //vehicle life spans
  getAllVehicleLifeSpans,
  // getVehicleLifeSpansAll,
  getVehicleLifeSpans,
  addVehicleLifeSpans,
  updateVehicleLifeSpans,
  deleteVehicleLifeSpans,

  //Registration Number Sequences
  getAllVehicNumberSequence,
  getVehicleNumberSequence,
  addVehicleNumberSequence,
  updateVehicleNumberSequence,
  deleteVehicleNumberSequence
}
export default VehicleMasterService

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ReportService from '@services/report'

export const getReportConfigurations = createAsyncThunk('reportConfigurations/getDrivingTestConfigurations', async (params, { rejectWithValue }) => {
  try {
    const response = await ReportService.getReportConfigurations(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addReportConfiguration = createAsyncThunk('reportConfigurations/addReportConfiguration', async (data, { rejectWithValue }) => {
  // await axios.post('/apps/users/add-user', user)

  try {
    const response = await ReportService.addReportConfiguration(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateReportConfiguration = createAsyncThunk('reportConfigurations/updateReportConfiguration', async (data, { rejectWithValue }) => {
  try {
    const { id } = data
    console.log('hihi', id)
    const response = await ReportService.updateDrivingTestConfiguration(id, data)

    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getReportConfiguration = createAsyncThunk('reportConfigurations/reportConfiguration', async (id, { rejectWithValue }) => {
  try {
    const response = await ReportService.getReportConfiguration(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

const ReportConfiguration = createSlice({
  name: 'reportConfigurations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: true,
    errors: null,
    selectedReportConfiguration: null,
    candidates: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getReportConfigurations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedReportConfiguration = null
      })
      .addCase(getReportConfigurations.pending, (state) => {
        state.selectedReportConfiguration = null
        state.loading = true
      })
      .addCase(getReportConfiguration.fulfilled, (state, action) => {
        state.loading = false
        state.errors = null
        state.selectedReportConfiguration = action.payload
      })
      .addCase(getReportConfiguration.rejected, (state, action) => {
        state.selectedReportConfiguration = null
        state.errors = action.payload
        state.loading = false
      })

      .addCase(updateReportConfiguration.pending, (state) => {
        state.selectedReportConfiguration = null
        state.loading = true
      })
      .addCase(updateReportConfiguration.fulfilled, (state, action) => {
        state.loading = false
        state.errors = null
        state.selectedReportConfiguration = action.payload
      })
      .addCase(updateReportConfiguration.rejected, (state, action) => {
        state.selectedReportConfiguration = null
        state.errors = action.payload
        state.loading = false
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default ReportConfiguration.reducer

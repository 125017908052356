import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getCrashInformations = async (params) => {
  const response = await api.get(`${servicePrefix}/crash-information`, { params: stripEmptyObjects(params) })
  return response.data
}

const addCrashInformation = async (crashData) => {
  const response = await api.post(`${servicePrefix}/crash-information`, crashData)
  return response.data
}

const getCrashInformationById = async (id) => {
  const response = await api.get(`${servicePrefix}/crash-information/${id}`)
  return response.data
}

const updateCrashInformation = async (id, crashData) => {
  const response = await api.patch(`${servicePrefix}/crash-information/${id}`, crashData)
  return response.data
}

const deleteCrashInformation = async (id) => {
  const response = await api.delete(`${servicePrefix}/crash-information/${id}`)
  return response.data
}

const crashAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/crash-information/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//Passenger

const addCrashPassengerInformation = async (crashData) => {
  const response = await api.post(`${servicePrefix}/passenger-crash-information`, crashData)
  return response.data
}

const getPassengerCrashByCrashId = async (crashInfoId) => {
  const response = await api.get(`${servicePrefix}/passenger-crash-information/get-by-crash-info/${crashInfoId}`)
  return response.data
}

const getPassengerCrashById = async (id) => {
  const response = await api.get(`${servicePrefix}/passenger-crash-information/${id}`)

  return response.data
}

const updatePassengerCrashInformation = async (id, passenger) => {
  const response = await api.patch(`${servicePrefix}/passenger-crash-information/${id}`, passenger)
  return response.data
}

const deletePassengerCrashInformation = async (id) => {
  const response = await api.delete(`${servicePrefix}/passenger-crash-information/${id}`)
  return response.data
}

// Vehicle

const addCrashVehicleInformation = async (crashData) => {
  const response = await api.post(`${servicePrefix}/vehicle-crash-information`, crashData)
  return response.data
}

const getVehicleCrashByCrashId = async (crashInfoId) => {
  const response = await api.get(`${servicePrefix}/vehicle-crash-information/get-by-crash-info/${crashInfoId}`)
  return response.data
}

const updateVehicleCrashInformation = async (id, vehicleCrashData) => {
  const response = await api.patch(`${servicePrefix}/vehicle-crash-information/${id}`, vehicleCrashData)
  return response.data
}

const getVehicleById = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicle-crash-information/${id}`)
  return response.data
}

const deleteVehicleCrashInformations = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicle-crash-information/${id}`)
  return response.data
}

//Non Motorist

const addCrashNonMotoristInformation = async (crashData) => {
  const response = await api.post(`${servicePrefix}/non-motorist-crash-information`, crashData)
  return response.data
}

const getNonMotoristCrashByCrashId = async (crashInfoId) => {
  const response = await api.get(`${servicePrefix}/non-motorist-crash-information/get-by-crash-info/${crashInfoId}`)
  return response.data
}

const getNonMotoristById = async (id) => {
  const response = await api.get(`${servicePrefix}/non-motorist-crash-information/${id}`)
  return response.data
}

const updateNonMotoristCrashInformation = async (id, motorist) => {
  const response = await api.patch(`${servicePrefix}/non-motorist-crash-information/${id}`, motorist)
  return response.data
}

const deleteNonMotoristCrashInformation = async (id) => {
  const response = await api.delete(`${servicePrefix}/non-motorist-crash-information/${id}`)
  return response.data
}

//driver

const addCrashDriverInformation = async (crashData) => {
  const response = await api.post(`${servicePrefix}/driver-crash-information`, crashData)
  return response.data
}

const getDriverCrashByCrashId = async (crashInfoId) => {
  const response = await api.get(`${servicePrefix}/driver-crash-information/get-by-crash-info/${crashInfoId}`)
  return response.data
}

const getDriverById = async (id) => {
  const response = await api.get(`${servicePrefix}/driver-crash-information/${id}`)
  return response.data
}

const updateDriverCrashInformation = async (id, driver) => {
  const response = await api.patch(`${servicePrefix}/driver-crash-information/${id}`, driver)
  return response.data
}

const deleteDriverCrashInformation = async (id) => {
  const response = await api.delete(`${servicePrefix}/driver-crash-information/${id}`)
  return response.data
}

const CrashInformationService = {
  getCrashInformations,
  addCrashInformation,
  getCrashInformationById,
  updateCrashInformation,
  deleteCrashInformation,
  addCrashVehicleInformation,
  addCrashDriverInformation,
  addCrashPassengerInformation,
  addCrashNonMotoristInformation,
  getVehicleCrashByCrashId,
  getDriverCrashByCrashId,
  getPassengerCrashByCrashId,
  getNonMotoristCrashByCrashId,
  updateVehicleCrashInformation,
  updatePassengerCrashInformation,
  getPassengerCrashById,
  deletePassengerCrashInformation,
  getVehicleById,
  deleteVehicleCrashInformations,
  getNonMotoristById,
  deleteNonMotoristCrashInformation,
  updateNonMotoristCrashInformation,
  getDriverById,
  updateDriverCrashInformation,
  deleteDriverCrashInformation,
  crashAttachment
}

export default CrashInformationService

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getSafetySystems = async (params) => {
  const response = await api.get(`${servicePrefix}/safety-system`, { params: stripEmptyObjects(params) })
  return response.data
}

//safety-systems
const getAllSafetySystems = async (params) => {
  const response = await api.get(`${servicePrefix}/safety-system/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addSafetySystem = async (safetySystems) => {
  const response = await api.post(`${servicePrefix}/safety-system`, safetySystems)
  return response.data
}

const updateSafetySystem = async (safetySystems) => {
  const { id } = safetySystems
  const response = await api.patch(`${servicePrefix}/safety-system/${id}`, safetySystems)
  return response.data
}

const deleteSafetySystem = async (id) => {
  const response = await api.delete(`${servicePrefix}/safety-system/${id}`)
  return response.data
}

const SafetySystemMasterService = {
  getSafetySystems,
  getAllSafetySystems,
  addSafetySystem,
  updateSafetySystem,
  deleteSafetySystem
}

export default SafetySystemMasterService

import api from '@services'

import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_COMMON_DATA_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/common-data/api`

//country
const getAllCountry = async (params) => {
  const response = await api.get(`${servicePrefix}/countries`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllCountries = async () => {
  const response = await api.get(`${servicePrefix}/countries/all`)
  return response
}

const addCountry = async (country) => {
  const response = await api.post(`${servicePrefix}/countries`, country)
  return response.data
}

const updateCountry = async (country) => {
  const { id } = country
  const response = await api.patch(`${servicePrefix}/countries/${id}`, country)
  return response.data
}

const deleteCountry = async (id) => {
  const response = await api.delete(`${servicePrefix}/countries/${id}`)
  return response.data
}

//dzongkhag
const getAllDzongkhag = async (params) => {
  const response = await api.get(`${servicePrefix}/dzongkhags`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllDzongkhags = async () => {
  const response = await api.get(`${servicePrefix}/dzongkhags/all`)
  return response.data
}

const addDzongkhag = async (dzongkhag) => {
  const response = await api.post(`${servicePrefix}/dzongkhags`, dzongkhag)
  return response.data
}

const updateDzongkhag = async (initialPost) => {
  const { id } = initialPost
  const response = await api.patch(`${servicePrefix}/dzongkhags/${id}`, initialPost)
  return response.data
}

const deleteDzongkhag = async (id) => {
  const response = await api.delete(`${servicePrefix}/dzongkhags/${id}`)
  return response.data
}

//Gewog
const getAllGewog = async (params) => {
  const response = await api.get(`${servicePrefix}/gewogs`, { params: stripEmptyObjects(params) })
  return response.data
}
const getIndividualGewog = async (id) => {
  const response = await api.get(`${servicePrefix}/gewogs/${id}`)
  return response
}
const addGewog = async (gewog) => {
  const response = await api.post(`${servicePrefix}/gewogs`, gewog)
  return response.data
}

const updateGewog = async (gewog) => {
  const { id } = gewog
  const response = await api.patch(`${servicePrefix}/gewogs/${id}`, gewog)
  return response.data
}

const deleteGewog = async (id) => {
  const response = await api.delete(`${servicePrefix}/gewogs/${id}`)
  return response.data
}

// Region
const getAllRegions = async (params) => {
  const response = await api.get(`${servicePrefix}/regions`, { params: stripEmptyObjects(params) })
  return response.data
}

export const getAllRegionForOffice = async () => {
  const response = await api.get(`${servicePrefix}/regions/all`)
  return response.data
}

const addRegion = async (dzongkhag) => {
  const response = await api.post(`${servicePrefix}/regions`, dzongkhag)
  return response.data
}

const updateRegion = async (region) => {
  const { id } = region
  const response = await api.patch(`${servicePrefix}/regions/${id}`, region)
  return response.data
}

const deleteRegion = async (id) => {
  const response = await api.delete(`${servicePrefix}/regions/${id}`)
  return response.data
}

//Office Locations
const getAllOfficeLocations = async (params) => {
  const response = await api.get(`${servicePrefix}/office-locations`, { params: stripEmptyObjects(params) })
  return response.data
}

const getOfficeLocations = async () => {
  const response = await api.get(`${servicePrefix}/office-locations`)
  return response.data
}

const getAllOfficeLocationsAtOnce = async () => {
  const response = await api.get(`${servicePrefix}/office-locations/all`)
  return response.data
}

const getIndividualOfficeLocation = async (id) => {
  const response = await api.get(`${servicePrefix}/office-locations/${id}`)
  return response
}

const addOfficeLocations = async (office_location) => {
  const response = await api.post(`${servicePrefix}/office-locations`, office_location)
  return response.data
}

const updateOfficeLocations = async (office_location) => {
  const { id } = office_location
  const response = await api.patch(`${servicePrefix}/office-locations/${id}`, office_location)
  return response.data
}

const deleteOfficeLocations = async (id) => {
  const response = await api.delete(`${servicePrefix}/office-locations/${id}`)
  return response.data
}

//DriveTypes
const getLearnerLicenseDriveTypesAll = async () => {
  const response = await api.get(`${servicePrefix}/drive-types/all`)
  return response.data
}
const getLearnerLicenseDriveTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/drive-types`, { params: stripEmptyObjects(params) })
  return response.data
}
const getLearnerLicenseDriveType = async (id) => {
  const response = await api.get(`${servicePrefix}/drive-types/${id}`)
  return response
}
const addLearnerLicenseDriveType = async (driveType) => {
  const response = await api.post(`${servicePrefix}/drive-types`, driveType)
  return response
}

const updateLearnerLicenseDriveType = async (driveType) => {
  const { id } = driveType
  const response = await api.patch(`${servicePrefix}/drive-types/${id}`, driveType)
  return response.data
}

const deleteLernerLicenseDriveType = async (id) => {
  const response = await api.delete(`${servicePrefix}/drive-types/${id}`)
  return response
}

const MasterService = {
  //country
  getAllCountry,
  getAllCountries,
  addCountry,
  updateCountry,
  deleteCountry,

  //Dzongkhag
  getAllDzongkhag,
  getAllDzongkhags,
  addDzongkhag,
  updateDzongkhag,
  deleteDzongkhag,

  //Gewog
  getAllGewog,
  addGewog,
  updateGewog,
  deleteGewog,
  getIndividualGewog,

  //Region
  getAllRegions,
  getAllRegionForOffice,
  addRegion,
  updateRegion,
  deleteRegion,

  //Office Location
  getAllOfficeLocations,
  getOfficeLocations,
  addOfficeLocations,
  updateOfficeLocations,
  deleteOfficeLocations,
  getIndividualOfficeLocation,

  //DriveTypes
  getLearnerLicenseDriveTypesAll,
  getLearnerLicenseDriveTypes,
  getLearnerLicenseDriveType,
  addLearnerLicenseDriveType,
  updateLearnerLicenseDriveType,
  deleteLernerLicenseDriveType,
  getAllOfficeLocationsAtOnce
}

export default MasterService

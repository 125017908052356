import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerService from '@services/learner-license'

export const getAllLearnerNumberSequence = createAsyncThunk('number-sequence/getAllLearnerNumberSequence', async (params, { rejectWithValue }) => {
  try {
    const response = await LearnerService.getAllLearnerNumberSequence(params)
    console.log('response', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const getLearnerleNumberSequence = createAsyncThunk('number-sequence/getLearnerleNumberSequence', async (id, { rejectWithValue }) => {
  try {
    const response = await LearnerService.getLearnerleNumberSequence(id)
    // console.log('response', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response)
  }
})

export const addLearnerleNumberSequence = createAsyncThunk('number-sequence/addLearnerleNumberSequence', async (numberSequence, { rejectWithValue }) => {
  try {
    const response = await LearnerService.addLearnerleNumberSequence(numberSequence)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerleNumberSequence = createAsyncThunk('number-sequence/updateLearnerleNumberSequence', async (numberSequence, { rejectWithValue }) => {
  try {
    const response = await LearnerService.updateLearnerleNumberSequence(numberSequence)
    // dispatch(getAllLearnerNumberSequence(getState()?.number-sequences.params))
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteLearnerleNumberSequence = createAsyncThunk('number-sequence/deleteLearnerleNumberSequence', async (id, { dispatch, getState }) => {
  await LearnerService.deleteLearnerleNumberSequence(id)
  await dispatch(getAllLearnerNumberSequence(getState()?.learnerNumberSequences.params))
  return id
})

const numberSequenceSlice = createSlice({
  name: 'learner_number_sequence',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLearnerNumberSequence: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllLearnerNumberSequence.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualLearnerNumberSequence = null
      })
      .addCase(getLearnerleNumberSequence.fulfilled, (state, action) => {
        state.individualLearnerNumberSequence = action.payload
      })

      .addCase(updateLearnerleNumberSequence.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const sequence = state.data.filter((sequence) => sequence.id !== id)
        state.data = [...sequence, action.payload]
      })
  }
})

export default numberSequenceSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getAllDrivingLicenseReplacements = createAsyncThunk('replacement/getAllDrivingLicenseReplacements', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicenseReplacements(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseReplacement = createAsyncThunk('replacement/getDrivingLicenseReplacement', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseReplacement(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrivingLicenseReplacement = createAsyncThunk('replacement/addDrivingLicenseReplacement', async (replacement, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseReplacement(replacement)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseReplacementAttachment = createAsyncThunk('replacement/updateDrivingLicenseReplacementAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseReplacementAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseReplacementPayment = createAsyncThunk('replacement/updateDrivingLicenseReplacementPayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseReplacementPayment(payment)
    return response
  } catch (err) {
    console.log(err?.response)
    return rejectWithValue(err?.response?.data?.message)
  }
})

export const updateDrivingLicenseReplacement = createAsyncThunk('replacement/updateDrivingLicenseReplacement', async (replacement, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseReplacement(replacement)
    dispatch(getAllDrivingLicenseReplacements(getState()?.drivingLicenseReplacements.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

const Replacement = createSlice({
  name: 'replacement',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDrivingLicenseReplacement: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicenseReplacements.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicenseReplacement = null
      })
      .addCase(getDrivingLicenseReplacement.fulfilled, (state, action) => {
        state.selectedDrivingLicenseReplacement = action.payload
      })
      .addCase(updateDrivingLicenseReplacement.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const replacement = state.data.filter((replacement) => replacement.id !== id)
        state.data = [...replacement, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default Replacement.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/vehicle-master'

export const getAllConversionTypes = createAsyncThunk('conversion-types/getAllConversionTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllConversionTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getConversionType = createAsyncThunk('conversion-types/getConversionType', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getConversionType(id)
    // console.log('type', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addConversionType = createAsyncThunk('conversion-types/addConversionType', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addConversionType(type)
    dispatch(getAllConversionTypes(getState()?.vehicleConversionTypes))
    return type
  } catch (err) {
    return rejectWithValue(err.type.data)
  }
})

export const updateConversionType = createAsyncThunk('conversion-types/updateConversionType', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.updateConversionType(type)
    dispatch(getAllConversionTypes(getState()?.vehicleConversionTypes.params))
    return type
  } catch (err) {
    return rejectWithValue(err.type.data)
  }
})

export const deleteConversionType = createAsyncThunk('conversion-types/deleteConversionType', async (id, { dispatch, getState }) => {
  await MasterService.deleteConversionType(id)
  await dispatch(getAllConversionTypes(getState()?.vehicleConversionTypes.params))
  return id
})

const conversionType = createSlice({
  name: 'conversion_type',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedConversionType: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllConversionTypes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedConversionType = null
      })
      .addCase(getConversionType.fulfilled, (state, action) => {
        state.selectedConversionType = action.payload
      })
      .addCase(updateConversionType.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const conversionType = state.data.filter((conversionType) => conversionType.id !== id)
        state.data = [...conversionType, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default conversionType.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import PersonalInformationService from '@services/personal-information'

export const getPisIndividuals = createAsyncThunk('pisIndividual/getPisIndividuals', async (params) => {
  const response = await PersonalInformationService.getPisIndividuals(params)
  return {
    params,
    data: response.data,
    totalPages: response.meta.itemCount
  }
})

export const getPisIndividual = createAsyncThunk('pisIndividual/getPisIndividual', async (id, { rejectWithValue }) => {
  try {
    const response = await PersonalInformationService.getPisIndividual(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addPisIndividual = createAsyncThunk('pisIndividual/addPisIndividual', async (pisIndividual, { rejectWithValue }) => {
  try {
    const response = await PersonalInformationService.addPisIndividual(pisIndividual)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const addPisIndividualPermanentAddress = createAsyncThunk('pisIndividual/addPisIndividualPermanentAddress', async (pisIndividualPermanentAddress, { rejectWithValue }) => {
  try {
    const response = await PersonalInformationService.addPisIndividualPermanentAddress(pisIndividualPermanentAddress)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
export const addPisIndividualPresentAddress = createAsyncThunk('pisIndividual/addPisIndividualPresentAddress', async (pisIndividualPresentAddress, { rejectWithValue }) => {
  try {
    const response = await PersonalInformationService.addPisIndividualPresentAddress(pisIndividualPresentAddress)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deletePisIndividual = createAsyncThunk('pisIndividual/deletePisIndividual', async (id, { dispatch, getState }) => {
  await PersonalInformationService.deletePisIndividual(id)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const updatePisIndividual = createAsyncThunk('pisIndividual/addPisIndividual', async (pisIndividual, { rejectWithValue }) => {
  console.log(pisIndividual)
  try {
    const response = await PersonalInformationService.updatePisIndividual(pisIndividual.id, pisIndividual)
    console.log('dispatchp resp', response)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updatePisPermanentAddress = createAsyncThunk('pisIndividual/updatePisPermanentAddress', async (permanentAddress, { rejectWithValue }) => {
  try {
    const response = await PersonalInformationService.updatePermanentAddress(permanentAddress.id, permanentAddress)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updatePisPresentAddress = createAsyncThunk('pisIndividual/updatePisPresentAddress', async (presentAddress, { rejectWithValue }) => {
  try {
    const response = await PersonalInformationService.updatePresentAddress(presentAddress.id, presentAddress)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const pisIndividualAvatar = createAsyncThunk('pisIndividual/pisIndividualAvatar', async (data, { rejectWithValue }) => {
  console.log(data)
  try {
    const id = data.get('id')
    const response = await PersonalInformationService.uploadAvatarForPisIndividual(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const pisIndividualDocuments = createAsyncThunk('pisIndividual/pisIndividualDocuments', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await PersonalInformationService.documentForPisIndividual(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const pisIndividualSlice = createSlice({
  name: 'pisIndividuals',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPisIndividual: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPisIndividuals.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPisIndividual.pending, (state, action) => {
        state.selectedPisIndividual = action.payload
      })
      .addCase(getPisIndividual.fulfilled, (state, action) => {
        state.selectedPisIndividual = action.payload
      })
  }
})

export default pisIndividualSlice.reducer

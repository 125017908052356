// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/vehicle-master'

export const getAllBusCategoryTypes = createAsyncThunk('bus-category-types/getAllBusCategoryTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllBusCategoryTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getBusCategoryType = createAsyncThunk('bus-category-types/getBusCategoryType', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getBusCategoryType(id)
    // console.log('type', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addBusCategoryType = createAsyncThunk('bus-category-types/addBusCategoryType', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await MasterService.addBusCategoryType(type)
    dispatch(getAllBusCategoryTypes(getState()?.busCategoryTypes))
    return response
  } catch (err) {
    return rejectWithValue(err?.response?.data)
  }
})

export const updateBusCategoryType = createAsyncThunk('bus-category-types/updateBusCategoryType', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await MasterService.updateBusCategoryType(type)
    dispatch(getAllBusCategoryTypes(getState()?.busCategoryTypes.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteBusCategoryType = createAsyncThunk('bus-category-types/deleteBusCategoryType', async (id, { dispatch, getState }) => {
  await MasterService.deleteBusCategoryType(id)
  await dispatch(getAllBusCategoryTypes(getState()?.busCategoryTypes.params))
  return id
})

const BusCategoryType = createSlice({
  name: 'bus_category_type',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedBusCategoryType: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllBusCategoryTypes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedBusCategoryType = null
      })
      .addCase(getBusCategoryType.fulfilled, (state, action) => {
        state.selectedBusCategoryType = action.payload
      })
      .addCase(updateBusCategoryType.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const busCategoryType = state.data.filter((busCategoryType) => busCategoryType.id !== id)
        state.data = [...busCategoryType, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default BusCategoryType.reducer

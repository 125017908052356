// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getDrivingLicenseApplicationByApplicationNo = createAsyncThunk('driving-license-application/getDrivingLicenseApplication', async (applicationNo, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseApplicationByApplicationNo(applicationNo)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAllOrdinaryDrivingLicense = createAsyncThunk('ordinary-driving-license/getAllOrdinaryDrivingLicense', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllOrdinaryDrivingLicense(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getOrdinaryDrivingLicense = createAsyncThunk('ordinary-driving-license/getOrdinaryDrivingLicense', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getOrdinaryDrivingLicense(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateOrdinaryDrivingLicense = createAsyncThunk('ordinary-driving-license/updateOrdinaryDrivingLicense', async (license, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateOrdinaryDrivingLicense(license)
    dispatch(getAllOrdinaryDrivingLicense(getState()?.ordinaryDrivingLicense.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteOrdinaryDrivingLicense = createAsyncThunk('ordinary-driving-license/deleteOrdinaryDrivingLicense', async (id, { dispatch, getState }) => {
  await DrivingLicenseService.deleteOrdinaryDrivingLicense(id)
  await dispatch(getAllOrdinaryDrivingLicense(getState()?.ordinaryDrivingLicense.params))
  return id
})

//renewal
export const addDrivingLicenseRenewal = createAsyncThunk('ordinary-driving-license/addDrivingLicenseRenewal', async (renewal, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseRenewal(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseRenewalAttachment = createAsyncThunk('ordinary-driving-license/updateDrivingLicenseRenewalAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseRenewalAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseRenewalPayment = createAsyncThunk('ordinary-driving-license/updateDrivingLicenseRenewalPayment', async (renewal) => {
  const response = await DrivingLicenseService.updateDrivingLicenseRenewalPayment(renewal)
  return response
})

//replacements
export const addDrivingLicenseReplacement = createAsyncThunk('ordinary-driving-license/addDrivingLicenseReplacement', async (renewal, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseReplacement(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseReplacementAttachment = createAsyncThunk('ordinary-driving-license/updateDrivingLicenseReplacementAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseReplacementAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseReplacementPayment = createAsyncThunk('ordinary-driving-license/updateDrivingLicenseReplacementPayment', async (renewal) => {
  const response = await DrivingLicenseService.updateDrivingLicenseReplacementPayment(renewal)
  return response
})

//update payment for all payment
export const updatePayment = createAsyncThunk('ordinary-driving-license/updatePayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updatePayment(payment)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//suspension
// export const addDrivingLicenseSuspension = createAsyncThunk('ordinary-driving-license/addDrivingLicenseSuspension', async (suspension, { rejectWithValue }) => {
//   try {
//     const response = await DrivingLicenseService.addDrivingLicenseSuspension(suspension)
//     return response
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

//cancellation
// export const addDrivingLicenseCancellation = createAsyncThunk('ordinary-driving-license/addDrivingLicenseCancellation', async (cancellation, { rejectWithValue }) => {
//   try {
//     const response = await DrivingLicenseService.addDrivingLicenseCancellation(cancellation)
//     return response
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

const DrivingLicenseApplication = createSlice({
  name: 'ordinary_driving_license',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedOrdinaryDrivingLicense: null,
    selectedDrivingLicenseApplication: null,
    loading: true
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllOrdinaryDrivingLicense.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedOrdinaryDrivingLicense = null
      })
      .addCase(getOrdinaryDrivingLicense.fulfilled, (state, action) => {
        state.selectedOrdinaryDrivingLicense = action.payload
        state.loading = false
      })
      .addCase(getOrdinaryDrivingLicense.pending, (state, action) => {
        state.selectedOrdinaryDrivingLicense = action.payload
        state.loading = true
      })
      .addCase(getOrdinaryDrivingLicense.rejected, (state, action) => {
        state.selectedOrdinaryDrivingLicense = action.payload
        state.loading = false
      })
      .addCase(getDrivingLicenseApplicationByApplicationNo.fulfilled, (state, action) => {
        state.selectedDrivingLicenseApplication = action.payload
        state.loading = false
      })
      .addCase(getDrivingLicenseApplicationByApplicationNo.rejected, (state) => {
        state.selectedDrivingLicenseApplication = null
        state.loading = false
      })
      .addCase(updateOrdinaryDrivingLicense.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const ordinaryLicense = state.data.filter((ordinaryLicense) => ordinaryLicense.id !== id)
        state.data = [...ordinaryLicense, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default DrivingLicenseApplication.reducer

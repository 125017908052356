import api from '@services'
import { stripEmptyObjects } from '@utils'
const servicePrefix = '/svc/admin-user/api'

//agencies
const getAllAgency = async (params) => {
  const response = await api.get(`${servicePrefix}/agencies`, { params: stripEmptyObjects(params) })
  return response.data
}

const addAgency = async (agency) => {
  const response = await api.post(`${servicePrefix}/agencies`, agency)
  return response.data
}

const updateAgency = async (agency) => {
  const { id } = agency
  const response = await api.patch(`${servicePrefix}/agencies/${id}`, agency)
  return response.data
}

const deleteAgency = async (id) => {
  const response = await api.delete(`${servicePrefix}/agencies/${id}`)
  return response.data
}

const AdminMasterService = {
  //agencies
  getAllAgency,
  addAgency,
  updateAgency,
  deleteAgency
}

export default AdminMasterService

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchRegistrationCode = createAsyncThunk('registrationCode/getAllRegistrationCode', async (params, rejectWithValue) => {
  try {
    const response = await VehicleMasterService.getAllRegistrationCode(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewRegistrationCode = createAsyncThunk('registrationCode/addNewRegistrationCode', async (code, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addRegistrationCode(code)
    dispatch(fetchRegistrationCode(getState()?.registrationCodes.params))
    return code
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateRegistrationCode = createAsyncThunk('registrationCode/updateRegistrationCode', async (code, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.updateRegistrationCode(code)
    dispatch(fetchRegistrationCode(getState()?.registrationCodes.params))
    return code
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteRegistrationCode = createAsyncThunk('registrationCode/deleteRegistrationCode', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteRegistrationCode(id)
  await dispatch(fetchRegistrationCode(getState()?.registrationCodes.params))
  return id
})

const registrationCodeSlice = createSlice({
  name: 'registrationCode',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchRegistrationCode.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateRegistrationCode.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const registrationCode = state.data.filter((registrationCode) => registrationCode.id !== id)
        state.data = [...registrationCode, action.payload]
      })
  }
})

export const getAllRegistrationCode = (state) => state.registrationCodes.registrationCode
export const selectRegistrationCodeById = (state, id) => state.registrationCodes.data.find((registrationCode) => registrationCode.id === id)

export default registrationCodeSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import RoadSurfaceConditionMasterService from '../../../../../services/vehicle-crash/masters/road-surface-condition'

export const getRoadSurfaceConditions = createAsyncThunk('roadSurfaceCondition/getRoadSurfaceConditions', async (params, { rejectWithValue }) => {
  try {
    const response = await RoadSurfaceConditionMasterService.getRoadSurfaceConditions(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addRoadSurfaceCondition = createAsyncThunk('roadSurfaceCondition/addRoadSurfaceCondition', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await RoadSurfaceConditionMasterService.addRoadSurfaceCondition(color)
    dispatch(getRoadSurfaceConditions(getState()?.roadSurfaceConditions.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getRoadSurfaceConditionsById = createAsyncThunk('roadSurfaceCondition/getRoadSurfaceConditionsById', async (params, { rejectWithValue }) => {
  try {
    const response = await RoadSurfaceConditionMasterService.getRoadSurfaceConditionsById(params)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateRoadSurfaceCondition = createAsyncThunk('roadSurfaceCondition/updateRoadSurfaceCondition', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await RoadSurfaceConditionMasterService.updateRoadSurfaceCondition(color)
    dispatch(getRoadSurfaceConditions(getState()?.roadSurfaceConditions.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteRoadSurfaceCondition = createAsyncThunk('roadSurfaceCondition/deleteRoadSurfaceCondition', async (id, { dispatch, getState }) => {
  await RoadSurfaceConditionMasterService.deleteRoadSurfaceCondition(id)
  await dispatch(getRoadSurfaceConditions(getState()?.roadSurfaceConditions.params))
  return id
})

const RoadSurfaceConditionSlice = createSlice({
  name: 'roadSurfaceConditions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getRoadSurfaceConditions.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectRoadSurfaceConditionById = (state, id) => state.roadSurfaceConditions.data.find((roadSurfaceConditions) => roadSurfaceConditions.id === id)

export default RoadSurfaceConditionSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import NonCollisionTypeMasterService from '@services/vehicle-crash/masters/non-collision-type'

export const getNonCollisionTypes = createAsyncThunk('non-collision-type/getNonCollisionTypes', async (params, rejectWithValue) => {
  try {
    const response = await NonCollisionTypeMasterService.getNonCollisionTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNonCollisionType = createAsyncThunk('non-collision-type/addNonCollisionType', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await NonCollisionTypeMasterService.addNonCollisionType(color)
    dispatch(getNonCollisionTypes(getState()?.nonCollisionTypes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateNonCollisionType = createAsyncThunk('non-collision-type/updateNonCollisionType', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await NonCollisionTypeMasterService.updateNonCollisionType(color)
    dispatch(getNonCollisionTypes(getState()?.nonCollisionTypes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteNonCollisionType = createAsyncThunk('non-collision-type/deleteNonCollisionType', async (id, { dispatch, getState }) => {
  await NonCollisionTypeMasterService.deleteNonCollisionType(id)
  await dispatch(getNonCollisionTypes(getState()?.nonCollisionTypes.params))
  return id
})

const nonCollisionTypesSlice = createSlice({
  name: 'nonCollisionTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getNonCollisionTypes.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectNonCollisionTypeById = (state, id) => state.nonCollisionTypes.data.find((nonCollisionType) => nonCollisionType.id === id)

export default nonCollisionTypesSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import InjuryStatusMasterService from '../../../../../services/vehicle-crash/masters/injury-status'

export const getInjuryStatus = createAsyncThunk('injuryStatus/getInjuryStatus', async (params, { rejectWithValue }) => {
  try {
    const response = await InjuryStatusMasterService.getInjuryStatus(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addInjuryStatus = createAsyncThunk('injuryStatus/addInjuryStatus ', async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    await InjuryStatusMasterService.addInjuryStatus(data)
    dispatch(getInjuryStatus(getState()?.injuryStatus.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateInjuryStatus = createAsyncThunk('injuryStatus/updateInjuryStatus ', async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    await InjuryStatusMasterService.updateInjuryStatus(data)
    dispatch(getInjuryStatus(getState()?.injuryStatus.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteInjuryStatus = createAsyncThunk('injuryStatus/deleteInjuryStatus ', async (id, { dispatch, getState }) => {
  await InjuryStatusMasterService.deleteInjuryStatus(id)
  await dispatch(getInjuryStatus(getState()?.injuryStatus.params))
  return id
})

const InjuryStatusSlice = createSlice({
  name: 'injuryStatus',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getInjuryStatus.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectInjuryStatusById = (state, id) => state.injuryStatus.data.find((injuryStatus) => injuryStatus.id === id)

export default InjuryStatusSlice.reducer

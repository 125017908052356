// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import CrashInformationService from '@services/vehicle-crash'

export const getCrashInformations = createAsyncThunk('crashInformation/getCrashInformations', async (params) => {
  const response = await CrashInformationService.getCrashInformations(params)
  return {
    params,
    data: response.data,
    totalPages: response.meta.itemCount
  }
})

export const getCrashInformationById = createAsyncThunk('crashInformation/getCrashInformationById', async (id, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getCrashInformationById(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addCrashInformation = createAsyncThunk('crashInformation/addCrashInformation', async (crashInformation, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.addCrashInformation(crashInformation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateCrashInformation = createAsyncThunk('crashInformation/updateCrashInformation', async (crashInformation, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.updateCrashInformation(crashInformation.id, crashInformation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteCrashInformation = createAsyncThunk('crashInformation/deleteCrashInformation', async (id, { dispatch, getState }) => {
  await CrashInformationService.deleteCrashInformation(id)
  await dispatch(getCrashInformations(getState()?.crashInformations.params))
  return id
})

//Passenger

export const getPassengerCrashInformationById = createAsyncThunk('crashInformation/getPassengerCrashInformationById', async (crashInfoId, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getPassengerCrashByCrashId(crashInfoId)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getPassengerCrashInformationByItsId = createAsyncThunk('crashInformation/getPassengerCrashInformationByItsId', async (id, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getPassengerCrashById(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updatePassengerCrashInformation = createAsyncThunk('crashInformation/updatePassengerCrashInformation', async (passenger, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.updatePassengerCrashInformation(passenger.id, passenger)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addPassengerCrashInformation = createAsyncThunk('crashInformation/addPassengerCrashInformation', async (crashInformation, { rejectWithValue }) => {
  console.log(crashInformation)
  try {
    const response = await CrashInformationService.addCrashPassengerInformation(crashInformation)
    console.log(response)
    return response
  } catch (err) {
    console.log(err.response.data)
    return rejectWithValue(err.response.data)
  }
})

export const deletePassengerCrashInformation = createAsyncThunk('crashInformation/deletePassengerCrashInformation', async (id) => {
  await CrashInformationService.deletePassengerCrashInformation(id)
})

//Vehicle
export const addVehicleCrashInformation = createAsyncThunk('crashInformation/addVehicleCrashInformation', async (crashInformation, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.addCrashVehicleInformation(crashInformation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getVehicleCrashInformationById = createAsyncThunk('crashInformation/getVehicleCrashInformationById', async (crashInfoId, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getVehicleCrashByCrashId(crashInfoId)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getVehicleById = createAsyncThunk('crashInformation/getVehicleById', async (id, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getVehicleById(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleCrashInformation = createAsyncThunk('crashInformation/updateVehicleCrashInformation', async (vehicleCrashData, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.updateVehicleCrashInformation(vehicleCrashData.id, vehicleCrashData)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteVehicleCrashInformation = createAsyncThunk('crashInformation/deleteVehicleCrashInformation', async (id) => {
  await CrashInformationService.deleteVehicleCrashInformations(id)
})

//Non Motorist

export const getNonMotoristCrashInformationById = createAsyncThunk('crashInformation/getNonMotoristCrashInformationById', async (crashInfoId, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getNonMotoristCrashByCrashId(crashInfoId)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getNonMotoristById = createAsyncThunk('crashInformation/getNonMotoristById', async (id, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getNonMotoristById(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addMotoristCrashInformation = createAsyncThunk('crashInformation/addMotoristCrashInformation', async (crashInformation, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.addCrashNonMotoristInformation(crashInformation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateNonMotoristCrashInformation = createAsyncThunk('crashInformation/updateNonMotoristCrashInformation', async (motorist, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.updateNonMotoristCrashInformation(motorist.id, motorist)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteNonMotoristCrashInformation = createAsyncThunk('crashInformation/deleteNonMotoristCrashInformation', async (id) => {
  await CrashInformationService.deleteNonMotoristCrashInformation(id)
})

//Driver

export const getDriverCrashInformationById = createAsyncThunk('crashInformation/getDriverCrashInformationById', async (crashInfoId, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getDriverCrashByCrashId(crashInfoId)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDriverCrashInformation = createAsyncThunk('crashInformation/addDriverCrashInformation', async (crashInformation, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.addCrashDriverInformation(crashInformation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDriverById = createAsyncThunk('crashInformation/getDriverById', async (id, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.getDriverById(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDriverCrashInformation = createAsyncThunk('crashInformation/updateDriverCrashInformation', async (driver, { rejectWithValue }) => {
  try {
    const response = await CrashInformationService.updateDriverCrashInformation(driver.id, driver)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDriverCrashInformation = createAsyncThunk('crashInformation/deleteDriverCrashInformation', async (id) => {
  await CrashInformationService.deleteDriverCrashInformation(id)
})

export const updateDocumentUpload = createAsyncThunk('crashInformation/updateDocumentUpload', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await CrashInformationService.crashAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const crashInformationSlice = createSlice({
  name: 'crashInformations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    //crash
    selectedCrashData: null,
    //driver
    selectedDriverCrashInfo: null,
    selectedDriverCrashData: [],
    //non-motorist
    selectedNonMotoristCrashInfo: null,
    selectedNonMotoristCrashData: [],
    //vehicle
    selectedVehicleCrashInfo: null,
    selectedVehicleCrashData: [],
    //Passenger
    selectedPassengerCrashData: [],
    selectedPassengerCrashDataById: null,

    //Basic Information Reducer
    crashInformation: null,

    //Basic Location Reducer
    locationInfo: {
      latitude: 27.499687756660602,
      longitude: 90.50496339797974
    },
    //Basic Vehicle Reducer
    vehicleInformation: [],

    //Basic Driver Reducer
    driverInformation: [],

    //Basic Passenger Reducer
    passengerInformation: [],

    //Basic Non Motorist Reducer
    nonMotoristInformation: [],

    loading: false
  },
  reducers: {
    setCrashInformation: (state, action) => {
      state.crashInformation = action.payload
    },
    setLocationInformation: (state, action) => {
      state.locationInfo = action.payload
    },
    setVehicleInformation: (state, action) => {
      state.vehicleInformation = action.payload
    },
    setDriverInformation: (state, action) => {
      state.driverInformation = action.payload
    },
    setPassengerInformation: (state, action) => {
      state.passengerInformation = action.payload
    },
    setNonMotoristInformation: (state, action) => {
      state.nonMotoristInformation = action.payload
    },

    resetCrashInformation: (state) => {
      state.crashInformation = null
    },
    resetLocationInformation: (state) => {
      state.locationInfo = {
        latitude: 27.499687756660602,
        longitude: 90.50496339797974
      }
    },

    resetVehicleInformation: (state) => {
      state.vehicleInformation = []
    },
    resetDriverInformation: (state) => {
      state.driverInformation = []
    },
    resetPassengerInformation: (state) => {
      state.passengerInformation = []
    },
    resetNonMotoristInformation: (state) => {
      state.nonMotoristInformation = []
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCrashInformations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getCrashInformationById.fulfilled, (state, action) => {
        state.selectedCrashData = action.payload
        state.loading = false
      })
      .addCase(getVehicleCrashInformationById.fulfilled, (state, action) => {
        state.selectedVehicleCrashData = action.payload
        state.loading = false
      })
      .addCase(getVehicleById.fulfilled, (state, action) => {
        state.selectedVehicleCrashInfo = action.payload
        state.loading = false
      })
      .addCase(getDriverCrashInformationById.fulfilled, (state, action) => {
        state.selectedDriverCrashData = action.payload
        state.loading = false
      })
      .addCase(getDriverById.fulfilled, (state, action) => {
        state.selectedDriverCrashInfo = action.payload
        state.loading = false
      })
      .addCase(getPassengerCrashInformationById.fulfilled, (state, action) => {
        state.selectedPassengerCrashData = action.payload
        state.loading = false
      })
      .addCase(getPassengerCrashInformationByItsId.fulfilled, (state, action) => {
        state.selectedPassengerCrashDataById = action.payload
        state.loading = false
      })
      .addCase(getNonMotoristCrashInformationById.fulfilled, (state, action) => {
        state.selectedNonMotoristCrashData = action.payload
        state.loading = false
      })
      .addCase(getNonMotoristById.fulfilled, (state, action) => {
        state.selectedNonMotoristCrashInfo = action.payload
        state.loading = false
      })
  }
})

export const { setCrashInformation, setLocationInformation, setVehicleInformation, setDriverInformation, setPassengerInformation, setNonMotoristInformation, resetCrashInformation, resetLocationInformation, resetVehicleInformation, resetDriverInformation, resetPassengerInformation, resetNonMotoristInformation } = crashInformationSlice.actions

export default crashInformationSlice.reducer

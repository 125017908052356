import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import TrafficControlDevicemMasterService from '@services/vehicle-crash/masters/traffic-control-device'

export const getTrafficControlDevices = createAsyncThunk('traffic-control-device/getTrafficControlDevices', async (params, rejectWithValue) => {
  try {
    const response = await TrafficControlDevicemMasterService.getTrafficControlDevices(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addTrafficControlDevice = createAsyncThunk('traffic-control-device/addTrafficControlDevice', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await TrafficControlDevicemMasterService.addTrafficControlDevice(color)
    dispatch(getTrafficControlDevices(getState()?.trafficControlDevices.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateTrafficControlDevice = createAsyncThunk('traffic-control-device/updateTrafficControlDevice', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await TrafficControlDevicemMasterService.updateTrafficControlDevice(color)
    dispatch(getTrafficControlDevices(getState()?.trafficControlDevices.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteTrafficControlDevice = createAsyncThunk('traffic-control-device/deleteTrafficControlDevice', async (id, { dispatch, getState }) => {
  await TrafficControlDevicemMasterService.deleteTrafficControlDevice(id)
  await dispatch(getTrafficControlDevices(getState()?.trafficControlDevices.params))
  return id
})

const trafficControlDeviceSlice = createSlice({
  name: 'trafficControlDevices',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getTrafficControlDevices.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectTrafficControlDeviceById = (state, id) => state.trafficControlDevices.data.find((trafficControlDevice) => trafficControlDevice.id === id)

export default trafficControlDeviceSlice.reducer

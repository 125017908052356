import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getTrafficControlDevices = async (params) => {
  const response = await api.get(`${servicePrefix}/traffic-control-device`, { params: stripEmptyObjects(params) })
  return response.data
}

//traffic-control-devices
const getAllTrafficControlDevices = async (params) => {
  const response = await api.get(`${servicePrefix}/traffic-control-device/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addTrafficControlDevice = async (trafficControlDevices) => {
  const response = await api.post(`${servicePrefix}/traffic-control-device`, trafficControlDevices)
  return response.data
}

const updateTrafficControlDevice = async (trafficControlDevices) => {
  const { id } = trafficControlDevices
  const response = await api.patch(`${servicePrefix}/traffic-control-device/${id}`, trafficControlDevices)
  return response.data
}

const deleteTrafficControlDevice = async (id) => {
  const response = await api.delete(`${servicePrefix}/traffic-control-device/${id}`)
  return response.data
}

const TrafficControlDevicemMasterService = {
  getTrafficControlDevices,
  getAllTrafficControlDevices,
  addTrafficControlDevice,
  updateTrafficControlDevice,
  deleteTrafficControlDevice
}

export default TrafficControlDevicemMasterService

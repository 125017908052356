import api from '@services'

const servicePrefix = '/personal-information-service/api'

//get all personalInfo
const getAllpersonalInfo = async () => {
  const response = await api.get(`${servicePrefix}/personal-informations`)
  return response.data
}

//search personalInfo
const searchPersonalInfo = async (search) => {
  const response = await api.get(`${servicePrefix}/personal-informations/info/${search}`)
  return response.data
}
//get all countries
const getAllCountries = async () => {
  const response = await api.get(`${servicePrefix}/countries/all`)
  return response.data
}
// get all permanent-addresses
const getAllPermenantAddress = async () => {
  const response = await api.get(`${servicePrefix}/permanent-addresses`)
  return response.data
}
//add personalInfo
const addPersonalInfo = async (personalInfo) => {
  const response = await api.post(`${servicePrefix}/personal-informations`, personalInfo)
  return response.data
}
//add permenant Address
const addPermenantAddress = async (permenantAddress) => {
  const response = await api.post(`${servicePrefix}/permanent-addresses`, permenantAddress)
  return response.data
}
//add present Address
const addPresentAddress = async (presentAddress) => {
  const response = await api.post(`${servicePrefix}/present-addresses`, presentAddress)
  return response.data
}
//get all Gewogs
const getAllGewogs = async () => {
  const response = await api.get(`${servicePrefix}/gewogs/all`)
  return response.data
}
//get all dzongkhags with gewogs
const getAllDzongkhags = async () => {
  const response = await api.get(`${servicePrefix}/dzongkhags/all`)
  return response.data
}
const PersonalInfoService = {
  getAllpersonalInfo,
  searchPersonalInfo,
  getAllPermenantAddress,
  addPresentAddress,
  getAllCountries,
  addPersonalInfo,
  addPermenantAddress,
  getAllDzongkhags,
  getAllGewogs
}

export default PersonalInfoService

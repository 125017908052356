// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Returns paginated array
const paginateArray = (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage)

export const getAllpersonalInformation = createAsyncThunk('personal-information/getAllPersonalInformation', async (params) => {
  const { q = '', perPage = 7, page = 1 } = params
  const response = await axios.get('https://uat.eralis.cloudbhutan.com/personal-information-service/api/personal-informations', params)
  const allData = response.data.data
  const filteredData = allData.filter((item) => Object.values(item).join('').toLowerCase().includes(q.toLowerCase()))
  return { allData: paginateArray(filteredData, perPage, page), totalPages: filteredData.length, data: response.data.data, params }
})

export const getPersonalInformation = createAsyncThunk('personal-information/getPersonalInformation', async (id) => {
  return await axios.get(`https://uat.eralis.cloudbhutan.com/personal-information-service/api/personal-informations/${id}`).then((res) => res.data)
})

export const updatePersonalInformation = createAsyncThunk('personal-information/updatePersonaInformation', async (personalInfo, { dispatch, getState }) => {
  try {
    const { id } = personalInfo
    const response = await axios.post(`https://uat.eralis.cloudbhutan.com/personal-information-service/api/personal-informations/${id}`, personalInfo)
    console.log('success', response)
    dispatch(getAllpersonalInformation(getState()))
    return response.data
  } catch (err) {
    console.log('response', err.response)
    return rejectWithValue(err.response.data)
  }
})

export const deletePersonalInformation = createAsyncThunk('appInvoice/deleteInvoice', async (id, { dispatch, getState }) => {
  await axios.delete(`https://uat.eralis.cloudbhutan.com/personal-information-service/api/personal-informations/${id}`)
  await dispatch(getAllpersonalInformation(getState()))
  return id
})

export const appInvoiceSlice = createSlice({
  name: 'PI',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    getPI: []
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllpersonalInformation.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.allData = action.payload.allData
      })
      .addCase(getPersonalInformation.fulfilled, (state, action) => {
        state.getPI = action.payload
      })
      .addCase(updatePersonalInformation.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const data = state.data.filter((data) => data.id !== id)
        state.data = [...data, action.payload]
      })
  }
})

export default appInvoiceSlice.reducer

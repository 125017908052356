// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import OffenceService from '@services/offence'

export const getLicenseActions = createAsyncThunk('licenseActions/getLicenseActions', async (params) => {
  const response = await OffenceService.getLicenseActions(params)
  try {
    return {
      params,
      data: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getLicenseActionById = createAsyncThunk('licenseActions/getLicenseActionsById', async (id) => {
  const response = await OffenceService.getLicenseActionById(id)
  return response
})

export const updateLicenseActions = createAsyncThunk('licenseActions/updateLicenseActions', async (demerits, { rejectWithValue }) => {
  try {
    const response = await OffenceService.updateLicenseActions(demerits)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteLicenseActions = createAsyncThunk('licenseActions/deleteLicenseActions', async (id, { getState, dispatch }) => {
  await OffenceService.deleteLicenseActions(id)
  await dispatch(getLicenseActions(getState()?.licenseActions?.params))
  return id
})

export const licenseActionsSlice = createSlice({
  name: 'licenseActions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLicenseActions: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLicenseActions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLicenseActionById.fulfilled, (state, action) => {
        state.individualDemerit = action.payload
      })
  }
})

export default licenseActionsSlice.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`
const getVehicleActions = async (params) => {
  const response = await api.get(`${servicePrefix}/vehicle-prior-action`, { params: stripEmptyObjects(params) })

  return response.data
}

//vehicle-actions
const getAllVehicleActions = async (params) => {
  const response = await api.get(`${servicePrefix}/vehicle-prior-action/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addVehicleAction = async (vehicleAction) => {
  const response = await api.post(`${servicePrefix}/vehicle-prior-action`, vehicleAction)
  return response.data
}

const updateVehicleAction = async (vehicleAction) => {
  const { id } = vehicleAction
  const response = await api.patch(`${servicePrefix}/vehicle-prior-action/${id}`, vehicleAction)
  return response.data
}

const deleteVehicleAction = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicle-prior-action/${id}`)
  return response.data
}

const VehicleActionMasterService = {
  getVehicleActions,
  getAllVehicleActions,
  addVehicleAction,
  updateVehicleAction,
  deleteVehicleAction
}

export default VehicleActionMasterService

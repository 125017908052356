// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehicle = createAsyncThunk('vehicle/getAllVehicle', async () => {
  const response = await VehicleService.getAllVehicle()
  //   console.log('ff', response)
  return response.data
})

export const getVehicles = createAsyncThunk('vehicle/getVehicles', async (params, { rejectWithValue }) => {
  const response = await VehicleService.getVehicles(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

export const getVehicle = createAsyncThunk('vehicle/getVehicle', async (id) => {
  const response = await VehicleService.getVehicle(id)
  // console.log(response)
  return response
})

export const addVehicle = createAsyncThunk('vehicle/addVehicle', async (vehicle, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleRegistration(vehicle)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicle = createAsyncThunk('vehicle/updateVehicle', async (vehicle) => {
  const response = await VehicleService.updateVehicleRegistration(vehicle)
  return response
})

export const deleteVehicle = createAsyncThunk('vehicle/deleteVehicle', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.deleteVehicle(id)
    return response
  } catch (err) {
    // Check if it's a network error
    if (!err.response) {
      return rejectWithValue(err)
    } else {
      return rejectWithValue(err.response.data)
    }
    // return rejectWithValue(err.response)
  }
})

export const updateVehicleOrganization = createAsyncThunk('vehicle/updateVehicleOrganization', async (data, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehicleOwner(data.id, { ownerId: data.ownerId })
    return response
  } catch (err) {
    return rejectWithValue(err.response)
  }
})

export const updateVehicleDetails = createAsyncThunk('vehicle/updateVehicleDetails', async (vehicle, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehicle(vehicle)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addVehicleRenewal = createAsyncThunk('vehicle/addVehicleRenewal', async (renewal, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleRenewal(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleRenewalAttachment = createAsyncThunk('vehicle/updateVehicleRenewalAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleRenewalAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleRenewalPayment = createAsyncThunk('vehicle/updateVehicleRenewalPayment', async (renewal) => {
  const response = await VehicleService.updateVehicleRenewalPayment(renewal)
  return response
})

export const addVehicleFitness = createAsyncThunk('vehicle/addVehicleFitness', async (fitness, { rejectWithValue }) => {
  console.log(fitness)
  try {
    const response = await VehicleService.addVehicleFitness(fitness)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleFitnessAttachment = createAsyncThunk('vehicle/updateVehicleFitnessAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleFitnessAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleFitnessPayment = createAsyncThunk('vehicle/updateVehicleFitnessPayment', async (fitness, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehicleFitnessPayment(fitness)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const addVehicleReplacement = createAsyncThunk('vehicle/addVehicleReplacement', async (replacement, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleReplacement(replacement)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleReplacementAttachment = createAsyncThunk('vehicle/updateVehicleReplacementAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleReplacementAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleReplacementPayment = createAsyncThunk('vehicle/updateVehicleReplacementPayment', async (replacement) => {
  const response = await VehicleService.updateVehicleReplacementPayment(replacement)
  return response
})

export const addVehicleConversion = createAsyncThunk('vehicle/addVehicleConversion', async (conversion, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleConversion(conversion)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleConversionAttachment = createAsyncThunk('vehicle/updateVehicleConversionAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleConversionAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const vehicleConversionPayment = createAsyncThunk('vehicle/vehicleConversionPayment', async (conversion) => {
  const response = await VehicleService.vehicleConversionPayment(conversion)
  // console.log('conversion response', response)
  return response
})

export const addVehicleNoc = createAsyncThunk('vehicle/addVehicleNoc', async (noc, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleNoc(noc)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const vehicleNocPayment = createAsyncThunk('vehicle/vehicleNocPayment', async (noc) => {
  const response = await VehicleService.vehicleNocPayment(noc)
  // console.log('conversion response', response)
  return response
})

export const addVehicleOwnershipTransfer = createAsyncThunk('vehicle/addVehicleOwnershipTransfer', async (transfer, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleOwnershipTransfer(transfer)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleOwnershipTransferAttachment = createAsyncThunk('vehicle/updateVehicleOwnershipTransferAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleOwnershipTransferAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const vehicleOwnershipTransferPayment = createAsyncThunk('vehicle/vehicleOwnershipTransferPayment', async (transfer) => {
  const response = await VehicleService.vehicleOwnershipTransferPayment(transfer)
  // console.log('conversion response', response)
  return response
})

export const updateVehicleNocAttachment = createAsyncThunk('vehicle/updateVehicleNocAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleNocAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

//update payment for all payment
export const updatePayment = createAsyncThunk('vehicle/updatePayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updatePayment(payment)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVehicle: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllVehicle.fulfilled, (state, action) => {
        state.allData = action.payload
        state.selectedVehicle = null
      })
      .addCase(getVehicles.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getVehicle.fulfilled, (state, action) => {
        state.selectedVehicle = action.payload
        state.loading = false
      })
      .addCase(getVehicle.pending, (state, action) => {
        state.selectedVehicle = action.payload
        state.loading = true
      })
      .addCase(getVehicle.rejected, (state, action) => {
        state.selectedVehicle = action.payload
        state.loading = false
      })
  }
})

export default vehicleSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchVehicleModelName = createAsyncThunk('model/getAllVehicleModelName', async (params, rejectWithValue) => {
  try {
    const response = await VehicleMasterService.getAllVehicleModelName(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
export const getIndividualModelName = createAsyncThunk('model/getIndividualModelName', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleMasterService.getVehicleModelName(id)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
export const addNewVehicleModelName = createAsyncThunk('model/addNewVehicleModelName', async (modelName, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addVehicleModelName(modelName)
    dispatch(fetchVehicleModelName(getState()?.vehicleModelNames.params))
    return modelName
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleModelName = createAsyncThunk('model/updateVehicleModelName', async (modelName, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.updateVehicleModelName(modelName)
    dispatch(fetchVehicleModelName(getState()?.vehicleModelNames.params))
    return modelName
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteVehicleModelName = createAsyncThunk('model/deleteVehicleModelName', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteVehicleModelName(id)
  await dispatch(fetchVehicleModelName(getState()?.vehicleModelName))
  return id
})

const vehicleModelNameSlice = createSlice({
  name: 'vehicleModelName',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualModelName: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchVehicleModelName.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualModelName = null
      })
      .addCase(getIndividualModelName.fulfilled, (state, action) => {
        state.individualModelName = action.payload
      })

      .addCase(updateVehicleModelName.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const vehicleModelName = state.data.filter((vehicleModelName) => vehicleModelName.id !== id)
        state.data = [...vehicleModelName, action.payload]
      })
  }
})

export const getAllVehicleModelName = (state) => state.vehicleModelNames.vehicleModelName
export const selectVehicleModelNameById = (state, id) => state.vehicleModelNames.data.find((vehicleModelName) => vehicleModelName.id === id)

export default vehicleModelNameSlice.reducer

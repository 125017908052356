// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UserList = lazy(() => import('../../views/user/list'))
const UserView = lazy(() => import('../../views/user/view'))

const Roles = lazy(() => import('../../views/roles-permissions/roles'))
const Permissions = lazy(() => import('../../views/roles-permissions/permissions'))
const AddPermission = lazy(() => import('../../views/roles-permissions/permissions/add/index'))

const AppRoutes = [
  {
    element: <UserList />,
    path: '/apps/user/list'
  },
  {
    path: '/apps/user/view',
    element: <Navigate to="/apps/user/view/1" />
  },
  {
    element: <UserView />,
    path: '/apps/user/view/:id'
  },
  {
    element: <Roles />,
    path: '/apps/roles'
  },
  {
    element: <Permissions />,
    path: '/apps/permissions'
  },
  {
    path: '/apps/permission/list/add',
    element: <AddPermission />,
    meta: {
      action: 'create',
      resource: 'Permissions'
    }
  }
]

export default AppRoutes

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/vehicle-master'

export const getAllCancellationReasons = createAsyncThunk('cancellation-reason/getAllCancellationReasons', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllCancellationReasons(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getCancellationReasons = createAsyncThunk('cancellation-reason/getCancellationReasons', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getCancellationReasons(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addCancellationReasons = createAsyncThunk('cancellation-reason/addCancellationReasons', async (reasons, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addCancellationReasons(reasons)
    dispatch(getAllCancellationReasons(getState()?.vehicleCancellationReasons.params))
    return reasons
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateCancellationReasons = createAsyncThunk('cancellation-reason/updateCancellationReasons', async (reasons, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.updateCancellationReasons(reasons)
    dispatch(getAllCancellationReasons(getState()?.vehicleCancellationReasons.params))
    return reasons
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteCancellationReasons = createAsyncThunk('cancellation-reason/deleteCancellationReasons', async (id, { dispatch, getState }) => {
  await MasterService.deleteCancellationReasons(id)
  await dispatch(getAllCancellationReasons(getState()?.vehicleCancellationReasons.params))
  return id
})

const cancellationReasons = createSlice({
  name: 'cancellation_reasons',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCancellationReasons: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllCancellationReasons.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedCancellationReasons = null
      })
      .addCase(getCancellationReasons.fulfilled, (state, action) => {
        state.selectedCancellationReasons = action.payload
      })
      .addCase(updateCancellationReasons.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const cancellationReasons = state.data.filter((cancellationReasons) => cancellationReasons.id !== id)
        state.data = [...cancellationReasons, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default cancellationReasons.reducer

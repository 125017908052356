import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_DRIVING_TEST_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/driving-test/api`

//driving license application
export const getDrivingTestConfigurations = async (params) => {
  const response = await api.get(`${servicePrefix}/test-configurations`, { params: stripEmptyObjects(params) })
  return response.data
}

export const addDrivingTestConfiguration = async (data) => {
  const response = await api.post(`${servicePrefix}/test-configurations`, data)
  return response.data
}

export const getDrivingTestConfiguration = async (id) => {
  const response = await api.get(`${servicePrefix}/test-configurations/${id}`)
  return response.data
}

export const getDrivingTestConfigurationWithBookings = async (id) => {
  const response = await api.get(`${servicePrefix}/test-configurations/${id}/test-bookings`)
  return response.data
}

export const updateDrivingTestConfiguration = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/test-configurations/${id}`, data)
  return response.data
}

//Driving Test Candidates

export const getDrivingTestCandidates = async (params) => {
  const response = await api.get(`${servicePrefix}/test-bookings`, { params: stripEmptyObjects(params) })
  return response.data
}

export const getDrivingTestCandidatesByTestConfigurationId = async (id) => {
  const response = await api.get(`${servicePrefix}/test-configurations/${id}/practical-candidates`)
  return response.data
}

export const getDrivingTestCandidate = async (id) => {
  const response = await api.get(`${servicePrefix}/test-bookings/${id}`)
  return response.data
}

//Driving Test Theory Questions
export const getTestQuestions = async (params) => {
  const response = await api.get(`${servicePrefix}/questions`, { params: stripEmptyObjects(params) })
  return response.data
}

export const getTestQuestion = async (id) => {
  const response = await api.get(`${servicePrefix}/questions/${id}`)
  return response.data
}

export const postTestQuestion = async (data) => {
  const response = await api.post(`${servicePrefix}/questions`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

export const searchDrivingTestBookingBySearchTerm = async (searchTerm, testDate) => {
  const response = await api.get(`${servicePrefix}/test-bookings/search/${searchTerm}?testDate=${testDate}`)

  return response.data
}

export const getTheoryTestByBookingId = async (id) => {
  const response = await api.get(`${servicePrefix}/test-bookings/${id}/theory-test`)
  return response.data
}

export const postMarksheet = async (data) => {
  const response = await api.post(`${servicePrefix}/marksheets`, data)

  return response.data
}

export const getMarksheetByBookingId = async (testBookingId) => {
  const response = await api.get(`${servicePrefix}/marksheets/get-marksheet-by-booking-id/${testBookingId}`)

  return response.data
}

export const getTestBookingById = async (id) => {
  const response = await api.get(`${servicePrefix}/test-bookings/${id}`)
  return response.data
}

const DrivingTestService = {
  getDrivingTestConfigurations,
  addDrivingTestConfiguration,
  getDrivingTestConfiguration,
  getDrivingTestConfigurationWithBookings,
  getDrivingTestCandidatesByTestConfigurationId,
  updateDrivingTestConfiguration,

  getDrivingTestCandidates,
  getDrivingTestCandidate,

  getTestQuestions,
  getTestQuestion,
  postTestQuestion,

  searchDrivingTestBookingBySearchTerm,
  getTheoryTestByBookingId,

  postMarksheet,
  getMarksheetByBookingId,
  getTestBookingById
}

export default DrivingTestService

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/vehicle-master'

export const getAllTransferTypes = createAsyncThunk('transfer-Types/getAllTransferTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllTransferTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getTransferType = createAsyncThunk('transfer-Types/getTransferType', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getTransferType(id)
    // console.log('TransferType', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addTransferType = createAsyncThunk('transfer-Types/addTransferType', async (types, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addTransferType(types)
    dispatch(getAllTransferTypes(getState()?.vehicleTransferTypes.params))
    return types
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateTransferType = createAsyncThunk('transfer-Types/updateTransferType', async (types, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.updateTransferType(types)
    dispatch(getAllTransferTypes(getState()?.vehicleTransferTypes.params))
    return types
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteTransferType = createAsyncThunk('transfer-Types/deleteTransferType', async (id, { dispatch, getState }) => {
  await MasterService.deleteTransferType(id)
  dispatch(getAllTransferTypes(getState()?.vehicleTransferTypes.params))
  return id
})

const transfer = createSlice({
  name: 'transfer_types',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedTransferTypes: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllTransferTypes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedTransferTypes = null
      })
      .addCase(getTransferType.fulfilled, (state, action) => {
        state.selectedTransferTypes = action.payload
      })
      .addCase(updateTransferType.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const transferTypes = state.data.filter((transferTypes) => transferTypes.id !== id)
        state.data = [...transferTypes, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default transfer.reducer

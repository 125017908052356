// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerLicenseService from '@services/learner-license'

export const getAllSuspendedList = createAsyncThunk('learnerLicense/getAllSuspendedList', async (params, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.getLearnerLicenseSuspension(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getSuspensionById = createAsyncThunk('learnerLicense/getSuspensionById', async (id) => {
  const response = await LearnerLicenseService.getLearnerLicenseSuspensionById(id)
  //   console.log('res', response)

  return response
})

export const learnerLicenseSuspensionWithdrawal = createAsyncThunk('learnerLicense/learnerLicenseSuspensionWithdrawal', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await LearnerLicenseService.learnerLicenseSuspensionWithdrawal(id, data)

    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const learnerLicenseSuspensionSlice = createSlice({
  name: 'learnerLicenseSuspension',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLearnerLicenseSuspension: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSuspendedList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getSuspensionById.fulfilled, (state, action) => {
        state.individualLearnerLicenseSuspension = action.payload
        state.loading = false
      })
  }
})

export default learnerLicenseSuspensionSlice.reducer

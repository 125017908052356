import { lazy } from 'react'
const Datatable = lazy(() => import('../../views/personal-information-1/list/Datatable'))
const PersonalInfoDetails = lazy(() => import('../../views/personal-information-1/personal-details/index'))
const PersonalInfoSearch = lazy(() => import('../../views/personal-information-1/personal-details/index'))
const AddNewPersonalInfo = lazy(() => import('../../views/personal-information-1/add/index'))
const PermenantAddress = lazy(() => import('../../views/personal-information-1/add/permanentAddress'))
const PresentAddress = lazy(() => import('../../views/personal-information-1/add/presentAddress'))
const PersonalInfoViewId = lazy(() => import('../../views/personal-information-1/view/index'))
const Permenant = lazy(() => import('../../views/personal-information-1/view/PermanentAddressInfoCard'))
const PersonalInfoRoutes = [
  {
    path: '/personal-info/list',
    element: <Datatable />
  },
  {
    element: <PersonalInfoDetails />,
    path: '/personal-detials/view'
  },
  {
    element: <PersonalInfoSearch />,
    path: '/personal-info/new'
  },
  {
    element: <AddNewPersonalInfo />,
    path: '/personal-info/add'
  },
  {
    element: <PermenantAddress />,
    path: '/personal-info/permenantAddress'
  },

  {
    element: <PresentAddress />,
    path: '/personal-info/presentAddress',
    path: '/personal-info/list/view/:id',
    element: <PersonalInfoViewId />
  },

  {
    element: <Permenant />,
    path: '/personal-info/view/permenant',
    element: <Permenant />
  }
]
export default PersonalInfoRoutes

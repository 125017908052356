import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getCollisionTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/collision-type`, { params: stripEmptyObjects(params) })
  return response.data
}

//agencies
const getAllCollisionType = async () => {
  const response = await api.get(`${servicePrefix}/collision-type/all`)
  return response.data
}

const addCollisionType = async (collisionType) => {
  const response = await api.post(`${servicePrefix}/collision-type`, collisionType)
  return response.data
}

const updateCollisionType = async (collisionType) => {
  const { id } = collisionType
  const response = await api.patch(`${servicePrefix}/collision-type/${id}`, collisionType)
  return response.data
}

const deleteCollisionType = async (id) => {
  const response = await api.delete(`${servicePrefix}/collision-type/${id}`)
  return response.data
}

const CollisionTypeMasterService = {
  getCollisionTypes,
  getAllCollisionType,
  addCollisionType,
  updateCollisionType,
  deleteCollisionType
}

export default CollisionTypeMasterService

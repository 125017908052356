import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getDrugTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/drug-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllDrugType = async () => {
  const response = await api.get(`${servicePrefix}/drug-types/all`)
  return response.data
}

const addDrugType = async (drugType) => {
  const response = await api.post(`${servicePrefix}/drug-types`, drugType)
  return response.data
}

const updateDrugType = async (drugType) => {
  const { id } = drugType
  const response = await api.patch(`${servicePrefix}/drug-types/${id}`, drugType)
  return response.data
}

const deleteDrugType = async (id) => {
  const response = await api.delete(`${servicePrefix}/drug-types/${id}`)
  return response.data
}

const DrugTypeMasterService = {
  getDrugTypes,
  getAllDrugType,
  addDrugType,
  updateDrugType,
  deleteDrugType
}

export default DrugTypeMasterService

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerLicenseService from '@services/learner-license/index'

export const getLearnerLicenseApplications = createAsyncThunk('learnerLicenseApplication/getLearnerLicenseApplications', async (params) => {
  const response = await LearnerLicenseService.getLearnerLicenseApplications(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getLearnerLicenseApplicationById = createAsyncThunk('learnerLicenseApplication/getLearnerLicenseById', async (id) => {
  const response = await LearnerLicenseService.getLearnerLicenseApplication(id)
  return response
})

export const addLearnerLicenseApplication = createAsyncThunk('learnerLicenseApplication/addLearnerLicenseApplication', async (learnerLicenseInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.addLearnerLicenseApplication(learnerLicenseInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerLicenseApplication = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseApplication', async (learnerLicenseInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updateLearnerLicenseApplication(learnerLicenseInfo)
    console.log(response)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerLicensePayment = createAsyncThunk('learnerLicenseApplication/updateLearnerLicensePayment', async (paymentInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updatePaymentForLearnerLicense(paymentInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addLearnerLicenseRenewal = createAsyncThunk('learnerLicenseApplication/addLearnerLicenseRenewal', async (learnerLicenseDetails) => {
  const response = await LearnerLicenseService.addLearnerLicenseRenewal(learnerLicenseDetails)
  return response
})

export const updateLearnerLicenseAttachment = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    console.log(id)
    const response = await LearnerLicenseService.updateLearnerLicenseAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const learnerLicenseApplicationSlice = createSlice({
  name: 'learnerLicenseApplication',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLearnerLicense: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLearnerLicenseApplications.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLearnerLicenseApplicationById.fulfilled, (state, action) => {
        state.individualLearnerLicense = action.payload
      })
  }
})

export default learnerLicenseApplicationSlice.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getStations = async (params) => {
  const response = await api.get(`${servicePrefix}/station`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllStation = async (params) => {
  const response = await api.get(`${servicePrefix}/station/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addStation = async (station) => {
  const response = await api.post(`${servicePrefix}/station`, station)
  return response.data
}

const getStationById = async (station) => {
  const { id } = station
  const response = await api.get(`${servicePrefix}/station/${id}`, station)
  return response.data
}

const updateStation = async (station) => {
  const { id } = station
  const response = await api.patch(`${servicePrefix}/station/${id}`, station)
  return response.data
}

const deleteStation = async (id) => {
  const response = await api.delete(`${servicePrefix}/station/${id}`)
  return response.data
}

const StationMasterService = {
  getStations,
  getAllStation,
  addStation,
  updateStation,
  deleteStation,
  getStationById
}

export default StationMasterService

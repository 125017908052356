import { configureStore } from '@reduxjs/toolkit'
import persistedReducer from './rootReducer'
import { persistStore } from 'redux-persist'

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false
    })
  }
})

const persistor = persistStore(store)

export { store, persistor }

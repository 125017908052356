// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getAllDrivingLicenseCancellation = createAsyncThunk('cancellation/getAllDrivingLicenseCancellation', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicenseCancellation(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseCancellation = createAsyncThunk('cancellation/getDrivingLicenseCancellation', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseCancellation(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrivingLicenseCancellation = createAsyncThunk('cancellation/addDrivingLicenseCancellation', async (cancellation, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseCancellation(cancellation)
    dispatch(getAllDrivingLicenseCancellation(getState()?.drivingLicenseCancellations.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseCancellation = createAsyncThunk('cancellation/updateDrivingLicenseCancellation', async (cancellation, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseCancellation(cancellation)
    dispatch(getAllDrivingLicenseCancellation(getState()?.drivingLicenseCancellations.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseCancellationAttachment = createAsyncThunk('cancellation/updateDrivingLicenseCancellationAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseCancellationAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const updateDrivingLicenseCancellationAttachment = createAsyncThunk('cancellation/updateDrivingLicenseCancellationAttachment', async (attachment) => {
//   const response = await DrivingLicenseService.updateDrivingLicenseCancellationAttachment(attachment)
//   // console.log('conversion response', response)
//   return response
// })

const Cancellation = createSlice({
  name: 'drivingLicenseCancellations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDrivingLicenseCancellation: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicenseCancellation.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicenseCancellation = null
      })
      .addCase(getDrivingLicenseCancellation.fulfilled, (state, action) => {
        state.selectedDrivingLicenseCancellation = action.payload
      })
      .addCase(updateDrivingLicenseCancellation.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const cancellation = state.data.filter((cancellation) => cancellation.id !== id)
        state.data = [...cancellation, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default Cancellation.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getTrafficCongestionConditions = async (params) => {
  const response = await api.get(`${servicePrefix}/traffic-congestion-condition`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllTrafficCongestionCondition = async (params) => {
  const response = await api.get(`${servicePrefix}/traffic-congestion-condition/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addTrafficCongestionCondition = async (trafficCongestionCondition) => {
  const response = await api.post(`${servicePrefix}/traffic-congestion-condition`, trafficCongestionCondition)
  return response.data
}

const updateTrafficCongestionCondition = async (trafficCongestionCondition) => {
  const { id } = trafficCongestionCondition
  const response = await api.patch(`${servicePrefix}/traffic-congestion-condition/${id}`, trafficCongestionCondition)
  return response.data
}

const deleteTrafficCongestionCondition = async (id) => {
  const response = await api.delete(`${servicePrefix}/traffic-congestion-condition/${id}`)
  return response.data
}

const TrafficCongestionConditionMasterService = {
  getTrafficCongestionConditions,
  getAllTrafficCongestionCondition,
  addTrafficCongestionCondition,
  updateTrafficCongestionCondition,
  deleteTrafficCongestionCondition
}

export default TrafficCongestionConditionMasterService

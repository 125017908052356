import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DivisionMasterService from '../../../../../services/vehicle-crash/masters/station'

export const getStations = createAsyncThunk('station/getStations', async (params, { rejectWithValue }) => {
  try {
    const response = await DivisionMasterService.getStations(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const getIndividualStation = createAsyncThunk('station/getIndividualStation', async (id, { rejectWithValue }) => {
  try {
    const response = await DivisionMasterService.getStationById(id)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewStation = createAsyncThunk('station/addNewStation', async (station, { rejectWithValue }) => {
  try {
    const response = await DivisionMasterService.addStation(station)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateStation = createAsyncThunk('station/updateStation', async (station, { rejectWithValue, dispatch, getState }) => {
  try {
    await DivisionMasterService.updateStation(station)
    dispatch(getStations(getState()?.stations.params))
    return station
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteStation = createAsyncThunk('station/deleteStation', async (id, { dispatch, getState }) => {
  await DivisionMasterService.deleteStation(id)
  await dispatch(getStations(getState()?.stations.params))
  return id
})

const stationsSlice = createSlice({
  name: 'stations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualStation: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getStations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualStation = null
      })
      .addCase(getIndividualStation.fulfilled, (state, action) => {
        state.individualStation = action.payload
      })
  }
})

export const selectStationById = (state, id) => state.stations.data.find((station) => station.id === id)

export default stationsSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/common-data'

export const fetchGewog = createAsyncThunk('gewog/getAllGewog', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllGewog(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const getIndividualGewog = createAsyncThunk('gewog/getIndividualGewog', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getIndividualGewog(id)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewGewog = createAsyncThunk('gewog/addNewGewog', async (gewog, { rejectWithValue }) => {
  try {
    const response = await MasterService.addGewog(gewog)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateGewog = createAsyncThunk('gewog/updateGewog', async (gewog, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.updateGewog(gewog)
    dispatch(fetchGewog(getState()?.gewogs.params))
    return gewog
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteGewog = createAsyncThunk('gewog/deleteGewog', async (id, { dispatch, getState }) => {
  await MasterService.deleteGewog(id)
  await dispatch(fetchGewog(getState()?.gewogs.params))
  return id
})

const gewogSlice = createSlice({
  name: 'gewog',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualGewog: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchGewog.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualGewog = null
      })
      .addCase(getIndividualGewog.fulfilled, (state, action) => {
        state.individualGewog = action.payload
      })

      .addCase(updateGewog.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const gewog = state.data.filter((gewog) => gewog.id !== id)
        state.data = [...gewog, action.payload]
      })
  }
})

export default gewogSlice.reducer

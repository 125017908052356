// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerLicenseService from '@services/learner-license/index'

export const getLearnerLicenseRenewals = createAsyncThunk('learnerLicense/getLearnerLicenses', async (params) => {
  const response = await LearnerLicenseService.getLearnerLicenseRenewal(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getLearnerLicenseRenewalById = createAsyncThunk('learnerLicense/getLearnerLicenseRenewalById', async (id) => {
  const response = await LearnerLicenseService.getLearnerLicenseRenewalById(id)
  return response
})

export const addLearnerLicenseRenewal = createAsyncThunk('learnerLicenseApplication/addLearnerLicenseApplication', async (learnerLicenseInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.addLearnerLicenseRenewal(learnerLicenseInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerLicenseApplication = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseApplication', async (learnerLicenseInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updateLearnerLicenseApplication(learnerLicenseInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const paymentUpdate = createAsyncThunk('learnerLicense/paymentUpdate', async (paymentInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updatepayment(paymentInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerLicenseRenewalAttachment = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseRenewalAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await LearnerLicenseService.updateLearnerLicenseRenewalAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const learnerLicenseRenewalSlice = createSlice({
  name: 'learnerLicenseRenewal',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLearnerLicenseRenewal: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLearnerLicenseRenewals.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLearnerLicenseRenewalById.fulfilled, (state, action) => {
        state.individualLearnerLicenseRenewal = action.payload
      })
  }
})

export default learnerLicenseRenewalSlice.reducer

import { DefaultRoute } from '../router/routes'
import { differenceInYears, parse, differenceInMinutes, isBefore, startOfDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const getLastLoggedIn = () => localStorage.getItem('lastLoggedIn')
export const updateLastLoggedIn = (loginDateTime) => {
  if (loginDateTime !== null) {
    localStorage.setItem('lastLoggedIn', loginDateTime)
  } else {
    localStorage.removeItem('lastLoggedIn')
  }
}

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') {
    const diff = differenceInMinutes(new Date(), new Date(getLastLoggedIn()))

    //
    if (diff >= 15) {
      localStorage.removeItem('userData')
      localStorage.removeItem('lastLoggedIn')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')

      return '/login'
    }

    return DefaultRoute
  }
  if (userRole === 'client') return '/access-control'

  return '/login'
}

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const serverSideErrors = (errors, setError) => {
  const processError = (error, parentProperty = '') => {
    const property = parentProperty ? `${parentProperty}.${error.property}` : error.property

    if (error.children && error.children.length > 0) {
      error.children.forEach((childError) => {
        processError(childError, property)
      })
    } else if (error.constraints) {
      const errorMessage = Object.values(error.constraints)
      setError(property, {
        type: 'server',
        message: errorMessage
      })
    }
  }

  if (Array.isArray(errors.message)) {
    errors.message.forEach((error) => {
      processError(error)
    })
  } else {
    processError(errors.message)
  }
}

export const stripEmptyObjects = (obj) => {
  if (obj) {
    return Object.keys(obj)
      .filter((key) => obj[key])
      .reduce((newObj, key) => {
        newObj[key] = obj[key]
        return newObj
      }, {})
  }
}

export const getSlug = (text) => {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

export const getPastDateByNumberOfYears = (numberOfYears) => {
  const currentDate = new Date()
  return currentDate.setFullYear(currentDate.getFullYear() - numberOfYears)
}

export const isBhutaneseCid = (text) => {
  const cid = text.replace(/\s/g, '')
  return cid.length === 11 && !isNaN(cid)
}

export const formatMobileNo = (number) => {
  const regex = /^(\+?\d{1,3}|\d{1,4})$/gm
  const str = '+975'
  const match = str.match(regex)

  if (match) {
    return number
  } else {
    return `+975${number}`
  }
}

export const titleCase = (string) => {
  const sentence = string.toLowerCase().split('-')
  for (let i = 0; i < sentence.length; i++) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
  }

  return sentence.join(' ')
}

export const camelCaseToTitleCase = (string) => {
  const result = string.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export const calculateAge = (dob) => {
  const date = parse(dob, 'yyyy-mm-dd', new Date())
  const age = differenceInYears(new Date(), date)
  return age
}

export const getLicenseType = (licenseNo) => {
  const odPrefixes = ['T-', 'P-', 'G-', 'S-', 'M-']

  if (licenseNo.slice(0, 3).toUpperCase() === 'PD-') {
    return 'professional'
  } else if (odPrefixes.includes(licenseNo.slice(0, 2))) {
    return 'ordinary'
  }

  return 'Invalid License No'
}

export const isDateBeforeToday = (date) => isBefore(zonedTimeToUtc(startOfDay(date), 'Asia/Thimphu'), zonedTimeToUtc(startOfDay(new Date()), 'Asia/Thimphu'))

export function generateYearOptions(maxYears) {
  const yearOptions = [{ value: '6', label: '6 Months' }]
  for (let i = 1; i <= maxYears; i++) {
    const years = i * 12
    yearOptions.push({ value: years.toString(), label: `${i} Year${i > 1 ? 's' : ''}` })
  }
  return yearOptions
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import PersonalInfoService from '@services/personalInfo'
import axios from 'axios'

export const getPersonalInformationById = createAsyncThunk('personal-information/getPersonalInformation', async (id) => {
  return await axios.get(`https://uat.eralis.cloudbhutan.com/personal-information-service/api/personal-informations/${id}`).then((res) => res.data)
})
//Get All PersonalInfo
export const getPersonalInfo = createAsyncThunk('PersonalInfo/getAllpersonalInfo', async () => {
  const response = await PersonalInfoService.getAllpersonalInfo()
  return { PersonalInfo: response.data }
})

//Get All Permenant Address
export const getPermenantAddress = createAsyncThunk('PersonalInfo/getPermenantAddress', async () => {
  const response = await PersonalInfoService.getAllPermenantAddress()
  return response.data
})
//Add PersonalInfo
export const addNewPersonalInfo = createAsyncThunk('PersonalInfo/addNewPersonalInfo', async (personalInfo, { rejectWithValue }) => {
  try {
    const response = await PersonalInfoService.addPersonalInfo(personalInfo)
    console.log('success', response)
    return response
  } catch (err) {
    console.log('response', err.response)
    return rejectWithValue(err.response.data)
  }
})
//add new permanent address
export const addNewPermanentAddress = createAsyncThunk('PersonalInfo/addNewPermanentAddress', async (permenantAddress, { rejectWithValue }) => {
  try {
    const response = await PersonalInfoService.addPermenantAddress(permenantAddress)
    return response
  } catch (err) {
    console.log('Permanent response', err.response)
    return rejectWithValue(err.response.data)
  }
})
//add new present address
export const addNewPresentAddress = createAsyncThunk('PersonalInfo/addNewPresentAddress', async (presentAddress, { rejectWithValue }) => {
  try {
    const response = await PersonalInfoService.addPresentAddress(presentAddress)
    return response
  } catch (err) {
    console.log('Present response', err.response)
    return rejectWithValue(err.response.data)
  }
})
//Get All Countries
export const getCountries = createAsyncThunk('PersonalInfo/getAllcountries', async () => {
  const response = await PersonalInfoService.getAllCountries()
  return { Countries: response }
})
// Get All dzongkhags
export const getDzongkhags = createAsyncThunk('PersonalInfo/getAllDzongkhags', async () => {
  const response = await PersonalInfoService.getAllDzongkhags()
  return response
})

export const getGewogs = createAsyncThunk('PersonalInfo/getAllGewogs', async () => {
  const response = await axios.get(`https://uat.eralis.cloudbhutan.com/personal-information-service/api/gewogs`)
  return response.data.data
})

//Personal Info slice
const personalInfoSlice = createSlice({
  name: 'PersonalInfo',
  initialState: {
    countries: [],
    personalInfo: [],
    dzongkhags: [],
    gewogs: [],
    permenantAddress: []
  },
  extraReducers(builder) {
    builder
      .addCase(getCountries.fulfilled, (state, action) => {
        state.countries = action.payload.Countries
      })
      .addCase(getPersonalInfo.fulfilled, (state, action) => {
        state.personalInfo = action.payload.PersonalInfo
      })
      .addCase(getDzongkhags.fulfilled, (state, action) => {
        state.dzongkhags = action.payload
      })
      .addCase(getGewogs.fulfilled, (state, action) => {
        state.gewogs = action.payload
      })
      .addCase(getPermenantAddress.fulfilled, (state, action) => {
        state.permenantAddress = action.payload
      })
  }
})

export default personalInfoSlice.reducer

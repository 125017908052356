// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehicleRenewal = createAsyncThunk('renewal/getAllVehicleRenewal', async (params, { rejectWithValue }) => {
  const response = await VehicleService.getAllVehicleRenewal(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

export const getIndividualVehicleRenewal = createAsyncThunk('renewal/getIndividualVehicleRenewal', async (id) => {
  const response = await VehicleService.getIndividualVehicleRenewal(id)
  // console.log(response)
  return response
})

export const addVehicleRenewal = createAsyncThunk('renewal/addVehicleRenewal', async (renewal, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleRenewal(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleRenewalAttachment = createAsyncThunk('renewal/updateVehicleRenewalAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleRenewalAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleRenewalPayment = createAsyncThunk('renewal/updateVehicleRenewalPayment', async (renewal, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehicleRenewalPayment(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err?.response?.data)
  }
})

export const vehicleSlice = createSlice({
  name: 'renewal',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVehicleRenewal: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllVehicleRenewal.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedVehicleRenewal = null
      })
      .addCase(getIndividualVehicleRenewal.fulfilled, (state, action) => {
        state.selectedVehicleRenewal = action.payload
      })
    // .addCase(upre.fulfilled, (state, action) => {
    //   if (!action.payload?.id) {
    //     console.log('Update could not complete')
    //     console.log(action.payload)
    //     return
    //   }
    //   const { id } = action.payload
    //   const replacement = state.data.filter((replacement) => replacement.id !== id)
    //   state.data = [...replacement, action.payload]
    // })
  }
})

export default vehicleSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/driving-license-master'

export const getAllDrivingLicenseLifeSpan = createAsyncThunk('life-span/getAllDrivingLicenseLifeSpan', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllDrivingLicenseLifeSpan(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseLifeSpan = createAsyncThunk('life-span/getDrivingLicenseLifeSpan', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getDrivingLicenseLifeSpan(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrivingLicenseLifeSpan = createAsyncThunk('life-span/addDrivingLicenseLifeSpan', async (lifeSpan, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await MasterService.addDrivingLicenseLifeSpan(lifeSpan)
    dispatch(getAllDrivingLicenseLifeSpan(getState()?.drivingLicenseLifeSpans.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseLifeSpan = createAsyncThunk('life-span/updateDrivingLicenseLifeSpan', async (lifeSpan, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await MasterService.updateDrivingLicenseLifeSpan(lifeSpan)
    dispatch(getAllDrivingLicenseLifeSpan(getState()?.drivingLicenseLifeSpans.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDrivingLicenseLifeSpan = createAsyncThunk('life-span/deleteDrivingLicenseLifeSpan', async (id, { dispatch, getState }) => {
  await MasterService.deleteDrivingLicenseLifeSpan(id)
  await dispatch(getAllDrivingLicenseLifeSpan(getState()?.drivingLicenseLifeSpans.params))
  return id
})

const DrivingLicenseLifeSpan = createSlice({
  name: 'life_span',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDrivingLicenseLifeSpan: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicenseLifeSpan.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicenseLifeSpan = null
      })
      .addCase(getDrivingLicenseLifeSpan.fulfilled, (state, action) => {
        state.selectedDrivingLicenseLifeSpan = action.payload
      })
      .addCase(updateDrivingLicenseLifeSpan.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const drivingLicenseLifeSpan = state.data.filter((drivingLicenseLifeSpan) => drivingLicenseLifeSpan.id !== id)
        state.data = [...drivingLicenseLifeSpan, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default DrivingLicenseLifeSpan.reducer

import { Grid, Circle } from 'react-feather'

export default [
  {
    id: 'masters',
    title: 'Masters',
    badge: 'light-danger',
    icon: <Grid size={20} />,
    resource: 'Masters',
    children: [
      {
        id: 'commondata',
        title: 'Common data',
        icon: <Circle size={12} />,
        children: [
          {
            id: 'agencies',
            title: 'Agencies',
            icon: <Circle size={12} />,
            navLink: '/master/agencies',
            resource: 'Masters'
          },
          {
            id: 'countries',
            title: 'Countries',
            icon: <Circle size={12} />,
            navLink: '/master/countries',
            resource: 'Masters'
          },
          {
            id: 'dzongkhags',
            title: 'Dzongkhags',
            icon: <Circle size={12} />,
            navLink: '/master/dzongkhags',
            resource: 'Masters'
          },

          {
            id: 'gewogs',
            title: 'Gewogs',
            icon: <Circle size={12} />,
            navLink: '/master/gewogs',
            resource: 'Masters'
          },
          {
            id: 'officeLocations',
            title: 'Office Locations',
            icon: <Circle size={12} />,
            navLink: '/master/office-locations',
            resource: 'Masters'
          },
          {
            id: 'regions',
            title: 'Regions',
            icon: <Circle size={12} />,
            navLink: '/master/regions',
            resource: 'Masters'
          }
        ]
      },
      {
        id: 'vehicles',
        title: 'Vehicle',
        icon: <Circle size={12} />,
        resource: 'Masters',
        children: [
          {
            id: 'bus',
            title: 'Bus Category Types',
            icon: <Circle size={12} />,
            navLink: '/master/bus-category-types',
            resource: 'Masters'
          },
          {
            id: 'color',
            title: 'Colors',
            icon: <Circle size={12} />,
            navLink: '/master/colors',
            resource: 'Masters'
          },

          {
            id: 'cancellation-types',
            title: 'Cancellation Types',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-cancellation-types',
            resource: 'Masters'
          },
          {
            id: 'cancellation-reasons',
            title: 'Cancellation Reasons',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-cancellation-reasons',
            resource: 'Masters'
          },
          {
            id: 'conversion',
            title: 'Conversion Types',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-conversion-types',
            resource: 'Masters'
          },
          {
            id: 'dealers',
            title: 'Dealers',
            icon: <Circle size={12} />,
            navLink: '/master/dealers',
            resource: 'Masters'
          },
          {
            id: 'engineType',
            title: 'Engine Types',
            icon: <Circle size={12} />,
            navLink: '/master/engine-types',
            resource: 'Masters'
          },
          {
            id: 'vehicleCompany',
            title: 'Vehicle Companies',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-companies',
            resource: 'Masters'
          },
          {
            id: 'vehicleModelName',
            title: 'Model Names',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-model-names',
            resource: 'Masters'
          },
          {
            id: 'vehicleModelVariant',
            title: 'Model Variants',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-model-variants',
            resource: 'Masters'
          },
          {
            id: 'vehicleType',
            title: 'Vehicle Types',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-types',
            resource: 'Masters'
          },

          {
            id: 'fitnesses',
            title: 'Fitnesses',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-fitnesses',
            resource: 'Masters'
          },

          {
            id: 'registrationCode',
            title: 'Registration Code',
            icon: <Circle size={12} />,
            navLink: '/master/registration-codes',
            resource: 'Masters'
          },

          {
            id: 'transfer-types',
            title: 'Transfer Types',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-transfer-types',
            resource: 'Masters'
          },

          {
            id: 'routeList',
            title: 'Route List',
            icon: <Circle size={12} />,
            navLink: '/master/route-list',
            resource: 'Masters'
          },

          {
            id: 'vehicle-life-spans',
            title: 'Vehicle Life Spans',
            icon: <Circle size={12} />,
            navLink: '/master/vehicle-life-spans',
            resource: 'Masters'
          }
          // {
          //   id: 'vehicle-number-sequence',
          //   title: 'Vehicle Number Sequence',
          //   icon: <Circle size={12} />,
          //   navLink: '/master/vehicle-number-sequences'
          // }
        ]
      },
      {
        id: 'license',
        title: 'License',
        icon: <Circle size={12} />,
        resource: 'Masters',
        children: [
          {
            id: 'learnerLicense',
            title: 'Learner licenses',
            icon: <Circle size={12} />,
            resource: 'Masters',
            children: [
              {
                id: 'driveType',
                title: 'Drive Types',
                icon: <Circle size={12} />,
                navLink: '/master/learnerLicenseDriveTypes',
                resource: 'Masters'
              }
              // {
              //   id: 'learner-number-sequence',
              //   title: 'Learner Number Sequence',
              //   icon: <Circle size={12} />,
              //   navLink: '/master/learner-number-sequences'
              // }
            ]
          },
          {
            id: 'driving-licenses',
            title: 'Driving License',
            icon: <Circle size={12} />,
            children: [
              {
                id: 'drive-type',
                title: 'Drive Types',
                icon: <Circle size={12} />,
                navLink: '/master/driving-license-drive-types',
                resource: 'Masters'
              },
              {
                id: 'life span',
                title: 'Life Spans',
                icon: <Circle size={12} />,
                navLink: '/master/driving-license-life-spans',
                resource: 'Masters'
              }
              // {
              //   id: 'driving-license-number-sequence',
              //   title: 'Driving License Number Sequence',
              //   icon: <Circle size={12} />,
              //   navLink: '/master/driving-license-number-sequences'
              // }
            ]
          }
        ]
      },

      {
        id: 'offence',
        title: 'Offence',
        icon: <Circle size={12} />,
        children: [
          {
            id: 'offences',
            title: 'Offences',
            icon: <Circle size={12} />,
            navLink: '/master/offences',
            resource: 'Masters'
          },
          {
            id: 'demerit-configuration',
            title: 'Demerit Configuration',
            icon: <Circle size={12} />,
            navLink: '/master/demerit-configurations',
            resource: 'Masters'
          },
          {
            id: 'inspection-places',
            title: 'Inspection Places',
            icon: <Circle size={12} />,
            navLink: '/master/inspection-places',
            resource: 'Masters'
          },
          {
            id: 'traffic-division',
            title: 'Traffic Divisions',
            icon: <Circle size={12} />,
            navLink: '/master/traffic-divisions',
            resource: 'Masters'
          }
        ]
      }
    ]
  }
]

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/vehicle-master'

export const getAllVehicleLifeSpans = createAsyncThunk('vehicle-life-spans/getAllVehicleLifeSpans', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllVehicleLifeSpans(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getVehicleLifeSpans = createAsyncThunk('vehicle-life-spans/getVehicleLifeSpans', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getVehicleLifeSpans(id)
    // console.log('type', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addVehicleLifeSpans = createAsyncThunk('vehicle-life-spans/addVehicleLifeSpans', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addVehicleLifeSpans(type)
    dispatch(getAllVehicleLifeSpans(getState()?.vehicleLifeSpans))
    return type
  } catch (err) {
    return rejectWithValue(err.type.data)
  }
})

export const updateVehicleLifeSpans = createAsyncThunk('vehicle-life-spans/updateVehicleLifeSpans', async (type, { rejectWithValue }) => {
  try {
    await MasterService.updateVehicleLifeSpans(type)
    // dispatch(getAllVehicleLifeSpans(getState()?.vehicleLifeSpans.params))
    return type
  } catch (err) {
    return rejectWithValue(err.type.data)
  }
})

export const deleteVehicleLifeSpans = createAsyncThunk('vehicle-life-spans/deleteVehicleLifeSpans', async (id, { dispatch, getState }) => {
  await MasterService.deleteVehicleLifeSpans(id)
  await dispatch(getAllVehicleLifeSpans(getState()?.vehicleLifeSpans.params))
  return id
})

const vehicleLifeSpan = createSlice({
  name: 'vehicle_life_spans',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVehicleLifeSpan: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllVehicleLifeSpans.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedVehicleLifeSpan = null
      })
      .addCase(getVehicleLifeSpans.fulfilled, (state, action) => {
        state.selectedVehicleLifeSpan = action.payload
      })
      .addCase(updateVehicleLifeSpans.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const lifeSpan = state.data.filter((lifeSpan) => lifeSpan.id !== id)
        state.data = [...lifeSpan, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default vehicleLifeSpan.reducer

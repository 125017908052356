// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const addToPrintList = createAsyncThunk('print-list/addToPrintList', async (printData, { rejectWithValue }) => {
  // await axios.post('/apps/users/add-user', user)

  console.log('printDat', printData)
  try {
    const response = await DrivingLicenseService.addToPrintList(printData)
    return response
  } catch (err) {
    console.log('ahell', err)
    return rejectWithValue(err?.response?.data)
  }
})

export const getAllDrivingLicensePrintList = createAsyncThunk('print-list/getAllDrivingLicensePrintList', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicensePrintList(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicensePrintList = createAsyncThunk('print-list/getDrivingLicensePrintList', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicensePrintList(id)
    console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicensePrintList = createAsyncThunk('print-list/updateDrivingLicensePrintList', async (printList, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicensePrintList(printList)
    dispatch(getAllDrivingLicensePrintList(getState()?.drivingLicensePrintLists.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

const DrivingLicensePrintList = createSlice({
  name: 'printList',
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    selectedDrivingLicensePrintList: null,
    loading: true,
    error: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicensePrintList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicensePrintList = null
        state.loading = false
        state.error = null
      })
      .addCase(getDrivingLicensePrintList.fulfilled, (state, action) => {
        state.selectedDrivingLicensePrintList = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(updateDrivingLicensePrintList.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const print = state.data.filter((print) => print.id !== id)
        state.data = [...print, action.payload]
        state.loading = false
        state.error = null
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default DrivingLicensePrintList.reducer

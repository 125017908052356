import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchVehicleCompany = createAsyncThunk('vehicleCompany/getAllVehicleCompany', async (params, rejectWithValue) => {
  try {
    const response = await VehicleMasterService.getAllVehicleCompany(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewVehicleCompany = createAsyncThunk('vehicleCompany/addNewVehicleCompany', async (company, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addVehicleCompany(company)
    dispatch(fetchVehicleCompany(getState()?.vehicleCompanies?.params))
    return company
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleCompany = createAsyncThunk('vehicleCompany/updateVehicleCompany', async (company, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.updateVehicleCompany(company)
    dispatch(fetchVehicleCompany(getState()?.vehicleCompanies.params))
    return company
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteVehicleCompany = createAsyncThunk('dealer/deleteVehicleCompany', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteVehicleCompany(id)
  await dispatch(fetchVehicleCompany(getState()?.vehicleCompanies.params))
  return id
})

const vehicleCompanySlice = createSlice({
  name: 'vehicleCompany',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchVehicleCompany.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateVehicleCompany.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const vehicleCompany = state.data.filter((vehicleCompany) => vehicleCompany.id !== id)
        state.data = [...vehicleCompany, action.payload]
      })
  }
})

export const getAllVehicleCompany = (state) => state.vehicleCompanies.vehicleCompany
export const selectVehicleCompanyById = (state, id) => state.vehicleCompanies.data.find((vehicleCompany) => vehicleCompany.id === id)

export default vehicleCompanySlice.reducer

import api from '@services'

import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PAYMENT_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/payment/api`

const getAllPayment = async (params) => {
  const response = await api.get(`${servicePrefix}/payments`, { params: stripEmptyObjects(params) })
  return response.data
}

const getPaymentByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/payments/${applicationNo}`)
  return response.data
}

const getRmaTransactionsByPaymentId = async (paymentId) => {
  const response = await api.get(`${servicePrefix}/payments/${paymentId}/rma-transactions`)
  return response.data
}

export const pushPaymentByApplicationNo = async (applicationNo, data) => {
  const response = await api.post(`${servicePrefix}/payments/push-payment/${applicationNo}`, data)
  return response.data
}

const PaymentService = {
  //payment
  getAllPayment,
  getPaymentByApplicationNo,
  getRmaTransactionsByPaymentId,
  pushPaymentByApplicationNo
}

export default PaymentService

import { lazy } from 'react'
//payments
const PaymentList = lazy(() => import('../../views/payment/list/index'))
const PaymentView = lazy(() => import('../../views/payment/view/index'))

const PaymentRoutes = [
  //payment
  {
    path: '/payments',
    element: <PaymentList />
  },
  {
    path: '/payments/:applicationNo/view',
    element: <PaymentView />
  }
]

export default PaymentRoutes

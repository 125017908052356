import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getNonCollisionTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/non-collision-type`, { params: stripEmptyObjects(params) })
  return response.data
}

//agencies
const getAllNonCollisionType = async (params) => {
  const response = await api.get(`${servicePrefix}/non-collision-type/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addNonCollisionType = async (nonCollisionType) => {
  const response = await api.post(`${servicePrefix}/non-collision-type`, nonCollisionType)
  return response.data
}

const updateNonCollisionType = async (nonCollisionType) => {
  const { id } = nonCollisionType
  const response = await api.patch(`${servicePrefix}/non-collision-type/${id}`, nonCollisionType)
  return response.data
}

const deleteNonCollisionType = async (id) => {
  const response = await api.delete(`${servicePrefix}/non-collision-type/${id}`)
  return response.data
}

const NonCollisionTypeMasterService = {
  getNonCollisionTypes,
  getAllNonCollisionType,
  addNonCollisionType,
  updateNonCollisionType,
  deleteNonCollisionType
}

export default NonCollisionTypeMasterService

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerLicenseService from '@services/learner-license'

export const getLearnerLicense = createAsyncThunk('learnerLicense/getLearnerLicense', async (params, { rejectWithValue }) => {
  const response = await LearnerLicenseService.getLearnerLicense(params)
  try {
    return {
      params,
      data: response.data,
      //   allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

export const getLearnerLicenseById = createAsyncThunk('learnerLicense/getLearnerLicenseById', async (id) => {
  const response = await LearnerLicenseService.getLearnerLicenseById(id)
  return response
})

export const paymentUpdateforLearnerRenewal = createAsyncThunk('learnerLicense/paymentUpdateforLearnerRenewal', async (paymentInfoforRenewal, { rejectWithValue }) => {
  console.log(paymentInfoforRenewal)
  try {
    const response = await LearnerLicenseService.updatePaymentForLearnerLicenseRenewal(paymentInfoforRenewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addLearnerLicenseReplacement = createAsyncThunk('learnerLicenseApplication/addLearnerLicenseReplacement', async (licenseReplacementDetails, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.addLearnerLicenseReplacement(licenseReplacementDetails)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addLearnerLicenseSuspension = createAsyncThunk('learnerLicenseApplication/addLearnerLicenseSuspension', async (licenseSuspensionDetails, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.addLearnerLicenseSuspension(licenseSuspensionDetails)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addLearnerLicenseCancellation = createAsyncThunk('learnerLicenseApplication/addLearnerLicenseCancellation', async (licenseCancellationDetails, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.addLearnerLicenseCancellation(licenseCancellationDetails)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const paymentUpdateforLearnerReplacement = createAsyncThunk('learnerLicense/paymentUpdateforLearnerReplacement', async (paymentInfoforReplacement, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updatePaymentForLearnerLicenseReplacement(paymentInfoforReplacement)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

//AttachmentForReplacement

export const updateLearnerLicenseReplacementAttachment = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseReplacementAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await LearnerLicenseService.updateLearnerLicenseReplacementAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

//AttachmentForSuspension
export const updateLearnerLicenseSuspensionAttachment = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseSuspensionAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await LearnerLicenseService.updateLearnerLicenseSuspensionAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

//AttachmentForSuspension
export const updateLearnerLicenseCancellationAttachment = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseSuspensionAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await LearnerLicenseService.updateLearnerLicenseCancellationAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const paymentUpdate = createAsyncThunk('learnerLicense/paymentUpdate', async (paymentInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updatepayment(paymentInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const learnerLicenseSlice = createSlice({
  name: 'learnerLicense',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLearnerLicense: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLearnerLicense.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLearnerLicenseById.fulfilled, (state, action) => {
        state.individualLearnerLicense = action.payload
        state.loading = false
      })
      .addCase(getLearnerLicenseById.pending, (state, action) => {
        state.individualLearnerLicense = action.payload
        state.loading = true
      })
      .addCase(getLearnerLicenseById.rejected, (state, action) => {
        state.individualLearnerLicense = action.payload
        state.loading = false
      })
  }
})

export default learnerLicenseSlice.reducer

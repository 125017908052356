import { Truck, Circle } from 'react-feather'

export default [
  {
    id: 'vehicle',
    title: 'Vehicles',
    icon: <Truck size={20} />,
    children: [
      {
        id: 'list-vehicle',
        title: 'Vehicle History',
        icon: <Circle size={12} />,
        navLink: '/vehicle/lists',
        action: 'read',
        resource: 'Vehicle'
      },
      {
        id: 'pending-application',
        title: 'Pending Applications',
        icon: <Circle size={12} />,
        children: [
          {
            id: 'renewal-pending',
            title: 'Renewal Pending',
            icon: <Circle size={12} />,
            navLink: '/vehicle/pending-application/renewal',
            resource: 'VehicleRegistration'
          },
          {
            id: 'replacement-pending',
            title: 'Replacement Pending',
            icon: <Circle size={12} />,
            navLink: '/vehicle/pending-application/replacement',
            resource: 'VehicleRcReplacement'
          }
        ]
      },
      {
        id: 'applications',
        title: 'New Registration',
        icon: <Circle size={12} />,
        navLink: '/vehicle/application/lists',
        resource: 'VehicleRegistration'
      },

      {
        id: 'renewal',
        title: 'RC Renewal',
        icon: <Circle size={12} />,
        navLink: '/vehicle/search/renewal',
        resource: 'VehicleRcRenewal'
      },
      {
        id: 'replacement',
        title: 'RC Replacement',
        icon: <Circle size={12} />,
        navLink: '/vehicle/search/replacement',
        resource: 'VehicleRcReplacement'
      },
      {
        id: 'fitness',
        title: 'RWC Renewal',
        icon: <Circle size={12} />,
        navLink: '/vehicle/fitness/list',
        resource: 'VehicleFitness'
      },

      {
        id: 'emission',
        title: 'Emission',
        icon: <Circle size={12} />,
        navLink: '/vehicle/emission',
        action: 'read',
        resource: 'VehicleEmission'
      },
      {
        id: 'conversion',
        title: 'Conversion',
        icon: <Circle size={12} />,
        navLink: '/vehicle/search/conversion',
        resource: 'VehicleConversion'
      },
      {
        id: 'ownership Transfer',
        title: 'Ownership Transfer',
        icon: <Circle size={12} />,
        navLink: '/vehicle/ownership-transfer',
        resource: 'VehicleOwnershipTransfer'
      },

      // {
      //   id: 'cancellationWithdrawal',
      //   title: 'Cancellation Withdrawal',
      //   icon: <Circle size={12} />,
      //   navLink: '/vehicle/search/cancellation-withdrawal'
      // },
      {
        id: 'vehiclePermit',
        title: 'Vehicle Permit',
        icon: <Circle size={12} />,
        navLink: '/vehicle/vehicle-permit',
        resource: 'VehicleRoutePermitReplacement'
      },
      {
        id: 'noc',
        title: 'NOC Issuance',
        icon: <Circle size={12} />,
        navLink: '/vehicle/noc/list',
        resource: 'VehicleNocIssuance'
      },

      {
        id: 'cancellation',
        title: 'Cancellation',
        icon: <Circle size={12} />,
        navLink: '/vehicle/cancellation/lists',
        resource: 'VehicleCancellation'
      },
      {
        id: 'busInspection',
        title: 'Bus Inspection',
        icon: <Circle size={12} />,
        navLink: '/vehicle/passenger-bus-inspection',
        resource: 'BusInspection'
      }
      // {
      //   id: 'vehiclePermit',
      //   title: 'Vehicle Permit',
      //   icon: <Circle size={12} />,
      //   children: [
      //     {
      //       id: 'newVehiclePermit',
      //       title: 'New Vehicle Permit',
      //       icon: <Circle size={12} />,
      //       navLink: '/vehicle/vehicle-permit'
      //     },
      //     {
      //       id: 'routePermitRenewal',
      //       title: 'Renewal',
      //       icon: <Circle size={12} />,
      //       navLink: '/vehicle/search/vehicle-permit-renewal'
      //     }
      //     {
      //       id: 'routePermitReplacement',
      //       title: 'Replacement',
      //       icon: <Circle size={12} />,
      //       navLink: '/vehicle/vehicle-permit-replacement'
      //     }
      //   ]
      // }
    ]
  }
]

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingTestService from '@services/driving-test'

export const getTestQuestions = createAsyncThunk('drivingTestQuestions/getTestQuestions', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingTestService.getTestQuestions(params)

    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getTestQuestion = createAsyncThunk('drivingTestQuestions/getTestQuestion', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingTestService.getTestQuestion(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addTestQuestion = createAsyncThunk('drivingTestQuestions/addTestQuestion', async (data, { rejectWithValue }) => {
  try {
    const response = await DrivingTestService.postTestQuestion(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateTestQuestion = createAsyncThunk('drivingTestQuestions/updateTestQuestion', async (data, { rejectWithValue }) => {
  try {
    const { id } = data
    const response = await DrivingTestService.updateTestQuestion(id, data)

    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

const DrivingTestQuestions = createSlice({
  name: 'drivingTestQuestions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: true,
    errors: null,
    selectedQuestion: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getTestQuestions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedQuestion = null
      })
      .addCase(getTestQuestion.pending, (state) => {
        state.selectedQuestion = null
        state.loading = true
      })
      .addCase(getTestQuestion.fulfilled, (state, action) => {
        state.loading = false
        state.errors = null
        state.selectedQuestion = action.payload
      })
      .addCase(getTestQuestion.rejected, (state, action) => {
        state.selectedQuestion = null
        state.errors = action.payload
        state.loading = false
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default DrivingTestQuestions.reducer

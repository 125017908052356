import { lazy } from 'react'

//search
const ReportConfigurationList = lazy(() => import('../../views/reports/report-configuration/list'))
const ReportConfigurationView = lazy(() => import('../../views/reports/report-configuration/view'))
const GenerateReportPage = lazy(() => import('../../views/reports/generate-report'))

const ReportRoutes = [
  // report configuration
  {
    path: '/reports/report-configurations',
    element: <ReportConfigurationList />,
    meta: {
      action: 'manage',
      resource: 'Report'
    }
  },
  {
    path: '/reports/report-configurations/view/:id',
    element: <ReportConfigurationView />,
    meta: {
      action: 'manage',
      resource: 'Report'
    }
  },
  {
    path: '/reports/generate',
    element: <GenerateReportPage />,
    meta: {
      action: 'read',
      resource: 'Report'
    }
  }
]

export default ReportRoutes

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getAllDrivingLicenseApplication = createAsyncThunk('driving-license-application/getAllDrivingLicenseApplication', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicenseApplication(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseApplication = createAsyncThunk('driving-license-application/getDrivingLicenseApplication', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseApplication(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrivingLicenseApplication = createAsyncThunk('driving-license-application/addDrivingLicenseApplication', async (application, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseApplication(application)
    dispatch(getAllDrivingLicenseApplication(getState()?.drivingLicenseApplications.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseApplicationAttachment = createAsyncThunk('driving-license-application/updateDrivingLicenseApplicationAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseApplicationAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseApplication = createAsyncThunk('driving-license-application/updateDrivingLicenseApplication', async (application, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseApplication(application)
    dispatch(getAllDrivingLicenseApplication(getState()?.drivingLicenseApplications.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const drivingLicenseApplicationPayment = createAsyncThunk('driving-license-application/drivingLicenseApplicationPayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseApplicationPayment(payment)
    return response
  } catch (err) {
    // console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const deleteDrivingLicenseApplication = createAsyncThunk('driving-license-application/deleteDrivingLicenseApplication', async (id, { dispatch, getState }) => {
  await DrivingLicenseService.deleteDrivingLicenseApplication(id)
  await dispatch(getAllDrivingLicenseApplication(getState()?.drivingLicenseApplications.params))
  return id
})

const DrivingLicenseApplication = createSlice({
  name: 'application',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDrivingLicenseApplication: null,
    loading: false
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicenseApplication.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicenseApplication = null
      })
      .addCase(getDrivingLicenseApplication.fulfilled, (state, action) => {
        state.selectedDrivingLicenseApplication = action.payload
        state.loading = false
      })
      .addCase(getDrivingLicenseApplication.pending, (state, action) => {
        state.selectedDrivingLicenseApplication = action.payload
        state.loading = true
      })
      .addCase(getDrivingLicenseApplication.rejected, (state, action) => {
        state.selectedDrivingLicenseApplication = action.payload
        state.loading = false
      })
      .addCase(updateDrivingLicenseApplication.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const application = state.data.filter((application) => application.id !== id)
        state.data = [...application, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default DrivingLicenseApplication.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getRoadSurfaceConditions = async (params) => {
  const response = await api.get(`${servicePrefix}/road-surface-condition`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllRoadSurfaceCondition = async (params) => {
  const response = await api.get(`${servicePrefix}/road-surface-condition/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addRoadSurfaceCondition = async (roadSurfaceCondition) => {
  const response = await api.post(`${servicePrefix}/road-surface-condition`, roadSurfaceCondition)
  return response.data
}

const updateRoadSurfaceCondition = async (roadSurfaceCondition) => {
  const { id } = roadSurfaceCondition
  const response = await api.patch(`${servicePrefix}/road-surface-condition/${id}`, roadSurfaceCondition)
  return response.data
}

const deleteRoadSurfaceCondition = async (id) => {
  const response = await api.delete(`${servicePrefix}/road-surface-condition/${id}`)
  return response.data
}

const RoadSurfaceConditionMasterService = {
  getRoadSurfaceConditions,
  getAllRoadSurfaceCondition,
  addRoadSurfaceCondition,
  updateRoadSurfaceCondition,
  deleteRoadSurfaceCondition
}

export default RoadSurfaceConditionMasterService

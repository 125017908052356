import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

//get learner license driving types
export const getFitnessesAll = createAsyncThunk('fitnesses/getfitnesses', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleMasterService.getAllFitnesses(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//post new learnerLicense drive type
export const addFitnesses = createAsyncThunk('fitnesses/addfitnesses', async (type, { rejectWithValue, getState, dispatch }) => {
  try {
    await VehicleMasterService.addFitnesses(type)
    await dispatch(getFitnessesAll(getState()?.vehicleFitnesses?.params))
    return type
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//update new learnerLicense drive type
export const updateFitnesses = createAsyncThunk('fitnesses/updatefitnesses', async (type, { rejectWithValue, getState, dispatch }) => {
  try {
    const reponse = await VehicleMasterService.updateFitnesses(type)
    await dispatch(getFitnessesAll(getState()?.vehicleFitnesses?.params))
    return reponse
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//delete learnerLicense drive type
export const deleteFitnesses = createAsyncThunk('fitnesses/deletefitnesses', async (id, { getState, dispatch }) => {
  await VehicleMasterService.deleteFitnesses(id)
  await dispatch(getFitnessesAll(getState()?.vehicleFitnesses?.params))
  return id
})
const fitnessesSlice = createSlice({
  name: 'vehicleFitnesses',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  extraReducers(builder) {
    builder
      .addCase(getFitnessesAll.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateFitnesses.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          return
        }
        const { id } = action.payload
        const fitnesses = state.data.filter((fitnesses) => fitnesses.id !== id)
        state.data = [...fitnesses, action.payload]
      })
  }
})

export const selectfitnessesById = (state, id) => state.vehicleFitnesses.data.find((fitnesses) => fitnesses.id === id)
export default fitnessesSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchColor = createAsyncThunk('color/getAllColor', async (params, rejectWithValue) => {
  try {
    const response = await VehicleMasterService.getAllColor(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewColor = createAsyncThunk('color/addNewColor', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addColor(color)
    dispatch(fetchColor(getState()?.colors.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateColor = createAsyncThunk('color/updateColor', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.updateColor(color)
    dispatch(fetchColor(getState()?.colors.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteColor = createAsyncThunk('color/deleteColor', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteColor(id)
  await dispatch(fetchColor(getState()?.colors.params))
  return id
})

const colorSlice = createSlice({
  name: 'color',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchColor.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateColor.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const color = state.color.filter((color) => color.id !== id)
        state.color = [...color, action.payload]
      })
  }
})

export const selectColorById = (state, id) => state.colors.data.find((color) => color.id === id)

export default colorSlice.reducer

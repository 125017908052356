import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrugTypeMasterService from '../../../../../services/vehicle-crash/masters/drug-type'

export const getDrugTypes = createAsyncThunk('drugType/getDrugTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await DrugTypeMasterService.getDrugTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrugType = createAsyncThunk('drugType/addDivision', async (drugType, { rejectWithValue, dispatch, getState }) => {
  try {
    await DrugTypeMasterService.addDrugType(drugType)
    dispatch(getDrugTypes(getState()?.drugTypes.params))
    return drugType
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrugType = createAsyncThunk('drugType/updateDrugType', async (drugType, { rejectWithValue, dispatch, getState }) => {
  try {
    await DrugTypeMasterService.updateDrugType(drugType)
    dispatch(getDrugTypes(getState()?.drugTypes.params))
    return drugType
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDrugType = createAsyncThunk('divdrugTypeision/deleteDrugType', async (id, { dispatch, getState }) => {
  await DrugTypeMasterService.deleteDrugType(id)
  await dispatch(getDrugTypes(getState()?.drugTypes.params))
  return id
})

const drugTypeSlice = createSlice({
  name: 'drugTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getDrugTypes.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectDrugTypeById = (state, id) => state.drugTypes.data.find((drugType) => drugType.id === id)

export default drugTypeSlice.reducer

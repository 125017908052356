// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import OffenceService from '@services/offence'

export const getDemerits = createAsyncThunk('demerits/getDemerits', async (params) => {
  const response = await OffenceService.getDemerits(params)
  try {
    return {
      params,
      data: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDemeritById = createAsyncThunk('demerits/getDemeritsById', async (id) => {
  const response = await OffenceService.getDemeritById(id)
  return response
})

export const updateDemerits = createAsyncThunk('demerits/updateDemerits', async (demerits, { rejectWithValue }) => {
  try {
    const response = await OffenceService.updateDemerits(demerits)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDemerits = createAsyncThunk('demerits/deleteDemerits', async (id, { getState, dispatch }) => {
  await OffenceService.deleteDemerits(id)
  await dispatch(getDemerits(getState()?.demerits?.params))
  return id
})

export const demeritsSlice = createSlice({
  name: 'demerits',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDemerit: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDemerits.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getDemeritById.fulfilled, (state, action) => {
        state.individualDemerit = action.payload
      })
  }
})

export default demeritsSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchVehicleModel = createAsyncThunk('model/getAllVehicleModel', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleMasterService.getAllVehicleModel(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
export const getIndividualVehicleModel = createAsyncThunk('model/getIndividualVehicleModel', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleMasterService.getVehicleModel(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
export const addNewVehicleModel = createAsyncThunk('model/addNewVehicleModel', async (model, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addVehicleModel(model)
    dispatch(fetchVehicleModel(getState()?.vehicleModels.params))
    return model
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleModel = createAsyncThunk('model/updateVehicleModel', async (model, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.updateVehicleModel(model)
    dispatch(fetchVehicleModel(getState()?.vehicleModels.params))
    return model
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteVehicleModel = createAsyncThunk('model/deleteVehicleModel', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteVehicleModel(id)
  await dispatch(fetchVehicleModel(getState()?.vehicleModels.params))
  return id
})

const vehicleModelSlice = createSlice({
  name: 'vehicleModel',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualVehicleModel: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchVehicleModel.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualVehicleModel = null
      })
      .addCase(getIndividualVehicleModel.fulfilled, (state, action) => {
        state.individualVehicleModel = action.payload
      })

      .addCase(updateVehicleModel.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const vehicleModel = state.data.filter((vehicleModel) => vehicleModel.id !== id)
        state.data = [...vehicleModel, action.payload]
      })
  }
})

export const getAllVehicleModels = (state) => state.vehicleModels.vehicleModel
export const selectVehicleModelById = (state, id) => state.vehicleModels.data.find((vehicleModel) => vehicleModel.id === id)

export default vehicleModelSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/common-data'

//get learner license driving types All
export const getLearnerLicenseDriveTypesAll = createAsyncThunk('learnerLicenseDriveTypes/getLearnerLicenseDriveTypesAll', async (params) => {
  try {
    const response = await MasterService.getLearnerLicenseDriveTypesAll(params)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//get learner license driving types
export const getLearnerLicenseDriveTypes = createAsyncThunk('learnerLicenseDriveTypes/getLearnerLicenseDriveTypes', async (params) => {
  const response = await MasterService.getLearnerLicenseDriveTypes(params)
  return { params, data: response.data, totalPages: response.meta.itemCount }
})
//post new learnerLicense drive type
export const addLearnerLicenseDriveType = createAsyncThunk('learnerLicenseDriveTypes/addLearnerLicenseDriveType', async (driveType, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addLearnerLicenseDriveType(driveType)
    await dispatch(getLearnerLicenseDriveTypes(getState()?.learnerLicenseDriveTypes?.params))
    return driveType
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//update new learnerLicense drive type
export const updateLeanerLicenseDriveType = createAsyncThunk('learnerLicenseDriveTypes/addLearnerLicenseDriveType/updateLeanerLicenseDriveType', async (driveType, { dispatch, getState }) => {
  await MasterService.updateLearnerLicenseDriveType(driveType)
  await dispatch(getLearnerLicenseDriveTypes(getState()?.learnerLicenseDriveTypes?.params))
  return id
})
//delete learnerLicense drive type
export const deleteLernerLicenseDriveType = createAsyncThunk('learnerLicenseDriveTypes/deleteLernerLicenseDriveType', async (id, { getState, dispatch }) => {
  await MasterService.deleteLernerLicenseDriveType(id)
  await dispatch(getLearnerLicenseDriveTypes(getState()?.learnerLicenseDriveTypes?.params))
  return id
})
const learnerLicenseDriveTypesSlice = createSlice({
  name: 'learnerLicenseDriveTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  extraReducers(builder) {
    builder
      .addCase(getLearnerLicenseDriveTypes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateLeanerLicenseDriveType.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          return
        }
        const { id } = action.payload
        const driveType = state.data.filter((driveType) => driveType.id !== id)
        state.data = [...driveType, action.payload]
      })
  }
})

export const selectLearnerLicenseDriveTypesById = (state, id) => state.learnerLicenseDriveTypes.data.find((driveType) => driveType.id === id)
export default learnerLicenseDriveTypesSlice.reducer

import { lazy } from 'react'

//Common-data master
const Country = lazy(() => import('../../views/masters/country/list'))
const Dzongkhag = lazy(() => import('../../views/masters/dzongkhag/list/index'))
const Gewog = lazy(() => import('../../views/masters/gewog/list/index'))
const GewogEdit = lazy(() => import('../../views/masters/gewog/edit'))
const GewogAdd = lazy(() => import('../../views/masters/gewog/add'))
const OfficeLocation = lazy(() => import('../../views/masters/office-location/list/index'))
const OfficeLocationAdd = lazy(() => import('../../views/masters/office-location/add'))
const OfficeLocationEdit = lazy(() => import('../../views/masters/office-location/edit'))
const Region = lazy(() => import('../../views/masters/region/list/index'))
const LearnerLicenseDriveTypes = lazy(() => import('../../views/masters/learner-license-drive-type/list'))
const LearnerNumberSequencesList = lazy(() => import('../../views/masters/learner-number-sequences/list/index'))
const LearnerNumberSequencesAdd = lazy(() => import('../../views/masters/learner-number-sequences/add/index'))
const EditLearnerNumberSequences = lazy(() => import('../../views/masters/learner-number-sequences/edit/index'))

//Vehicle master
const Color = lazy(() => import('../../views/masters/color/list/index'))
const Dealer = lazy(() => import('../../views/masters/dealer/list/index'))
const EngineType = lazy(() => import('../../views/masters/engine-type/list/index'))
const RegistrationCode = lazy(() => import('../../views/masters/registration-code/list/index'))
const VehicleCompany = lazy(() => import('../../views/masters/vehicle-company/list/index'))
const VehicleModelName = lazy(() => import('../../views/masters/vehicle-model-name/list/index'))
const VehicleModelNameEdit = lazy(() => import('../../views/masters/vehicle-model-name/edit'))
const VehicleModelVariant = lazy(() => import('../../views/masters/vehicle-model-variant/list/index'))
const VehicleModelVariantEdit = lazy(() => import('../../views/masters/vehicle-model-variant/edit'))
const VehicleType = lazy(() => import('../../views/masters/vehicle-type/list/index'))
const Fitnesses = lazy(() => import('../../views/masters/fitnesses/list/index'))
const CancellationReasons = lazy(() => import('../../views/masters/cancellation-reasons/list/index'))
const CancellationReasonsEdit = lazy(() => import('../../views/masters/cancellation-reasons/edit/index'))
const CancellationTypes = lazy(() => import('../../views/masters/cancellation-types/list/index'))
const CancellationTypesEdit = lazy(() => import('../../views/masters/cancellation-types/edit/index'))
const TransferTypes = lazy(() => import('../../views/masters/transfer-types/list/index'))
const TransferTypesEdit = lazy(() => import('../../views/masters/transfer-types/edit/index'))
const ConversionTypes = lazy(() => import('../../views/masters/conversion-types/list/index'))
const ConversionTypesEdit = lazy(() => import('../../views/masters/conversion-types/edit/index'))
const RouteList = lazy(() => import('../../views/masters/route-lists/list'))
const BusCategoryList = lazy(() => import('../../views/masters/bus-category-types/list/index'))
const EditBusCategory = lazy(() => import('../../views/masters/bus-category-types/edit/index'))
const VehicleLifeSpansList = lazy(() => import('../../views/masters/vehicle-life-spans/list/index'))
const EditVehicleLifeSpans = lazy(() => import('../../views/masters/vehicle-life-spans/edit/index'))
const VehicleNumberSquenceList = lazy(() => import('../../views/masters/registration-number-sequences/list/index'))
const VehicleNumberSquenceAdd = lazy(() => import('../../views/masters/registration-number-sequences/add/index'))
const EditVehicleNumberSquence = lazy(() => import('../../views/masters/registration-number-sequences/edit/index'))

//Inspection places
const InspectionPlaces = lazy(() => import('../../views/masters/inspection-places/list/index'))
const InspectionPlacesEdit = lazy(() => import('../../views/masters/inspection-places/edit'))

//Traffic Divisions
const TrafficDivisions = lazy(() => import('../../views/masters/traffic-divisions/list/index'))

//Offences
const Offences = lazy(() => import('../../views/masters/offences/list/index'))
const ListDemeritConfigurations = lazy(() => import('../../views/masters/demerit-configuration/list/index'))
//Admin master
const Agency = lazy(() => import('../../views/masters/agency/list/index'))

//Driving License
const DrivingLicenseDriveType = lazy(() => import('../../views/masters/driving-license-drive-types/list/index'))
const EditDrivingLicenseDriveType = lazy(() => import('../../views/masters/driving-license-drive-types/edit/index'))
const DrivingLicenseLifeSpan = lazy(() => import('../../views/masters/driving-license-life-spans/list/index'))
const EditDrivingLicenseLifeSpan = lazy(() => import('../../views/masters/driving-license-life-spans/edit/index'))
const DrivingLicenseNumberSquenceList = lazy(() => import('../../views/masters/driving-license-number-sequences/list/index'))
const DrivingLicenseNumberSquenceAdd = lazy(() => import('../../views/masters/driving-license-number-sequences/add/index'))
const EditDrivingLicenseNumberSquence = lazy(() => import('../../views/masters/driving-license-number-sequences/edit/index'))

const MasterRoutes = [
  //Country Route
  {
    path: '/master/countries',
    element: <Country />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Dzongkhag Route
  {
    path: '/master/dzongkhags',
    element: <Dzongkhag />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Gewog Route
  {
    path: '/master/gewogs',
    element: <Gewog />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/gewogs/add',
    element: <GewogAdd />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/gewogs/:id',
    element: <GewogEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  // Office location Route
  {
    path: '/master/office-locations',
    element: <OfficeLocation />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/office-locations/add',
    element: <OfficeLocationAdd />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/office-locations/:id',
    element: <OfficeLocationEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  // Region Route
  {
    path: '/master/regions',
    element: <Region />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  // Vehicle Color Route
  {
    path: '/master/colors',
    element: <Color />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  // Vehicle Engine Type Route
  {
    path: '/master/engine-types',
    element: <EngineType />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  // Vehicle Dealer Route
  {
    path: '/master/dealers',
    element: <Dealer />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Vehicle Registration Code Route
  {
    path: '/master/registration-codes',
    element: <RegistrationCode />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Vehicle Company Route
  {
    path: '/master/vehicle-companies',
    element: <VehicleCompany />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Vehicle Model Name Route
  {
    path: '/master/vehicle-model-names',
    element: <VehicleModelName />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/vehicle-model-names/:id',
    element: <VehicleModelNameEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Vehicle Model Detail Route
  {
    path: '/master/vehicle-model-variants',
    element: <VehicleModelVariant />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/vehicle-model-variants/:id',
    element: <VehicleModelVariantEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Vehicle Type Route
  {
    path: '/master/vehicle-types',
    element: <VehicleType />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Fitnesses
  {
    path: '/master/vehicle-fitnesses',
    element: <Fitnesses />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Cancellation Reasons Route
  {
    path: '/master/vehicle-cancellation-reasons',
    element: <CancellationReasons />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  {
    path: '/master/vehicle-cancellation-reasons/:id',
    element: <CancellationReasonsEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Cancellation Types Route
  {
    path: '/master/vehicle-cancellation-types',
    element: <CancellationTypes />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  {
    path: '/master/vehicle-cancellation-types/:id',
    element: <CancellationTypesEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Transfer Types Route
  {
    path: '/master/vehicle-transfer-types',
    element: <TransferTypes />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  {
    path: '/master/vehicle-transfer-types/:id',
    element: <TransferTypesEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Conversion Route
  {
    path: '/master/vehicle-conversion-types',
    element: <ConversionTypes />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/vehicle-conversion-types/:id',
    element: <ConversionTypesEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Agency Route
  {
    path: '/master/agencies',
    element: <Agency />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //learner-license-drive-types
  {
    path: '/master/learnerLicenseDriveTypes',
    element: <LearnerLicenseDriveTypes />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Inspection places
  {
    path: '/master/inspection-places',
    element: <InspectionPlaces />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/inspection-places/:id',
    element: <InspectionPlacesEdit />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Traffic Divisions
  {
    path: '/master/traffic-divisions',
    element: <TrafficDivisions />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Offences
  {
    path: '/master/offences',
    element: <Offences />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Offences
  {
    path: '/master/demerit-configurations',
    element: <ListDemeritConfigurations />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  //Driving License
  {
    path: '/master/driving-license-drive-types',
    element: <DrivingLicenseDriveType />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/driving-license-drive-types/:id',
    element: <EditDrivingLicenseDriveType />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/driving-license-life-spans',
    element: <DrivingLicenseLifeSpan />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },
  {
    path: '/master/driving-license-life-spans/:id',
    element: <EditDrivingLicenseLifeSpan />,
    meta: {
      action: 'manage',
      resource: 'Masters'
    }
  },

  // Vehicle RouteList
  {
    path: '/master/route-list',
    element: <RouteList />,
    meta: {
      action: 'read',
      resource: 'Masters'
    }
  },

  //bus category type
  {
    path: '/master/bus-category-types',
    element: <BusCategoryList />,
    meta: {
      action: 'read',
      resource: 'Masters'
    }
  },
  {
    path: '/master/bus-category-types/:id',
    element: <EditBusCategory />,
    meta: {
      action: 'update',
      resource: 'Masters'
    }
  },

  //vehicle life spans
  {
    path: '/master/vehicle-life-spans',
    element: <VehicleLifeSpansList />,
    meta: {
      action: 'read',
      resource: 'Masters'
    }
  },
  {
    path: '/master/vehicle-life-spans/:id',
    element: <EditVehicleLifeSpans />,
    meta: {
      action: 'update',
      resource: 'Masters'
    }
  },

  //vehicle number sequence
  {
    path: '/master/vehicle-number-sequences',
    element: <VehicleNumberSquenceList />,
    meta: {
      action: 'read',
      resource: 'Masters'
    }
  },
  {
    path: '/master/vehicle-number-sequences/add',
    element: <VehicleNumberSquenceAdd />,
    meta: {
      action: 'create',
      resource: 'Masters'
    }
  },
  {
    path: '/master/vehicle-number-sequences/:id/edit',
    element: <EditVehicleNumberSquence />,
    meta: {
      action: 'update',
      resource: 'Masters'
    }
  },

  //learner number sequence
  {
    path: '/master/learner-number-sequences',
    element: <LearnerNumberSequencesList />,
    meta: {
      action: 'read',
      resource: 'Masters'
    }
  },
  {
    path: '/master/learner-number-sequences/add',
    element: <LearnerNumberSequencesAdd />,
    meta: {
      action: 'create',
      resource: 'Masters'
    }
  },
  {
    path: '/master/learner-number-sequences/:id/edit',
    element: <EditLearnerNumberSequences />,
    meta: {
      action: 'update',
      resource: 'Masters'
    }
  },

  //learner number sequence
  {
    path: '/master/driving-license-number-sequences',
    element: <DrivingLicenseNumberSquenceList />,
    meta: {
      action: 'read',
      resource: 'Masters'
    }
  },
  {
    path: '/master/driving-license-number-sequences/add',
    element: <DrivingLicenseNumberSquenceAdd />,
    meta: {
      action: 'create',
      resource: 'Masters'
    }
  },
  {
    path: '/master/driving-license-number-sequences/:id/edit',
    element: <EditDrivingLicenseNumberSquence />,
    meta: {
      action: 'update',
      resource: 'Masters'
    }
  }
]

export default MasterRoutes

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getAllDrivingLicenseSuspension = createAsyncThunk('suspension/getAllDrivingLicenseSuspension', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicenseSuspension(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseSuspension = createAsyncThunk('suspension/getDrivingLicenseSuspension', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseSuspension(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrivingLicenseSuspension = createAsyncThunk('suspension/addDrivingLicenseSuspension', async (suspension, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseSuspension(suspension)
    dispatch(getAllDrivingLicenseSuspension(getState()?.drivingLicenseSuspensions.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseSuspension = createAsyncThunk('suspension/updateDrivingLicenseSuspension', async (suspension, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseSuspension(suspension)
    dispatch(getAllDrivingLicenseSuspension(getState()?.drivingLicenseSuspensions.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseSuspensionAttachment = createAsyncThunk('suspension/updateDrivingLicenseSuspensionAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseSuspensionAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const DrivingLicenseSuspensionWithdrawal = createAsyncThunk('licensePunch/DrivingLicensePunchWithdrawal', async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.DrivingLicenseSuspensionWithdrawal(data)
    dispatch(getAllDrivingLicenseSuspension(getState()?.drivingLicensePrintLists.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const updateDrivingLicenseSuspensionAttachment = createAsyncThunk('suspension/updateDrivingLicenseSuspensionAttachment', async (attachment) => {
//   const response = await DrivingLicenseService.updateDrivingLicenseSuspensionAttachment(attachment)
//   // console.log('conversion response', response)
//   return response
// })

const Suspension = createSlice({
  name: 'drivingLicenseSuspensions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDrivingLicenseSuspension: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicenseSuspension.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicenseSuspension = null
      })
      .addCase(getDrivingLicenseSuspension.fulfilled, (state, action) => {
        console.log(state)
        console.log(action)

        state.selectedDrivingLicenseSuspension = action.payload
      })
      .addCase(updateDrivingLicenseSuspension.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const suspension = state.data.filter((suspension) => suspension.id !== id)
        state.data = [...suspension, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default Suspension.reducer

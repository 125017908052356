import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import AdminMasterService from '@services/masters/admin-master'

export const fetchAgency = createAsyncThunk('agency/getAllAgency', async (params, { rejectWithValue }) => {
  try {
    const response = await AdminMasterService.getAllAgency(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewAgency = createAsyncThunk('agency/addNewAgency', async (agency, { rejectWithValue, dispatch, getState }) => {
  try {
    await AdminMasterService.addAgency(agency)
    dispatch(fetchAgency(getState()?.agencies?.params))
    return agency
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

export const updateAgency = createAsyncThunk('agency/updateAgency', async (agency, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await AdminMasterService.updateAgency(agency)
    dispatch(fetchAgency(getState()?.agencies?.params))
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteAgency = createAsyncThunk('agency/deleteAgency', async (id, { dispatch, getState }) => {
  await AdminMasterService.deleteAgency(id)
  await dispatch(fetchAgency(getState()?.agencies?.params))
  return id
})

const agencySlice = createSlice({
  name: 'agency',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchAgency.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateAgency.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const agency = state.agency.filter((agency) => agency.id !== id)
        state.agency = [...agency, action.payload]
      })
  }
})
export const selectAgencyById = (state, id) => state.agencies.data.find((agency) => agency.id === id)
export default agencySlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/vehicle-master'

export const getAllCancellationTypes = createAsyncThunk('cancellation-types/getAllCancellationTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllCancellationType(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getCancellationType = createAsyncThunk('cancellation-types/getCancellationType', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getCancellationType(id)
    // console.log('type', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addCancellationType = createAsyncThunk('cancellation-types/addCancellationType', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await MasterService.addCancellationType(type)
    dispatch(getAllCancellationTypes(getState()?.vehicleCancellationTypes))
    return response
  } catch (err) {
    return rejectWithValue(err.type.data)
  }
})

export const updateCancellationType = createAsyncThunk('cancellation-types/updateCancellationType', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await MasterService.updateCancellationType(type)
    dispatch(getAllCancellationTypes(getState()?.vehicleCancellationTypes.params))
    return response
  } catch (err) {
    return rejectWithValue(err.type.data)
  }
})

export const deleteCancellationType = createAsyncThunk('cancellation-types/deleteCancellationType', async (id, { dispatch, getState }) => {
  await MasterService.deleteCancellationType(id)
  await dispatch(getAllCancellationTypes(getState()?.vehicleCancellationTypes.params))
  return id
})

const cancellationType = createSlice({
  name: 'cancellation_type',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCancellationType: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllCancellationTypes.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedCancellationType = null
      })
      .addCase(getCancellationType.fulfilled, (state, action) => {
        state.selectedCancellationType = action.payload
      })
      .addCase(updateCancellationType.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const cancellationType = state.data.filter((cancellationType) => cancellationType.id !== id)
        state.data = [...cancellationType, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default cancellationType.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehicleNocIssuance = createAsyncThunk('nocIssuance/getAllVehicleNocIssuance', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getAllVehicleNoc(params)
    // console.log('cc', response.data)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getVehicleNocIssuance = createAsyncThunk('nocIssuance/getVehicleNocIssuance', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getVehicleNoc(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addVehicleNocIssuance = createAsyncThunk('nocIssuance/addVehicleNocIssuance', async (nocIssuance, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleNoc(nocIssuance)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const updateVehicleCancellationAttachment = createAsyncThunk('fitness/updateVehicleCancellationAttachment', async (data, { rejectWithValue }) => {
//   try {
//     const id = data.get('id')
//     const response = await VehicleService.updateVehicleCancellationAttachment(id, data)
//     return response.data
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

// export const updateVehicleCancellation = createAsyncThunk('fitness/updateVehicleCancellation', async (cancellation, { rejectWithValue, dispatch, getState }) => {
//   try {
//     const response = await VehicleService.updateVehicleCancellation(cancellation)
//     dispatch(getAllVehicleCancellation(getState()?.vehicleCancellations.params))
//     return response
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

// export const deleteVehicleCancellation = createAsyncThunk('fitness/deleteVehicleCancellation', async (id, { dispatch, getState }) => {
//   await VehicleService.deleteVehicleCancellation(id)
//   await dispatch(getAllVehicleCancellation(getState()?.vehicleCancellations.params))
//   return id
// })

const cancellation = createSlice({
  name: 'nocIssuance',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedNocIssuance: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllVehicleNocIssuance.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getVehicleNocIssuance.fulfilled, (state, action) => {
        state.selectedNocIssuance = action.payload
      })

    // .addCase(updateVehicleCancellation.fulfilled, (state, action) => {
    //   if (!action.payload?.id) {
    //     console.log('Update could not complete')
    //     console.log(action.payload)
    //     return
    //   }
    //   const { id } = action.payload
    //   const cancellation = state.data.filter((cancellation) => cancellation.id !== id)
    //   state.data = [...cancellation, action.payload]
    // })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default cancellation.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const addDrivingLicensePunch = createAsyncThunk('licensePunch/addToPrintList', async (printData, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicensePunch(printData)
    console.log(response)
    return response
  } catch (err) {
    return rejectWithValue(err?.response?.data)
  }
})

export const getAllDrivingLicensePunch = createAsyncThunk('licensePunch/getAllDrivingLicensePunch', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicensePunch(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicensePunch = createAsyncThunk('licensePunch/getDrivingLicensePunch', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicensePunch(id)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicensePunch = createAsyncThunk('licensePunch/updateDrivingLicensePunch', async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicensePunch(data)
    dispatch(getAllDrivingLicensePunch(getState()?.drivingLicensePrintLists.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const DrivingLicensePunchWithdrawal = createAsyncThunk('licensePunch/DrivingLicensePunchWithdrawal', async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.DrivingLicensePunchWithdrawal(data)
    dispatch(getAllDrivingLicensePunch(getState()?.drivingLicensePrintLists.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

const DrivingLicensePunch = createSlice({
  name: 'drivingLicensePunch',
  initialState: {
    data: [],
    total: 0,
    params: {},
    allData: [],
    selectedDrivingLicensePunch: null,
    loading: true,
    error: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicensePunch.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicensePunch = null
        state.loading = false
        state.error = null
      })
      .addCase(getDrivingLicensePunch.fulfilled, (state, action) => {
        state.selectedDrivingLicensePunch = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(updateDrivingLicensePunch.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const print = state.data.filter((print) => print.id !== id)
        state.data = [...print, action.payload]
        state.loading = false
        state.error = null
      })
  }
})

export default DrivingLicensePunch.reducer

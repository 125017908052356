import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import NonMotoristActionMasterService from '../../../../../services/vehicle-crash/masters/non-motorist-action'

export const getNonMotoristActions = createAsyncThunk('nonMotoristAction/getNonMotoristActions', async (params, { rejectWithValue }) => {
  try {
    const response = await NonMotoristActionMasterService.getNonMotoristActions(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNonMotoristAction = createAsyncThunk('nonMotoristAction/addNonMotoristAction', async (nonMotoristAction, { rejectWithValue, dispatch, getState }) => {
  try {
    await NonMotoristActionMasterService.addNonMotoristAction(nonMotoristAction)
    dispatch(getNonMotoristActions(getState()?.nonMotoristActions.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateNonMotoristAction = createAsyncThunk('nonMotoristAction/updateNonMotoristAction', async (nonMotoristAction, { rejectWithValue, dispatch, getState }) => {
  try {
    await NonMotoristActionMasterService.updateNonMotoristAction(nonMotoristAction)
    dispatch(getNonMotoristActions(getState()?.nonMotoristActions.params))
    return nonMotoristAction
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteNonMotoristAction = createAsyncThunk('nonMotoristAction/deleteNonMotoristAction', async (id, { dispatch, getState }) => {
  await NonMotoristActionMasterService.deleteNonMotoristAction(id)
  await dispatch(getNonMotoristActions(getState()?.nonMotoristActions.params))
  return id
})

const NonMotoristActionSlice = createSlice({
  name: 'nonMotoristActions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getNonMotoristActions.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectNonMotoristActionById = (state, id) => state.nonMotoristActions.data.find((nonMotoristActions) => nonMotoristActions.id === id)

export default NonMotoristActionSlice.reducer

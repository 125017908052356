// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerLicenseService from '@services/learner-license/index'

export const getOnlineApplicationsList = createAsyncThunk('learnerLicense/getOnlineApplication', async (params) => {
  const response = await LearnerLicenseService.getOnlineRegistrationList(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updatePisApplication = createAsyncThunk('learnerLicense/updatePisApplication', async (pisInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updateOnlinePisApplication(pisInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getOnlineApplicationsById = createAsyncThunk('learnerLicense/getOnlineApplicationById', async (id) => {
  const response = await LearnerLicenseService.getOnlineApplicationById(id)
  return response
})

export const addLearnerLicenseRenewal = createAsyncThunk('learnerLicenseApplication/addLearnerLicenseApplication', async (learnerLicenseInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.addLearnerLicenseRenewal(learnerLicenseInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerLicenseApplication = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseApplication', async (learnerLicenseInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updateLearnerLicenseApplication(learnerLicenseInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const paymentUpdate = createAsyncThunk('learnerLicense/paymentUpdate', async (paymentInfo, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updateOnlinePayment(paymentInfo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerLicenseRenewalAttachment = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseRenewalAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await LearnerLicenseService.updateLearnerLicenseRenewalAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLearnerLicenseAttachment = createAsyncThunk('learner/updateLearnerLicenseAttachment', async (data, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.updateOnlineLearnerLicenseAttachment(data)
    return response.data
  } catch (err) {
    console.log('error', err)
    return rejectWithValue(err.response.data)
  }
})

export const learnerLicenseRegistrationSlice = createSlice({
  name: 'learnerOnlineApplication',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualApplication: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOnlineApplicationsList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getOnlineApplicationsById.fulfilled, (state, action) => {
        state.individualApplication = action.payload
      })
  }
})

export default learnerLicenseRegistrationSlice.reducer

import { lazy } from 'react'

//search
const DrivingTestConfigurationList = lazy(() => import('../../views/driving-test/test-configuration/list'))
const AddNewTestDate = lazy(() => import('../../views/driving-test/test-configuration/add'))
const TestConfigurationView = lazy(() => import('../../views/driving-test/test-configuration/view'))
const DrivingETestCandidateList = lazy(() => import('../../views/driving-test/e-test-candidate/list'))
const DrivingTestCandidateList = lazy(() => import('../../views/driving-test/test-candidate/list'))
const DrivingTestQuestionList = lazy(() => import('../../views/driving-test/question/list'))
const TestQuestionView = lazy(() => import('../../views/driving-test/question/view'))
const AddTestMarksheet = lazy(() => import('../../views/driving-test/test-marksheet/add'))

const DrivingTestRoutes = [
  // test configuration
  {
    path: '/driving-test/test-configurations',
    element: <DrivingTestConfigurationList />,
    meta: {
      action: 'read',
      resource: 'ETestManagement'
    }
  },
  {
    path: '/driving-test/test-configurations/view/:id',
    element: <TestConfigurationView />,
    meta: {
      action: 'read',
      resource: 'ETestManagement'
    }
  },
  {
    path: '/driving-test/test-configurations/view/:id/e-test-candidates',
    element: <DrivingETestCandidateList />,
    meta: {
      action: 'read',
      resource: 'ETestManagement'
    }
  },
  {
    path: '/driving-test/test-configurations/view/:id/test-candidates',
    element: <TestConfigurationView />,
    meta: {
      action: 'read',
      resource: 'ETestManagement'
    }
  },
  {
    path: '/driving-test/test-candidates',
    element: <DrivingTestCandidateList />,
    meta: {
      action: 'manage',
      resource: 'ETestManagement'
    }
  },
  {
    path: '/driving-test/test-configurations/new',
    element: <AddNewTestDate />,
    meta: {
      action: 'manage',
      resource: 'ETestManagement'
    }
  },
  {
    path: '/driving-test/test-questions',
    element: <DrivingTestQuestionList />,
    meta: {
      action: 'read',
      resource: 'ETestManagement'
    }
  },
  {
    path: '/driving-test/test-questions/view/:id',
    element: <TestQuestionView />,
    meta: {
      action: 'read',
      resource: 'ETestManagement'
    }
  },
  {
    path: '/driving-test/test-marksheet/add',
    element: <AddTestMarksheet />,
    meta: {
      action: 'manage',
      resource: 'Marksheet'
    }
  }
]

export default DrivingTestRoutes

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import CollisionTypeMasterService from '@services/vehicle-crash/masters/collision-type'

export const getCollisionTypes = createAsyncThunk('collision-type/getCollisionTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await CollisionTypeMasterService.getCollisionTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addCollisionType = createAsyncThunk('collision-type/addCollisionType', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await CollisionTypeMasterService.addCollisionType(color)
    dispatch(getCollisionTypes(getState()?.collisionTypes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateCollisionType = createAsyncThunk('collision-type/updateCollisionType', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await CollisionTypeMasterService.updateCollisionType(color)
    dispatch(getCollisionTypes(getState()?.collisionTypes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteCollisionType = createAsyncThunk('collision-type/deleteCollisionType', async (id, { dispatch, getState }) => {
  await CollisionTypeMasterService.deleteCollisionType(id)
  await dispatch(getCollisionTypes(getState()?.collisionTypes.params))
  return id
})

const collisionTypesSlice = createSlice({
  name: 'collisionTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getCollisionTypes.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectCollisionTypeById = (state, id) => state.collisionTypes.data.find((collisionType) => collisionType.id === id)

export default collisionTypesSlice.reducer

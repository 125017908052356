// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/common-data'

export const fetchOfficeLocations = createAsyncThunk('office-location/getAllOfficeLocations', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllOfficeLocations(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getIndividualOfficeLocation = createAsyncThunk('office-location/getIndividualOfficeLocation', async (id, { rejectWithValue }) => {
  try {
    const response = await MasterService.getIndividualOfficeLocation(id)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addOfficeLocations = createAsyncThunk('office-location/addOfficeLocations', async (office_location, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addOfficeLocations(office_location)
    dispatch(fetchOfficeLocations(getState()?.officeLocations.params))
    return office_location
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateOfficeLocations = createAsyncThunk('office-location/updateOfficeLocations', async (office_location, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.updateOfficeLocations(office_location)
    dispatch(fetchOfficeLocations(getState()?.officeLocations.params))
    return office_location
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteOfficeLocations = createAsyncThunk('office-location/deleteOfficeLocations', async (id, { dispatch, getState }) => {
  await MasterService.deleteOfficeLocations(id)
  await dispatch(fetchOfficeLocations(getState()?.officeLocations.params))
  return id
})

const officeLocationListSlice = createSlice({
  name: 'office_location',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualOfficeLocation: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchOfficeLocations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualOfficeLocation = null
      })
      .addCase(getIndividualOfficeLocation.fulfilled, (state, action) => {
        state.individualOfficeLocation = action.payload
      })
      .addCase(updateOfficeLocations.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const office_location = state.data.filter((office_location) => office_location.id !== id)
        state.data = [...office_location, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default officeLocationListSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerLicenseService from '@services/learner-license/index'

export const getLearnerLicenseReplacement = createAsyncThunk('learnerLicense/getLearnerLicenseReplacement', async (params) => {
  const response = await LearnerLicenseService.getLearnerLicenseReplacement(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getLearnerLicenseReplacementById = createAsyncThunk('learnerLicense/getLearnerLicenseReplacementById', async (id) => {
  const response = await LearnerLicenseService.getLearnerLicenseReplacementById(id)
  return response
})

export const learnerLicenseReplacementSlice = createSlice({
  name: 'learnerLicenseReplacement',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLearnerLicenseReplacement: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLearnerLicenseReplacement.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLearnerLicenseReplacementById.fulfilled, (state, action) => {
        state.individualLearnerLicenseReplacement = action.payload
      })
  }
})

export default learnerLicenseReplacementSlice.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getNonMotoristLocations = async (params) => {
  const response = await api.get(`${servicePrefix}/non-motorist-locations`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllNonMotoristLocation = async () => {
  const response = await api.get(`${servicePrefix}/non-motorist-locations/all`)
  return response.data
}

const addNonMotoristLocation = async (nonMotoristLocation) => {
  const response = await api.post(`${servicePrefix}/non-motorist-locations`, nonMotoristLocation)
  return response.data
}

const updateNonMotoristLocation = async (nonMotoristLocation) => {
  const { id } = nonMotoristLocation
  const response = await api.patch(`${servicePrefix}/non-motorist-locations/${id}`, nonMotoristLocation)
  return response.data
}

const deleteNonMotoristLocation = async (id) => {
  const response = await api.delete(`${servicePrefix}/non-motorist-locations/${id}`)
  return response.data
}

const NonMotoristLocationMasterService = {
  getNonMotoristLocations,
  getAllNonMotoristLocation,
  addNonMotoristLocation,
  updateNonMotoristLocation,
  deleteNonMotoristLocation
}

export default NonMotoristLocationMasterService

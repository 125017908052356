import { lazy } from 'react'

//applicatios
const DrivingLicenseApplicationList = lazy(() => import('../../views/driving-license/driving-license-application/list/index'))
const AddDrivingLicenseApplication = lazy(() => import('../../views/driving-license/driving-license-application/add/index'))
const AddDrivingLicenseDetails = lazy(() => import('../../views/driving-license/driving-license-application/add/DrivingLicenseDetails'))
const AddDrivingLicenseAttachments = lazy(() => import('../../views/driving-license/driving-license-application/add/Attachment'))
const AddDrivingLicensePayment = lazy(() => import('../../views/driving-license/driving-license-application/payment/index'))
const DrivingLicenseApplicationView = lazy(() => import('../../views/driving-license/driving-license-application/view/index'))
const EditDrivingLicenseApplication = lazy(() => import('../../views/driving-license/driving-license-application/edit/index'))
const EditDrivingLicenseApplicationAttachment = lazy(() => import('../../views/driving-license/driving-license-application/edit/Attachment'))

//endorsement application
const DrivingLicenseEndorsementApplicationList = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/list/index'))
const AddDrivingLicenseEndorsementApplication = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/add/index'))
const AddDrivingLicenseEndorsementDetails = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/add/DrivingLicenseDetails'))
const AddProfessionalDrivingLicenseEndorsementDetails = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/add/ProfessionalDrivingLicenseDetails'))
const AddDrivingLicenseEndorsementAttachments = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/add/Attachment'))
const AddDrivingLicenseEndorsementPayment = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/payment/index'))
const DrivingLicenseEndorsementApplicationView = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/view/index'))
const EditDrivingLicenseEndorsementApplication = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/edit/index'))
const EditDrivingLicenseEndorsementApplicationAttachment = lazy(() => import('../../views/driving-license/driving-license-endorsement-application/edit/Attachment'))
const SearchEndorsements = lazy(() => import('../../views/driving-license/endorsement-cancellation/add/index'))

//ordinary driving license
const OrdinaryDrivingLicenseList = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/list/index'))
const OrdinaryDrivingLicenseView = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/view/index'))
const OrdinaryDrivingLicenseRenewal = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/renewal/index'))
const OrdinaryDrivingLicenseRenewalAttachment = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/renewal/Attachment'))
const OrdinaryDrivingLicenseRenewalPayment = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/renewal/payment/Payment'))
const OrdinaryDrivingLicenseReplacement = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/replacement/index'))
const OrdinaryDrivingLicenseReplacementAttachment = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/replacement/Attachment'))
const OrdinaryDrivingLicenseReplacementPayment = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/replacement/payment/Payment'))
const OrdinaryDrivingLicenseSuspension = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/suspension/index'))
const OrdinaryDrivingLicenseSuspensionAttachment = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/suspension/Attachment'))
const OrdinaryDrivingLicenseCancellation = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/cancellation/index'))
const OrdinaryDrivingLicenseCancellationAttachment = lazy(() => import('../../views/driving-license/driving-license-types/ordinary-driving-license/cancellation/Attachment'))

//professional driving license
const ProfessionalDrivingLicenseList = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/list/index'))
const ProfessionalDrivingLicenseView = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/view/index'))
const ProfessionalDrivingLicenseRenewal = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/renewal/index'))
const ProfessionalDrivingLicenseRenewalAttachment = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/renewal/Attachment'))
const ProfessionalDrivingLicenseRenewalPayment = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/renewal/payment/Payment'))
const ProfessionalDrivingLicenseReplacement = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/replacement/index'))
const ProfessionalDrivingLicenseReplacementAttachment = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/replacement/Attachment'))
const ProfessionalDrivingLicenseReplacementPayment = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/replacement/payment/Payment'))
const ProfessionalDrivingLicenseSuspension = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/suspension/index'))
const ProfessionalDrivingLicenseSuspensionAttachment = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/suspension/Attachment'))
const ProfessionalDrivingLicenseCancellation = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/cancellation/index'))
const ProfessionalDrivingLicenseCancellationAttachment = lazy(() => import('../../views/driving-license/driving-license-types/professional-driving-license/cancellation/Attachment'))

//renewal
const DrivingLicenseRenewalPending = lazy(() => import('../../views/driving-license/driving-license-types/renewal/list/index'))
const DrivingLicenseRenewalPendingView = lazy(() => import('../../views/driving-license/driving-license-types/renewal/view/index'))

//replacement
const DrivingLicenseReplacementPending = lazy(() => import('../../views/driving-license/driving-license-types/replacement/list/index'))
const DrivingLicenseReplacementPendingView = lazy(() => import('../../views/driving-license/driving-license-types/replacement/view/index'))

//print list
const DrivingLicensePrintList = lazy(() => import('../../views/driving-license/print-list/list/index'))
const EditDrivingLicensePrintList = lazy(() => import('../../views/driving-license/driving-license-application/edit/index'))
const DrivingLicensePrintView = lazy(() => import('../../views/driving-license/print-list/view/index'))

//cancellation
const DrivingLicenseCancellationList = lazy(() => import('../../views/driving-license/driving-license-types/cancellation/list/index'))
const DrivingLicenseCancellationView = lazy(() => import('../../views/driving-license/driving-license-types/cancellation/view/index'))
const DrivingLicenseCancellationWithdrawal = lazy(() => import('../../views/driving-license/driving-license-types/cancellation/view/DrivingLicenseCancellationWithdrawal'))
//suspension
const DrivingLicenseSuspensionList = lazy(() => import('../../views/driving-license/driving-license-types/suspension/list/index'))

//search
const DrivingLicenseRenewalSearch = lazy(() => import('../../views/driving-license/driving-license-types/renewal/search'))
const DrivingLicenseReplacementSearch = lazy(() => import('../../views/driving-license/driving-license-types/replacement/search'))
const DrivingLicenseSuspensionSearch = lazy(() => import('../../views/driving-license/driving-license-types/suspension/search'))
const DrivingLicenseCancellationSearch = lazy(() => import('../../views/driving-license/driving-license-types/cancellation/search'))
const DrivingLicenseSearchWithCid = lazy(() => import('../../views/driving-license/driving-license-types/search/search'))

//license punch
const DrivingLicensePunchList = lazy(() => import('../../views/driving-license/license-punch/list/index'))
const DrivingLicensePunchAdd = lazy(() => import('../../views/driving-license/license-punch/add/index'))
const DrivingLicensePunchDetails = lazy(() => import('../../views/driving-license/license-punch/add/LicensePunchDetails'))
const DrivingLicensePunchView = lazy(() => import('../../views/driving-license/license-punch/view/index'))
const DrivingLicensePunchWithdraw = lazy(() => import('../../views/driving-license/license-punch/view/withdrawal'))
const DrivingLicenseSuspensionView = lazy(() => import('../../views/driving-license/driving-license-types/suspension/view/index'))
const DrivingLicenseSuspensionWithdraw = lazy(() => import('../../views/driving-license/driving-license-types/suspension/view/withdrawal'))

//endorsement cancellation
const EndorsementCancellationList = lazy(() => import('../../views/driving-license/endorsement-cancellation/list/index'))
const EndorsementCancellation = lazy(() => import('../../views/driving-license/endorsement-cancellation/add/index'))
const EndorsementCancellationDetails = lazy(() => import('../../views/driving-license/endorsement-cancellation/add/CancellationDetails'))
const EndorsementCancellationAttachment = lazy(() => import('../../views/driving-license/endorsement-cancellation/add/Attachment'))
const EndorsementCancellationView = lazy(() => import('../../views/driving-license/endorsement-cancellation/view/index'))

const DrivingLicenseRoutes = [
  //application
  {
    path: '/driving-license/applications',
    element: <DrivingLicenseApplicationList />,
    meta: {
      action: 'read',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/applications/add',
    element: <AddDrivingLicenseApplication />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/applications/add/driving-license-details',
    element: <AddDrivingLicenseDetails />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/applications/add/:id/attachments',
    element: <AddDrivingLicenseAttachments />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/applications/:id/payment',
    element: <AddDrivingLicensePayment />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/applications/:id/view',
    element: <DrivingLicenseApplicationView />,
    meta: {
      action: 'read',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/applications/edit/:id/driving-license-details',
    element: <EditDrivingLicenseApplication />,
    meta: {
      action: 'update',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/applications/edit/:id/attachments',
    element: <EditDrivingLicenseApplicationAttachment />,
    meta: {
      action: 'update',
      resource: 'NewDrivingLicense'
    }
  },

  //endorsement application
  {
    path: '/driving-license/endorsement-applications',
    element: <DrivingLicenseEndorsementApplicationList />,
    meta: {
      action: 'read',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-applications/add',
    element: <AddDrivingLicenseEndorsementApplication />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-applications/add/:id/dl-endorsement-details',
    element: <AddDrivingLicenseEndorsementDetails />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-applications/add/:id/pd-endorsement-details',
    element: <AddProfessionalDrivingLicenseEndorsementDetails />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-applications/add/:id/attachments',
    element: <AddDrivingLicenseEndorsementAttachments />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-applications/:id/payment',
    element: <AddDrivingLicenseEndorsementPayment />,
    meta: {
      action: 'create',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-applications/:id/view',
    element: <DrivingLicenseEndorsementApplicationView />,
    meta: {
      action: 'read',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-applications/edit/:id',
    element: <EditDrivingLicenseEndorsementApplication />,
    meta: {
      action: 'update',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-applications/edit/:id/attachments',
    element: <EditDrivingLicenseEndorsementApplicationAttachment />,
    meta: {
      action: 'update',
      resource: 'NewDrivingLicense'
    }
  },

  //endorsement cancellations

  {
    path: '/driving-license/endorsement-cancellations',
    element: <EndorsementCancellationList />,
    meta: {
      action: 'read',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-cancellations/add',
    element: <EndorsementCancellation />,
    meta: {
      action: 'cancel',
      resource: 'NewDrivingLicense'
    }
  },
  {
    path: '/driving-license/endorsement-cancellations/add/:endorsementApplicationId',
    element: <EndorsementCancellationDetails />,
    meta: {
      action: 'cancel',
      resource: 'NewDrivingLicense'
    }
  },

  {
    path: '/driving-license/endorsement-cancellations/:id/view',
    element: <EndorsementCancellationView />,
    meta: {
      action: 'cancel',
      resource: 'NewDrivingLicense'
    }
  },

  {
    path: '/driving-license/endorsement-cancellations/:id/attachment',
    element: <EndorsementCancellationAttachment />,
    meta: {
      action: 'cancel',
      resource: 'NewDrivingLicense'
    }
  },

  //ordinary driving license
  {
    path: '/driving-license/ordinary-driving-license',
    element: <OrdinaryDrivingLicenseList />,
    meta: {
      action: 'read',
      resource: 'DrivingLicense'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/:id/view',
    element: <OrdinaryDrivingLicenseView />,
    meta: {
      action: 'read',
      resource: 'DrivingLicense'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/:id/renewal',
    element: <OrdinaryDrivingLicenseRenewal />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/renewal/:id/attachment',
    element: <OrdinaryDrivingLicenseRenewalAttachment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/renewal/:id/payment',
    element: <OrdinaryDrivingLicenseRenewalPayment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/:id/replacement',
    element: <OrdinaryDrivingLicenseReplacement />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/replacement/:id/attachment',
    element: <OrdinaryDrivingLicenseReplacementAttachment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/replacement/:id/payment',
    element: <OrdinaryDrivingLicenseReplacementPayment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/:id/suspension',
    element: <OrdinaryDrivingLicenseSuspension />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseSuspension'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/suspension/:id/attachment',
    element: <OrdinaryDrivingLicenseSuspensionAttachment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseSuspension'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/:id/cancellation',
    element: <OrdinaryDrivingLicenseCancellation />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/ordinary-driving-license/cancellation/:id/attachment',
    element: <OrdinaryDrivingLicenseCancellationAttachment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },

  //professional driving license
  {
    path: '/driving-license/professional-driving-license',
    element: <ProfessionalDrivingLicenseList />,
    meta: {
      action: 'read',
      resource: 'DrivingLicense'
    }
  },
  {
    path: '/driving-license/professional-driving-license/:id/view',
    element: <ProfessionalDrivingLicenseView />,
    meta: {
      action: 'read',
      resource: 'DrivingLicense'
    }
  },
  {
    path: '/driving-license/professional-driving-license/:id/renewal',
    element: <ProfessionalDrivingLicenseRenewal />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/professional-driving-license/renewal/:id/attachment',
    element: <ProfessionalDrivingLicenseRenewalAttachment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/professional-driving-license/renewal/:id/payment',
    element: <ProfessionalDrivingLicenseRenewalPayment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/professional-driving-license/:id/replacement',
    element: <ProfessionalDrivingLicenseReplacement />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/professional-driving-license/replacement/:id/attachment',
    element: <ProfessionalDrivingLicenseReplacementAttachment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/professional-driving-license/replacement/:id/payment',
    element: <ProfessionalDrivingLicenseReplacementPayment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/professional-driving-license/:id/suspension',
    element: <ProfessionalDrivingLicenseSuspension />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseSuspension'
    }
  },
  {
    path: '/driving-license/professional-driving-license/suspension/:id/attachment',
    element: <ProfessionalDrivingLicenseSuspensionAttachment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseSuspension'
    }
  },
  {
    path: '/driving-license/professional-driving-license/:id/cancellation',
    element: <ProfessionalDrivingLicenseCancellation />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/professional-driving-license/cancellation/:id/attachment',
    element: <ProfessionalDrivingLicenseCancellationAttachment />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },

  //renewal
  {
    path: '/driving-license/pending-application/renewal',
    element: <DrivingLicenseRenewalPending />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/pending-application/renewal/:id/view',
    element: <DrivingLicenseRenewalPendingView />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },

  //replacement
  {
    path: '/driving-license/pending-application/replacement',
    element: <DrivingLicenseReplacementPending />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/pending-application/replacement/:id/view',
    element: <DrivingLicenseReplacementPendingView />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },

  //print list
  {
    path: '/driving-license/print-list',
    element: <DrivingLicensePrintList />,
    meta: {
      action: 'read',
      resource: 'DrivingLicensePrinting'
    }
  },
  {
    path: '/driving-license/print-list/:id/view',
    element: <DrivingLicensePrintView />,
    meta: {
      action: 'read',
      resource: 'DrivingLicensePrinting'
    }
  },
  {
    path: '/driving-license/print-list/:id/edit',
    element: <EditDrivingLicensePrintList />,
    meta: {
      action: 'update',
      resource: 'DrivingLicensePrinting'
    }
  },

  //cancellation
  {
    path: '/driving-license/cancellations',
    element: <DrivingLicenseCancellationList />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/cancellations/search',
    element: <DrivingLicenseCancellationSearch />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/cancellations/:id/view',
    element: <DrivingLicenseCancellationView />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/cancellations/:id/withdraw',
    element: <DrivingLicenseCancellationWithdrawal />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },

  //suspension
  {
    path: '/driving-license/suspension',
    element: <DrivingLicenseSuspensionList />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license-suspension/view/:id',
    element: <DrivingLicenseSuspensionView />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseSuspension'
    }
  },
  {
    path: '/driving-license-suspension/:id/withdraw',
    element: <DrivingLicenseSuspensionWithdraw />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseSuspension'
    }
  },
  {
    path: '/driving-license/suspension/search',
    element: <DrivingLicenseSuspensionSearch />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  //search
  {
    path: '/driving-license/search/renewal',
    element: <DrivingLicenseRenewalSearch />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/search/replacement',
    element: <DrivingLicenseReplacementSearch />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicenseRenewal'
    }
  },
  {
    path: '/driving-license/license-history',
    element: <DrivingLicenseSearchWithCid />,
    meta: {
      action: 'read',
      resource: 'DrivingLicense'
    }
  },

  //license punch
  {
    path: '/driving-license-punch',
    element: <DrivingLicensePunchList />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicensePunch'
    }
  },
  {
    path: '/driving-license-punch/add',
    element: <DrivingLicensePunchAdd />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicensePunch'
    }
  },
  {
    path: '/driving-license-punch/details',
    element: <DrivingLicensePunchDetails />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicensePunch'
    }
  },
  {
    path: '/driving-license-punch/:id',
    element: <DrivingLicensePunchView />,
    meta: {
      action: 'read',
      resource: 'DrivingLicensePunch'
    }
  },
  {
    path: '/driving-license-punch/:id/withdraw',
    element: <DrivingLicensePunchWithdraw />,
    meta: {
      action: 'manage',
      resource: 'DrivingLicensePunch'
    }
  }
]

export default DrivingLicenseRoutes

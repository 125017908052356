import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import TrafficCongestionConditionMasterService from '../../../../../services/vehicle-crash/masters/traffic-congestion-condition'

export const getTrafficCongestionConditions = createAsyncThunk('trafficCongestionCondition/getTrafficCongestionConditions', async (params, { rejectWithValue }) => {
  try {
    const response = await TrafficCongestionConditionMasterService.getTrafficCongestionConditions(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addTrafficCongestionCondition = createAsyncThunk('trafficCongestionCondition/addTrafficCongestionCondition', async (trafficCongestionCondition, { rejectWithValue, dispatch, getState }) => {
  try {
    await TrafficCongestionConditionMasterService.addTrafficCongestionCondition(trafficCongestionCondition)
    dispatch(getTrafficCongestionConditions(getState()?.trafficCongestionConditions.params))
    return trafficCongestionCondition
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateTrafficCongestionCondition = createAsyncThunk('trafficCongestionCondition/updateTrafficCongestionCondition', async (trafficCongestionCondition, { rejectWithValue, dispatch, getState }) => {
  try {
    await TrafficCongestionConditionMasterService.updateTrafficCongestionCondition(trafficCongestionCondition)
    dispatch(getTrafficCongestionConditions(getState()?.trafficCongestionConditions.params))
    return trafficCongestionCondition
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteTrafficCongestionCondition = createAsyncThunk('trafficCongestionCondition/deleteTrafficCongestionCondition', async (id, { dispatch, getState }) => {
  await TrafficCongestionConditionMasterService.deleteTrafficCongestionCondition(id)
  await dispatch(getTrafficCongestionConditions(getState()?.trafficCongestionConditions.params))
  return id
})

const trafficCongestionConditionSlice = createSlice({
  name: 'trafficCongestionConditions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getTrafficCongestionConditions.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectTrafficCongestionConditionById = (state, id) => state.trafficCongestionConditions.data.find((trafficCongestionCondition) => trafficCongestionCondition.id === id)

export default trafficCongestionConditionSlice.reducer

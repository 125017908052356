import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DivisionMasterService from '../../../../../services/vehicle-crash/masters/division'

export const getDivisions = createAsyncThunk('division/getDivisions', async (params, { rejectWithValue }) => {
  try {
    const response = await DivisionMasterService.getDivisions(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDivision = createAsyncThunk('division/addDivision', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await DivisionMasterService.addDivision(color)
    dispatch(getDivisions(getState()?.divisions.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDivision = createAsyncThunk('division/updateDivision', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await DivisionMasterService.updateDivision(color)
    dispatch(getDivisions(getState()?.divisions.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDivision = createAsyncThunk('division/deleteDivision', async (id, { dispatch, getState }) => {
  await DivisionMasterService.deleteDivision(id)
  await dispatch(getDivisions(getState()?.divisions.params))
  return id
})

const divisionSlice = createSlice({
  name: 'divisions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getDivisions.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectDivisionById = (state, id) => state.divisions.data.find((divisions) => divisions.id === id)

export default divisionSlice.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_USER_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/admin-user/api`

const getUsers = async (params) => {
  const response = await api.get(`${servicePrefix}/users`, { params: stripEmptyObjects(params) })
  return response.data
}

const getUsersWithRoles = async (params) => {
  const response = await api.get(`${servicePrefix}/users/users-with-roles`, { params: stripEmptyObjects(params) })
  return response.data
}

const getUser = async (id) => {
  const response = await api.get(`${servicePrefix}/users/${id}`)
  return response.data
}

const addUser = async (user) => {
  const response = await api.post(`${servicePrefix}/users`, user)
  return response.data
}

const getAllAgencies = async () => {
  const response = await api.get(`${servicePrefix}/agencies/all`)
  return response.data
}

const getAllRegions = async () => {
  const response = await api.get(`${servicePrefix}/regions/all`)
  return response.data
}

const getAllOfficeLocations = async () => {
  const response = await api.get(`${servicePrefix}/office-locations/all`)
  return response.data
}

const updateUserRole = async (role) => {
  const { id } = role
  const response = await api.patch(`${servicePrefix}/users/add-edit-role/${id}`, stripEmptyObjects(role))
  return response.data
}

const updateUserIndividual = async (users) => {
  const { id } = users
  const response = await api.patch(`${servicePrefix}/users/${id}`, stripEmptyObjects(users))
  return response.data
}

const passwordReset = async (password) => {
  // const { id } = password
  const response = await api.patch(`${servicePrefix}/users/change-password`, password)
  return response.data
}

const forgotPassword = async (username) => {
  const response = await api.post(`${servicePrefix}/auth/forgot-password/${username}`)
  return response.data
}

const verifyOtp = async (otp, accessToken) => {
  const response = await api.get(`${servicePrefix}/auth/verify/${otp}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return response.data
}

const resetPassword = async (data, accessToken) => {
  const response = await api.post(`${servicePrefix}/auth/reset-password`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
  return response.data
}

const UserService = {
  getUsers,
  getUser,
  addUser,
  getUsersWithRoles,
  getAllAgencies,
  getAllRegions,
  getAllOfficeLocations,
  updateUserRole,
  updateUserIndividual,
  passwordReset,
  forgotPassword,
  verifyOtp,
  resetPassword
}

export default UserService

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getDrivingLicenseApplicationByApplicationNo = createAsyncThunk('driving-license-application/getDrivingLicenseApplication', async (applicationNo, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseApplicationByApplicationNo(applicationNo)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAllProfessionalDrivingLicense = createAsyncThunk('professional-driving-license/getAllProfessionalDrivingLicense', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllProfessionalDrivingLicense(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getProfessionalDrivingLicense = createAsyncThunk('professional-driving-license/getProfessionalDrivingLicense', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getProfessionalDrivingLicense(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateProfessionalDrivingLicense = createAsyncThunk('professional-driving-license/updateProfessionalDrivingLicense', async (license, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateProfessionalDrivingLicense(license)
    dispatch(getAllProfessionalDrivingLicense(getState()?.professionalDrivingLicense.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteProfessionalDrivingLicense = createAsyncThunk('professional-driving-license/deleteProfessionalDrivingLicense', async (id, { dispatch, getState }) => {
  await DrivingLicenseService.deleteProfessionalDrivingLicense(id)
  await dispatch(getAllProfessionalDrivingLicense(getState()?.professionalDrivingLicense.params))
  return id
})

//renewal
export const addDrivingLicenseRenewal = createAsyncThunk('professional-driving-license/addDrivingLicenseRenewal', async (renewal, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseRenewal(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseRenewalAttachment = createAsyncThunk('professional-driving-license/updateDrivingLicenseRenewalAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseRenewalAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseRenewalPayment = createAsyncThunk('professional-driving-license/updateDrivingLicenseRenewalPayment', async (renewal) => {
  const response = await DrivingLicenseService.updateDrivingLicenseRenewalPayment(renewal)
  return response
})

//replacements
export const addDrivingLicenseReplacement = createAsyncThunk('professional-driving-license/addDrivingLicenseReplacement', async (renewal, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseReplacement(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseReplacementAttachment = createAsyncThunk('professional-driving-license/updateDrivingLicenseReplacementAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseReplacementAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseReplacementPayment = createAsyncThunk('professional-driving-license/updateDrivingLicenseReplacementPayment', async (renewal) => {
  const response = await DrivingLicenseService.updateDrivingLicenseReplacementPayment(renewal)
  return response
})

//update payment for all payment
export const updatePayment = createAsyncThunk('professional-driving-license/updatePayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updatePayment(payment)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

//suspension
// export const addDrivingLicenseSuspension = createAsyncThunk('professional-driving-license/addDrivingLicenseSuspension', async (suspension, { rejectWithValue }) => {
//   try {
//     const response = await DrivingLicenseService.addDrivingLicenseSuspension(suspension)
//     return response
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

//cancellation
// export const addDrivingLicenseCancellation = createAsyncThunk('professional-driving-license/addDrivingLicenseCancellation', async (cancellation, { rejectWithValue }) => {
//   try {
//     const response = await DrivingLicenseService.addDrivingLicenseCancellation(cancellation)
//     return response
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

const DrivingLicenseApplication = createSlice({
  name: 'professional_driving_license',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedProfessionalDrivingLicense: null,
    loading: false
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllProfessionalDrivingLicense.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedProfessionalDrivingLicense = null
      })
      .addCase(getProfessionalDrivingLicense.fulfilled, (state, action) => {
        state.selectedProfessionalDrivingLicense = action.payload
        state.loading = false
      })
      .addCase(getProfessionalDrivingLicense.pending, (state, action) => {
        state.selectedProfessionalDrivingLicense = action.payload
        state.loading = true
      })
      .addCase(getProfessionalDrivingLicense.rejected, (state, action) => {
        state.selectedProfessionalDrivingLicense = action.payload
        state.loading = false
      })
      .addCase(updateProfessionalDrivingLicense.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const professionalLicense = state.data.filter((professionalLicense) => professionalLicense.id !== id)
        state.data = [...professionalLicense, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default DrivingLicenseApplication.reducer

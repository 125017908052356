import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_REPORT_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/report/api`


export const generateReport = async (params) => {
  const response = await api.get(`${servicePrefix}/reports`, { params: stripEmptyObjects(params) })
  return response.data
}

export const exportReport = async (payload) => {
  try {
    const response = await api.post(`${servicePrefix}/reports/export`, stripEmptyObjects(payload), {
      responseType: 'blob'
    })

    const contentDisposition = response.headers['content-disposition']
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
    const matches = filenameRegex.exec(contentDisposition)
    const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'report.csv' // Default filename if not found
    
    // Create a Blob object from the CSV data
    const blob = new Blob([response.data], { type: 'text/csv' })

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob)

    // Create an anchor element
    const link = document.createElement('a')

    // Set the anchor element's attributes
    link.href = blobUrl
    link.download = filename // Use the extracted filename

    // Simulate a click event on the anchor element to trigger the download
    link.click()

    // Clean up: revoke the Blob URL to free up memory
    URL.revokeObjectURL(blobUrl)
    return true // Or any indication of success
  } catch (error) {
    console.error('Error exporting report:', error)
    return false
  }
  // const response = await api.post(`${servicePrefix}/reports/export`, stripEmptyObjects(payload))
  // return response.data
}

//driving license application
export const getReportConfigurations = async (params) => {
  const response = await api.get(`${servicePrefix}/report-queries`, { params: stripEmptyObjects(params) })
  return response.data
}

export const addReportConfiguration = async (data) => {
  const response = await api.post(`${servicePrefix}/report-queries`, data)
  return response.data
}

export const getAllReportConfiguration = async () => {
  const response = await api.get(`${servicePrefix}/report-queries/all`)
  return response.data
}

export const getReportConfiguration = async (id) => {
  const response = await api.get(`${servicePrefix}/report-queries/${id}`)
  return response.data
}

export const getDrivingTestConfigurationWithBookings = async (id) => {
  const response = await api.get(`${servicePrefix}/report-queries/${id}`)
  return response.data
}

export const updateReportConfiguration = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/report-queries/${id}`, data)
  return response.data
}


const ReportService = {
  generateReport,
  exportReport,
  getReportConfigurations,
  getAllReportConfiguration,
  addReportConfiguration,
  getReportConfiguration,
  updateReportConfiguration
}

export default ReportService

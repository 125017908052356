import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/masters/driving-license-master'

export const getAllDrivingLicenseNumberSequence = createAsyncThunk('number-sequence/getAllDrivingLicenseNumberSequence', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicenseNumberSequence(params)
    console.log('response', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseNumberSequence = createAsyncThunk('number-sequence/getDrivingLicenseNumberSequence', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseNumberSequence(id)
    console.log('response', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response)
  }
})

export const addDrivingLicenseNumberSequence = createAsyncThunk('number-sequence/addDrivingLicenseNumberSequence', async (numberSequence, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseNumberSequence(numberSequence)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseNumberSequence = createAsyncThunk('number-sequence/updateDrivingLicenseNumberSequence', async (numberSequence, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseNumberSequence(numberSequence)
    // dispatch(getAllDrivingLicenseNumberSequence(getState()?.number-sequences.params))
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteDrivingLicenseNumberSequence = createAsyncThunk('number-sequence/deleteDrivingLicenseNumberSequence', async (id, { dispatch, getState }) => {
  await DrivingLicenseService.deleteDrivingLicenseNumberSequence(id)
  await dispatch(getAllDrivingLicenseNumberSequence(getState()?.drivingLicenseNumberSequences.params))
  return id
})

const numberSequenceSlice = createSlice({
  name: 'driving_license_number_sequence',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualDrivingLicenseNumberSequence: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicenseNumberSequence.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualDrivingLicenseNumberSequence = null
      })
      .addCase(getDrivingLicenseNumberSequence.fulfilled, (state, action) => {
        state.individualDrivingLicenseNumberSequence = action.payload
      })

      .addCase(updateDrivingLicenseNumberSequence.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const sequence = state.data.filter((sequence) => sequence.id !== id)
        state.data = [...sequence, action.payload]
      })
  }
})

export default numberSequenceSlice.reducer

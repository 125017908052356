import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DriverBehaviourMasterService from '@services/vehicle-crash/masters/driver-behaviour'

export const getDriverBehaviours = createAsyncThunk('driver-behaviour/getDriverBehaviours', async (params, rejectWithValue) => {
  try {
    const response = await DriverBehaviourMasterService.getDriverBehaviours(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDriverBehaviour = createAsyncThunk('driver-behaviour/addDriverBehaviour', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await DriverBehaviourMasterService.addDriverBehaviour(color)
    dispatch(getDriverBehaviours(getState()?.driverBehaviours.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDriverBehaviour = createAsyncThunk('driver-behaviour/updateDriverBehaviour', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await DriverBehaviourMasterService.updateDriverBehaviour(color)
    dispatch(getDriverBehaviours(getState()?.driverBehaviours.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDriverBehaviour = createAsyncThunk('driver-behaviour/deleteDriverBehaviour', async (id, { dispatch, getState }) => {
  await DriverBehaviourMasterService.deleteDriverBehaviour(id)
  await dispatch(getDriverBehaviours(getState()?.driverBehaviours.params))
  return id
})

const driverBehavioursSlice = createSlice({
  name: 'driverBehaviours',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getDriverBehaviours.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectDriverBehaviourById = (state, id) => state.driverBehaviours.data.find((driverBehaviour) => driverBehaviour.id === id)

export default driverBehavioursSlice.reducer

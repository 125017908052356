import { Circle } from 'react-feather'
import { AiOutlineCar } from 'react-icons/ai'

export default [
  {
    id: 'driving-license',
    title: 'Driving License',
    icon: <AiOutlineCar size={20} />,
    children: [
      {
        id: 'search',
        title: 'License History',
        icon: <Circle size={12} />,
        navLink: '/driving-license/license-history',
        action: 'read',
        resource: 'DrivingLicense'
      },
      {
        id: 'pending-application',
        title: 'Pending Applications',
        icon: <Circle size={12} />,
        resource: 'DrivingLicenseRenewal',
        children: [
          {
            id: 'renewal-pending',
            title: 'Renewal Pending',
            icon: <Circle size={12} />,
            navLink: '/driving-license/pending-application/renewal',
            resource: 'DrivingLicenseRenewal'
          },
          {
            id: 'replacement-pending',
            title: 'Replacement Pending',
            icon: <Circle size={12} />,
            navLink: '/driving-license/pending-application/replacement',
            resource: 'DrivingLicenseRenewal'
          }
        ]
      },
      {
        id: 'endorsements',
        title: 'Endorsements',
        icon: <Circle size={12} />,
        resource: 'DrivingLicenseRenewal',
        children: [
          {
            id: 'driving license endorsement',
            title: 'New',
            icon: <Circle size={12} />,
            navLink: '/driving-license/endorsement-applications',
            resource: 'DrivingLicenseRenewal'
          },
          {
            id: 'endorsement-cancellations',
            title: 'Cancellations',
            icon: <Circle size={12} />,
            navLink: '/driving-license/endorsement-cancellations',
            resource: 'DrivingLicenseRenewal'
          }
        ]
      },
      {
        id: 'driving-license-application',
        title: 'New Application',
        icon: <Circle size={12} />,
        navLink: '/driving-license/applications',
        resource: 'DrivingLicenseRenewal'
      },

      // {
      //   id: 'ordinary-driving-license',
      //   title: 'Ordinary Driving License',
      //   icon: <Circle size={12} />,
      //   navLink: '/driving-license/ordinary-driving-license',
      //   resource: 'DrivingLicenseRenewal'
      // },
      // {
      //   id: 'professional-driving-license',
      //   title: 'Professional Driving License',
      //   icon: <Circle size={12} />,
      //   navLink: '/driving-license/professional-driving-license',
      //   resource: 'DrivingLicenseRenewal'
      // },
      {
        id: 'renewal',
        title: 'Renewal',
        icon: <Circle size={12} />,
        navLink: '/driving-license/search/renewal',
        resource: 'DrivingLicenseRenewal'
      },
      {
        id: 'replacement',
        title: 'Replacement',
        icon: <Circle size={12} />,
        navLink: '/driving-license/search/replacement',
        resource: 'DrivingLicenseReplacement'
      },
      {
        id: 'suspension',
        title: 'Suspension',
        icon: <Circle size={12} />,
        navLink: '/driving-license/suspension',
        resource: 'DrivingLicenseSuspension'
      },
      {
        id: 'cancellation',
        title: 'Cancellation',
        icon: <Circle size={12} />,
        navLink: '/driving-license/cancellations',
        resource: 'DrivingLicenseCancellation'
      },
      {
        id: 'print-list',
        title: 'DL Print List',
        icon: <Circle size={12} />,
        navLink: '/driving-license/print-list',
        resource: 'DrivingLicensePrinting',
        action: 'read'
      },
      {
        id: 'license-punch-list',
        title: 'License Punch List',
        icon: <Circle size={12} />,
        navLink: '/driving-license-punch',
        resource: 'DrivingLicensePunch',
        action: 'read'
      }
    ]
  }
]

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleActionMasterService from '@services/vehicle-crash/masters/vehicle-action'

export const getVehicleActions = createAsyncThunk('vehicle-action/getVehicleActions', async (params, rejectWithValue) => {
  try {
    const response = await VehicleActionMasterService.getVehicleActions(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addVehicleAction = createAsyncThunk('vehicle-action/addVehicleAction', async (vehicleAction, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleActionMasterService.addVehicleAction(vehicleAction)
    dispatch(getVehicleActions(getState()?.vehicleActions.params))
    return vehicleAction
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleAction = createAsyncThunk('vehicle-action/updateVehicleAction', async (vehicleAction, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleActionMasterService.updateVehicleAction(vehicleAction)
    dispatch(getVehicleActions(getState()?.vehicleActions.params))
    return vehicleAction
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteVehicleAction = createAsyncThunk('vehicle-action/deleteVehicleAction', async (id, { dispatch, getState }) => {
  await VehicleActionMasterService.deleteVehicleAction(id)
  await dispatch(getVehicleActions(getState()?.vehicleActions.params))
  return id
})

const vehicleActionSlice = createSlice({
  name: 'vehicleActions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getVehicleActions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateVehicleAction.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          return
        }
        const { id } = action.payload
        const vehicleActions = state.vehicleActions.filter((vehicleActions) => vehicleActions.id !== id)
        state.vehicleActions = [...vehicleActions, action.payload]
      })
  }
})
export const selectVehicleActionById = (state, id) => state.vehicleActions.data.find((vehicleAction) => vehicleAction.id === id)

export default vehicleActionSlice.reducer

// ** React Imports
import { lazy } from 'react'

const LoginCover = lazy(() => import('../../views/authentication/LoginCover'))

const ForgotPasswordBasic = lazy(() => import('../../views/authentication/ForgotPasswordBasic'))

const ResetPasswordBasic = lazy(() => import('../../views/authentication/ResetPasswordBasic'))
const VerifyOTP = lazy(() => import('../../views/authentication/VerifyOTP'))

const AuthenticationRoutes = [
  {
    path: '/login',
    element: <LoginCover />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },

  {
    path: '/forgot-password',
    element: <ForgotPasswordBasic />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/verify',
    element: <VerifyOTP />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  },
  {
    path: '/reset-password',
    element: <ResetPasswordBasic />,
    meta: {
      layout: 'blank',
      publicRoute: true
    }
  }
]

export default AuthenticationRoutes

import { Info, Circle } from 'react-feather'

export default [
  {
    id: 'personal-information',
    title: 'PIS',
    icon: <Info size={20} />,
    children: [
      {
        id: 'list-individual',
        title: 'Individual',
        icon: <Circle size={12} />,
        navLink: '/personal-information/individual',
        parentOf: ['/personal-information/individual/add', '/personal-information/individual/list/view'],
        resource: 'Pis'
      },
      {
        id: 'list-organization',
        title: 'Organization',
        icon: <Circle size={12} />,
        navLink: '/personal-information/organization/list',
        parentOf: ['/personal-information/organization/list/view'],
        resource: 'Organization'
      }
    ]
  }
]

import { lazy } from 'react'

const PisIndividualList = lazy(() => import('../../views/personal-information/individual/list'))
const PisIndividualView = lazy(() => import('../../views/personal-information/individual/view'))
const AddPisIndividual = lazy(() => import('../../views/personal-information/individual/add'))
const AddPisIndividualPermanentAddress = lazy(() => import('../../views/personal-information/individual/add/PermanentAddress'))
const AddPisIndividualPresentAddress = lazy(() => import('../../views/personal-information/individual/add/PresentAddress'))
const Datatable = lazy(() => import('../../views/personal-information-1/list/Datatable'))
const PersonalInfoDetails = lazy(() => import('../../views/personal-information-1/personal-details/index'))
const PersonalInfoSearch = lazy(() => import('../../views/personal-information-1/personal-details/index'))
const AddNewPersonalInfo = lazy(() => import('../../views/personal-information-1/add/index'))
const PermenantAddress = lazy(() => import('../../views/personal-information-1/add/permanentAddress'))
const PresentAddress = lazy(() => import('../../views/personal-information-1/add/presentAddress'))
const PersonalInfoViewId = lazy(() => import('../../views/personal-information-1/view/index'))
const Permenant = lazy(() => import('../../views/personal-information-1/view/PermanentAddressInfoCard'))
const AddAvatarForPisIndividual = lazy(() => import('../../views/personal-information/individual/add/PisAvatarUpload'))
const AddPisIndividualDocuments = lazy(() => import('../../views/personal-information/individual/add/PisDocumentUpload'))
const PisAvatarUpdate = lazy(() => import('../../views/personal-information/individual/view/update-attachment/index'))

const PisIndividualRoutes = [
  {
    path: '/personal-information/individual',
    element: <PisIndividualList />,
    meta: {
      action: 'read',
      resource: 'Pis'
    }
  },
  {
    path: '/personal-information/individual/list',
    element: <PisIndividualList />,
    meta: {
      action: 'read',
      resource: 'Pis'
    }
  },
  {
    element: <AddPisIndividual />,
    path: '/personal-information/individual/add',
    meta: {
      action: 'create',
      resource: 'Pis'
    }
  },
  {
    element: <AddPisIndividualPermanentAddress />,
    path: '/personal-information/individual/add/:pisId/permanent-address',
    meta: {
      action: 'create',
      resource: 'Pis'
    }
  },
  {
    element: <AddPisIndividualPresentAddress />,
    path: '/personal-information/individual/add/:pisId/present-address',
    meta: {
      action: 'create',
      resource: 'Pis'
    }
  },
  {
    element: <AddAvatarForPisIndividual />,
    path: '/personal-information/individual/add/:id/upload-avatar',
    meta: {
      action: 'create',
      resource: 'Pis'
    }
  },
  {
    element: <PisAvatarUpdate />,
    path: '/personal-information/individual/view/:id/update-avatar',
    meta: {
      action: 'create',
      resource: 'Pis'
    }
  },
  {
    element: <AddPisIndividualDocuments />,
    path: '/personal-information/individual/add/:id/upload-document',
    meta: {
      action: 'create',
      resource: 'Pis'
    }
  },
  {
    element: <PisIndividualView />,
    path: '/personal-information/individual/view/:id',
    meta: {
      action: 'create',
      resource: 'Pis'
    }
  },
  {
    element: <AddNewPersonalInfo />,
    path: '/personal-info/add'
  },
  {
    element: <PermenantAddress />,
    path: '/personal-info/permenantAddress'
  },
  {
    element: <PresentAddress />,
    path: '/personal-info/presentAddress',
    path: '/personal-info/list/view/:id',
    element: <PersonalInfoViewId />
  },
  {
    element: <Permenant />,
    path: '/personal-info/view/permenant',
    element: <Permenant />
  }
]
export default PisIndividualRoutes

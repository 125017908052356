// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehiclePermit = createAsyncThunk('vehicle-permit/getAllVehiclePermit', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getAllVehiclePermit(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getVehiclePermit = createAsyncThunk('vehicle-permit/getVehiclePermit', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getVehiclePermit(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addVehiclePermit = createAsyncThunk('vehicle-permit/addVehiclePermit', async (permit, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehiclePermit(permit)
    // dispatch(getAllVehiclePermit(getState()?.vehiclePermits.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehiclePermit = createAsyncThunk('vehicle-permit/updateVehiclePermit', async (permit, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehiclePermit(permit)
    // dispatch(getAllVehiclePermit(getState()?.vehiclePermits.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehiclePermitPayment = createAsyncThunk('vehicle-permit/updateVehiclePermitPayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehiclePermitPayment(payment)
    return response
  } catch (err) {
    console.log(err.response)
    // console.log('errorsss', err)
    return rejectWithValue(err.response.data)
  }
})

export const addVehiclePermitRenewal = createAsyncThunk('vehicle-permit/addVehiclePermitRenewal', async (renewal, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehiclePermitRenewal(renewal)
    return response
  } catch (err) {
    console.log('renewal error', err)
    return rejectWithValue(err.response.data)
  }
})

export const updateVehiclePermitRenewalPayment = createAsyncThunk('vehicle-permit/updateVehiclePermitRenewalPayment', async (payment) => {
  const response = await VehicleService.updateVehiclePermitRenewalPayment(payment)
  // console.log('conversion response', response)
  return response
})

export const deleteVehiclePermit = createAsyncThunk('vehicle-permit/deleteVehiclePermit', async (id, { dispatch, getState }) => {
  await VehicleService.deleteVehiclePermit(id)
  await dispatch(getAllVehiclePermit(getState()?.vehiclePermits.params))
  return id
})

const VehiclePermit = createSlice({
  name: 'vehicle_permit',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVehiclePermit: null,
    selectedVehicleData: null
  },
  reducers: {
    selectVehicle(state, action) {
      state.selectedVehicleData = action.payload
    },
    clearSelectedVehicle(state) {
      state.selectedVehicleData = null
    }
  },

  extraReducers(builder) {
    builder
      .addCase(getAllVehiclePermit.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedVehiclePermit = null
      })
      .addCase(getVehiclePermit.fulfilled, (state, action) => {
        state.selectedVehiclePermit = action.payload
      })
      .addCase(updateVehiclePermit.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const permit = state.data.filter((permit) => permit.id !== id)
        state.data = [...permit, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)
export const { selectVehicle, clearSelectedVehicle } = VehiclePermit.actions

export default VehiclePermit.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehicleCancellation = createAsyncThunk('cancellation/getAllVehicleCancellation', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getAllVehicleCancellation(params)
    // console.log('cc', response.data)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getVehicleCancellation = createAsyncThunk('cancellation/getVehicleCancellation', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getVehicleCancellation(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addVehicleCancellation = createAsyncThunk('cancellation/addVehicleCancellation', async (cancellation, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await VehicleService.addVehicleCancellation(cancellation)
    dispatch(getAllVehicleCancellation(getState()?.vehicleCancellations.params))
    // console.log(cancellation)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleCancellationAttachment = createAsyncThunk('vehicle/updateVehicleCancellationAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleCancellationAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleCancellation = createAsyncThunk('cancellation/updateVehicleCancellation', async (cancellation, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await VehicleService.updateVehicleCancellation(cancellation)
    dispatch(getAllVehicleCancellation(getState()?.vehicleCancellations.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteVehicleCancellation = createAsyncThunk('cancellation/deleteVehicleCancellation', async (id, { dispatch, getState }) => {
  await VehicleService.deleteVehicleCancellation(id)
  await dispatch(getAllVehicleCancellation(getState()?.vehicleCancellations.params))
  return id
})

export const cancellationWithdrawal = createAsyncThunk('cancellation/cancellationWithdrawal', async (data, { rejectWithValue, dispatch, getState }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.withdrawVehicleCancellation(id, data)
    await dispatch(getAllVehicleCancellation(getState()?.vehicleCancellations.params))
    return response
  } catch (err) {
    console.log('eeeee', err)
    return rejectWithValue(err.response.data)
  }
})

const cancellation = createSlice({
  name: 'cancellation',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCancellation: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllVehicleCancellation.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedCancellation = null
      })
      .addCase(getVehicleCancellation.fulfilled, (state, action) => {
        state.selectedCancellation = action.payload
      })
      .addCase(updateVehicleCancellation.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const cancellation = state.data.filter((cancellation) => cancellation.id !== id)
        state.data = [...cancellation, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default cancellation.reducer

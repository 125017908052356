import { lazy } from 'react'

const DocumentUpload = lazy(() => import('../../views/vehicle-crash-data/add/steps/DocumentUpload'))
const DocumentUploadReUpload = lazy(() => import('../../views/vehicle-crash-data/edit/DocumentUpload'))

const VehicleCrashList = lazy(() => import('../../views/vehicle-crash-data/list'))
const VehicleCrashReport = lazy(() => import('../../views/vehicle-crash-data/report'))
const VehicleCrashWizard = lazy(() => import('../../views/vehicle-crash-data/add'))

const VehicleAction = lazy(() => import('../../views/vehicle-crash-data/masters/vehicle-action/list/index'))
const VehicleActionEdit = lazy(() => import('../../views/vehicle-crash-data/masters/vehicle-action/edit/index'))
const VehicleActionAdd = lazy(() => import('../../views/vehicle-crash-data/masters/vehicle-action/add/index'))

const SafetySystem = lazy(() => import('../../views/vehicle-crash-data/masters/safety-system/list/index'))
const SafetySystemEdit = lazy(() => import('../../views/vehicle-crash-data/masters/safety-system/edit/index'))
const SafetySystemAdd = lazy(() => import('../../views/vehicle-crash-data/masters/safety-system/add/index'))

const DriverBehaviour = lazy(() => import('../../views/vehicle-crash-data/masters/driver-behaviour/list/index'))
const DriverBehaviourEdit = lazy(() => import('../../views/vehicle-crash-data/masters/driver-behaviour/edit/index'))
const DriverBehaviourAdd = lazy(() => import('../../views/vehicle-crash-data/masters/driver-behaviour/add/index'))

const TrafficControlDevice = lazy(() => import('../../views/vehicle-crash-data/masters/traffic-control-device/list/index'))
const TrafficControlDeviceEdit = lazy(() => import('../../views/vehicle-crash-data/masters/traffic-control-device/edit/index'))
const TrafficControlDeviceAdd = lazy(() => import('../../views/vehicle-crash-data/masters/traffic-control-device/add/index'))

const RoadSurfaceCondition = lazy(() => import('../../views/vehicle-crash-data/masters/road-surface-condition/list/index'))
const RoadSurfaceConditionEdit = lazy(() => import('../../views/vehicle-crash-data/masters/road-surface-condition/edit/index'))
const RoadSurfaceConditionAdd = lazy(() => import('../../views/vehicle-crash-data/masters/road-surface-condition/add/index'))

const Division = lazy(() => import('../../views/vehicle-crash-data/masters/division/list/index'))
const DivisionEdit = lazy(() => import('../../views/vehicle-crash-data/masters/division/edit/index'))
const DivisionAdd = lazy(() => import('../../views/vehicle-crash-data/masters/division/add/index'))

const DamageArea = lazy(() => import('../../views/vehicle-crash-data/masters/damage-area/list/index'))
const DamageAreaEdit = lazy(() => import('../../views/vehicle-crash-data/masters/damage-area/edit/index'))
const DamageAreaAdd = lazy(() => import('../../views/vehicle-crash-data/masters/damage-area/add/index'))

const Station = lazy(() => import('../../views/vehicle-crash-data/masters/station/list/index'))
const StationEdit = lazy(() => import('../../views/vehicle-crash-data/masters/station/edit/index'))
const StationAdd = lazy(() => import('../../views/vehicle-crash-data/masters/station/add/index'))

const LocationType = lazy(() => import('../../views/vehicle-crash-data/masters/location-type/list/index'))
const LocationTypeEdit = lazy(() => import('../../views/vehicle-crash-data/masters/location-type/edit/index'))
const LocationTypeAdd = lazy(() => import('../../views/vehicle-crash-data/masters/location-type/add/index'))

const CollisionType = lazy(() => import('../../views/vehicle-crash-data/masters/collision-type/list/index'))
const CollisionTypeEdit = lazy(() => import('../../views/vehicle-crash-data/masters/collision-type/edit/index'))
const CollisionTypeAdd = lazy(() => import('../../views/vehicle-crash-data/masters/collision-type/add/index'))

const NonCollisionType = lazy(() => import('../../views/vehicle-crash-data/masters/non-collision-type/list/index'))
const NonCollisionTypeEdit = lazy(() => import('../../views/vehicle-crash-data/masters/non-collision-type/edit/index'))
const NonCollisionTypeAdd = lazy(() => import('../../views/vehicle-crash-data/masters/non-collision-type/add/index'))

const EjectionCode = lazy(() => import('../../views/vehicle-crash-data/masters/ejection-code/list/index'))
const EjectionCodeEdit = lazy(() => import('../../views/vehicle-crash-data/masters/ejection-code/edit/index'))
const EjectionCodeAdd = lazy(() => import('../../views/vehicle-crash-data/masters/ejection-code/add/index'))

const InsuranceType = lazy(() => import('../../views/vehicle-crash-data/masters/insurance-type/list/index'))
const InsuranceTypeEdit = lazy(() => import('../../views/vehicle-crash-data/masters/insurance-type/edit/index'))
const InsuranceTypeAdd = lazy(() => import('../../views/vehicle-crash-data/masters/insurance-type/add/index'))

const InjuryStatus = lazy(() => import('../../views/vehicle-crash-data/masters/injury-status/list/index'))
const InjuryStatusEdit = lazy(() => import('../../views/vehicle-crash-data/masters/injury-status/edit/index'))
const InjuryStatusAdd = lazy(() => import('../../views/vehicle-crash-data/masters/injury-status/add/index'))

const TrafficCongestionCondition = lazy(() => import('../../views/vehicle-crash-data/masters/traffic-congestion-condition/list/index'))
const TrafficCongestionConditionEdit = lazy(() => import('../../views/vehicle-crash-data/masters/traffic-congestion-condition/edit/index'))
const TrafficCongestionConditionAdd = lazy(() => import('../../views/vehicle-crash-data/masters/traffic-congestion-condition/add/index'))

const NonMotoristAction = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-action/list/index'))
const NonMotoristActionEdit = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-action/edit/index'))
const NonMotoristActionAdd = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-action/add/index'))

const NonMotoristLocation = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-location/list/index'))
const NonMotoristLocationEdit = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-location/edit/index'))
const NonMotoristLocationAdd = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-location/add/index'))

const NonMotoristType = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-type/list/index'))
const NonMotoristTypeEdit = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-type/edit/index'))
const NonMotoristTypeAdd = lazy(() => import('../../views/vehicle-crash-data/masters/non-motorist-type/add/index'))

const DrugType = lazy(() => import('../../views/vehicle-crash-data/masters/drug-type/list/index'))
const DrugTypeEdit = lazy(() => import('../../views/vehicle-crash-data/masters/drug-type/edit/index'))
const DrugTypeAdd = lazy(() => import('../../views/vehicle-crash-data/masters/drug-type/add/index'))

const VehicleCrashInformation = lazy(() => import('../../views/vehicle-crash-data/add/steps/VehicleInfo'))
const DriverCrashInformation = lazy(() => import('../../views/vehicle-crash-data/add/steps/DriverInfo'))
const PassengerInformation = lazy(() => import('../../views/vehicle-crash-data/add/steps/PassengerInfo'))
const NonMotoristInformation = lazy(() => import('../../views/vehicle-crash-data/add/steps/NonMotoristInfo'))
const SummaryView = lazy(() => import('../../views/vehicle-crash-data/add/steps/SummaryInfo'))
const CrashLocationInformation = lazy(() => import('../../views/vehicle-crash-data/add/steps/CrashLocation'))
const VehicleCrashView = lazy(() => import('../../views/vehicle-crash-data/view/index'))

const VehicleCrashDashboard = lazy(() => import('../../views/vehicle-crash-data/dashboard/index'))

const CrashInformationUpdate = lazy(() => import('../../views/vehicle-crash-data/edit/crash-information'))
const VehicleInformationUpdate = lazy(() => import('../../views/vehicle-crash-data/edit/vehicle/index'))
const PassengerInformationUpdate = lazy(() => import('../../views/vehicle-crash-data/edit/passenger/index'))
const NewPassengerInformationUpdate = lazy(() => import('../../views/vehicle-crash-data/edit/passenger/addNewPassenger'))
const NewDriverInformationUpdate = lazy(() => import('../../views/vehicle-crash-data/edit/driver/addNewDriver'))
const NewVehicleInformation = lazy(() => import('../../views/vehicle-crash-data/edit/vehicle/addNewVehicle'))
const NonMotoristInformationUpdate = lazy(() => import('../../views/vehicle-crash-data/edit/non-motorist/index'))
const DriverInformationUpdate = lazy(() => import('../../views/vehicle-crash-data/edit/driver/index'))
const NewNonMotoristInformationUpdate = lazy(() => import('../../views/vehicle-crash-data/edit/non-motorist/AddNewNonMotoristInfo'))

const PrintIndividualCrash = lazy(() => import('../../views/vehicle-crash-data/view/generate-pdf-for-individual-crash/index'))

const VehcieCrashDataRoutes = [
  {
    path: '/vehicle-crash-data/dashboard',
    element: <VehicleCrashDashboard />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData',
      parent: '/vehicle-crash-data'
    }
  },
  {
    path: '/crash-information/add',
    element: <VehicleCrashWizard />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData',
      parent: '/vehicle-crash-data'
    }
  },
  {
    path: '/crash-information/list/view/:id/print-crash-information',
    element: <PrintIndividualCrash />,
    meta: {
      action: 'read',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information',
    element: <VehicleCrashList />,
    meta: {
      action: 'read',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/vehicle-crash-data/reports',
    element: <VehicleCrashReport />,
    meta: {
      action: 'read',
      resource: 'VehicleCrashDataReports'
    }
  },
  {
    path: '/crash-information/list/view/:id',
    element: <VehicleCrashView />,
    meta: {
      action: 'read',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/crash-location/add',
    element: <CrashLocationInformation />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/vehicle/add',
    element: <VehicleCrashInformation />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/driver/add',
    element: <DriverCrashInformation />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/passenger/add',
    element: <PassengerInformation />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/non-motorist/add',
    element: <NonMotoristInformation />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/add/:id/document-upload',
    element: <DocumentUpload />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/edit/:id/document-upload',
    element: <DocumentUploadReUpload />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/summary/view',
    element: <SummaryView />,
    meta: {
      action: 'create',
      resource: 'VehicleCrashData'
    }
  },

  //Vehicle Crash Data Master
  {
    path: '/master-data/vehicle-prior-actions',
    element: <VehicleAction />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/vehicle-prior-action/add',
    element: <VehicleActionEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/vehicle-prior-action/:id',
    element: <VehicleActionAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Safety System Data Master
  {
    path: '/master-data/safety-systems',
    element: <SafetySystem />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/safety-system/add',
    element: <SafetySystemAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/safety-system/:id',
    element: <SafetySystemEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  //Driver Behaviour Data Master
  {
    path: '/master-data/driver-behaviour',
    element: <DriverBehaviour />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/driver-behaviour/add',
    element: <DriverBehaviourAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/driver-behaviour/:id',
    element: <DriverBehaviourEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Damage Area
  {
    path: '/master-data/damage-area',
    element: <DamageArea />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/damage-area/add',
    element: <DamageAreaAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/damage-area/:id',
    element: <DamageAreaEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Driver Traffic Control Device
  {
    path: '/master-data/traffic-control-devices',
    element: <TrafficControlDevice />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/traffic-control-device/add',
    element: <TrafficControlDeviceAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/traffic-control-device/:id',
    element: <TrafficControlDeviceEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Collision Type
  {
    path: '/master-data/collision-types',
    element: <CollisionType />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/collision-type/add',
    element: <CollisionTypeAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/collision-type/:id',
    element: <CollisionTypeEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  //Non Collision Type
  {
    path: '/master-data/non-collision-types',
    element: <NonCollisionType />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/non-collision-type/add',
    element: <NonCollisionTypeAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/non-collision-type/:id',
    element: <NonCollisionTypeEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Road Surface Condition
  {
    path: '/master-data/causes-of-accident',
    element: <RoadSurfaceCondition />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/causes-of-accident/add',
    element: <RoadSurfaceConditionAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/causes-of-accident/:id',
    element: <RoadSurfaceConditionEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Ejection Code
  {
    path: '/master-data/ejection-code',
    element: <EjectionCode />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/ejection-code/add',
    element: <EjectionCodeAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/ejection-code/:id',
    element: <EjectionCodeEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Insurance Type
  {
    path: '/master-data/insurance-type',
    element: <InsuranceType />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/insurance-type/add',
    element: <InsuranceTypeAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/insurance-type/:id',
    element: <InsuranceTypeEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Injury Status
  {
    path: '/master-data/injury-status',
    element: <InjuryStatus />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/injury-status/add',
    element: <InjuryStatusAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/injury-status/:id',
    element: <InjuryStatusEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Division
  {
    path: '/master-data/divisions',
    element: <Division />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/division/add',
    element: <DivisionAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/division/:id',
    element: <DivisionEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Station
  {
    path: '/master-data/stations',
    element: <Station />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/station/add',
    element: <StationAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/station/:id',
    element: <StationEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Location Types
  {
    path: '/master-data/intersection-types',
    element: <LocationType />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/location-type/add',
    element: <LocationTypeAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/location-type/:id',
    element: <LocationTypeEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //TrafficCongestionCondition
  {
    path: '/master-data/traffic-congestion-conditions',
    element: <TrafficCongestionCondition />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/traffic-congestion-condition/add',
    element: <TrafficCongestionConditionAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/traffic-congestion-condition/:id',
    element: <TrafficCongestionConditionEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Non Motorist Action
  {
    path: '/master-data/non-motorist-action',
    element: <NonMotoristAction />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/non-motorist-action/add',
    element: <NonMotoristActionAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/non-motorist-action/:id',
    element: <NonMotoristActionEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Non Motorist Location
  {
    path: '/master-data/non-motorist-location',
    element: <NonMotoristLocation />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/non-motorist-location/add',
    element: <NonMotoristLocationAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/non-motorist-location/:id',
    element: <NonMotoristLocationEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Non Motorist Type
  {
    path: '/master-data/non-motorist-type',
    element: <NonMotoristType />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/non-motorist-type/add',
    element: <NonMotoristTypeAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/non-motorist-type/:id',
    element: <NonMotoristTypeEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Drug Type
  {
    path: '/master-data/drug-type',
    element: <DrugType />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/drug-type/add',
    element: <DrugTypeAdd />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/master-data/drug-type/:id',
    element: <DrugTypeEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //Passenger
  {
    path: '/crash-information/list/view/:id/passenger-information-edit',
    element: <PassengerInformationUpdate />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/add-new-passenger/:id',
    element: <NewPassengerInformationUpdate />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //crash
  {
    path: '/crash-information/list/view/:id/crash-information-edit',
    element: <CrashInformationUpdate />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },

  //vehicle
  {
    path: '/crash-information/list/view/:id/vehicle-information-edit',
    element: <VehicleInformationUpdate />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/add-new-vehicle/:id',
    element: <NewVehicleInformation />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/list/view/:id/non-motorist-information-edit',
    element: <NonMotoristInformationUpdate />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: 'crash-information/list/view/:id/driver-information-edit',
    element: <DriverInformationUpdate />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/add-new-non-motorist/:id',
    element: <NewNonMotoristInformationUpdate />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  },
  {
    path: '/crash-information/add-new-driver/:id',
    element: <NewDriverInformationUpdate />,
    meta: {
      action: 'manage',
      resource: 'VehicleCrashData'
    }
  }
]
export default VehcieCrashDataRoutes

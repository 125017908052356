import { lazy } from 'react'

// Passenger Bus InspectionList
const ChargeCalculator = lazy(() => import('../../views/charge-calculator/index'))

const ChargeCalculatorRoutes = [
  //Application
  {
    path: '/charge-calculator',
    element: <ChargeCalculator />,
    meta: {
      action: 'read',
      resource: 'Dashboard'
    }
  }
]

export default ChargeCalculatorRoutes

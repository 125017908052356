import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import OffenceService from '@services/offence'

//Get Inspection Places
export const getInspectionPlaces = createAsyncThunk('inspectionPlaces/getInspectionPlaces', async (params) => {
  const response = await OffenceService.getInspectionPlaces(params)
  return { params, data: response.data, totalPages: response.meta.itemCount }
})

export const getIndividualInspectionPlace = createAsyncThunk('inspectionPlaces/getIndividualInspectionPlace', async (id, { rejectWithValue }) => {
  try {
    const response = await OffenceService.getInspectionPlacesById(id)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

//Post new Inspection Places
export const addInspectionPlaces = createAsyncThunk('inspectionPlaces/addInspectionPlaces', async (inspectionPlaceData, { rejectWithValue, getState, dispatch }) => {
  try {
    const response = await OffenceService.addInspectionPlaces(inspectionPlaceData)
    await dispatch(getInspectionPlaces(getState()?.vehicleFitnesses?.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//Update Inspection Places
export const updateInspectionPlaces = createAsyncThunk('inspectionPlaces/updateInspectionPlaces', async (inspectionPlaceData, { rejectWithValue, getState, dispatch }) => {
  try {
    const reponse = await OffenceService.updateInspectionPlaces(inspectionPlaceData)
    await dispatch(getInspectionPlaces(getState()?.vehicleFitnesses?.params))
    return reponse
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
//Delete Inspection Places
export const deleteInspectionPlaces = createAsyncThunk('inspectionPlaces/deleteInspectionPlaces', async (id, { getState, dispatch }) => {
  await OffenceService.deleteInspectionPlaces(id)
  await dispatch(getInspectionPlaces(getState()?.vehicleFitnesses?.params))
  return id
})
const InspectionPlacesSlice = createSlice({
  name: 'inspectionPlaces',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualInspectionPlace: null
  },
  extraReducers(builder) {
    builder
      .addCase(getInspectionPlaces.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualInspectionPlace = null
      })
      .addCase(getIndividualInspectionPlace.fulfilled, (state, action) => {
        state.individualInspectionPlace = action.payload
      })

      .addCase(updateInspectionPlaces.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          return
        }
        const { id } = action.payload
        const inspectionPlaces = state.data.filter((inspectionPlaces) => inspectionPlaces.id !== id)
        state.data = [...inspectionPlaces, action.payload]
      })
  }
})

export default InspectionPlacesSlice.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getInsuranceTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/insurance-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllInsuranceType = async () => {
  const response = await api.get(`${servicePrefix}/insurance-types/all`)
  return response.data
}

const addInsuranceType = async (insuranceTypes) => {
  const response = await api.post(`${servicePrefix}/insurance-types`, insuranceTypes)
  return response.data
}

const updateInsuranceType = async (insuranceTypes) => {
  const { id } = ejectionCode
  const response = await api.patch(`${servicePrefix}/insurance-types/${id}`, insuranceTypes)
  return response.data
}

const deleteInsuranceType = async (id) => {
  const response = await api.delete(`${servicePrefix}/insurance-types/${id}`)
  return response.data
}

const InsuranceTypeMasterService = {
  getInsuranceTypes,
  getAllInsuranceType,
  addInsuranceType,
  updateInsuranceType,
  deleteInsuranceType
}

export default InsuranceTypeMasterService

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getDriverBehaviours = async (params) => {
  const response = await api.get(`${servicePrefix}/driver-behaviour`, { params: stripEmptyObjects(params) })
  return response.data
}

//agencies
const getAllDriverBehaviour = async (params) => {
  const response = await api.get(`${servicePrefix}/driver-behaviour/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addDriverBehaviour = async (driverBehaviour) => {
  const response = await api.post(`${servicePrefix}/driver-behaviour`, driverBehaviour)
  return response.data
}

const updateDriverBehaviour = async (driverBehaviour) => {
  const { id } = driverBehaviour
  const response = await api.patch(`${servicePrefix}/driver-behaviour/${id}`, driverBehaviour)
  return response.data
}

const deleteDriverBehaviour = async (id) => {
  const response = await api.delete(`${servicePrefix}/driver-behaviour/${id}`)
  return response.data
}

const DriverBehaviourMasterService = {
  getDriverBehaviours,
  getAllDriverBehaviour,
  addDriverBehaviour,
  updateDriverBehaviour,
  deleteDriverBehaviour
}

export default DriverBehaviourMasterService

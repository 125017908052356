import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_USER_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/admin-user/api`

const getPermissions = async (params) => {
  const response = await api.get(`${servicePrefix}/permissions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllPermission = async () => {
  const response = await api.get(`${servicePrefix}/permissions/all`)
  return response.data
}

const addPermission = async (permissions) => {
  try {
    const response = await api.post(`${servicePrefix}/permissions`, permissions)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const PermissionService = {
  getPermissions,
  getAllPermission,
  addPermission
}

export default PermissionService

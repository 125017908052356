import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getInjuryStatus = async (params) => {
  const response = await api.get(`${servicePrefix}/injury-status`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllInjuryStatus = async () => {
  const response = await api.get(`${servicePrefix}/injury-status/all`)
  return response.data
}

const addInjuryStatus = async (injuryStatus) => {
  const response = await api.post(`${servicePrefix}/injury-status`, injuryStatus)
  return response.data
}

const updateInjuryStatus = async (injuryStatus) => {
  const { id } = injuryStatus
  const response = await api.patch(`${servicePrefix}/injury-status/${id}`, injuryStatus)
  return response.data
}

const deleteInjuryStatus = async (id) => {
  const response = await api.delete(`${servicePrefix}/injury-status/${id}`)
  return response.data
}

const InjuryStatusMasterService = {
  getInjuryStatus,
  getAllInjuryStatus,
  addInjuryStatus,
  updateInjuryStatus,
  deleteInjuryStatus
}

export default InjuryStatusMasterService

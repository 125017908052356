import { Circle } from 'react-feather'
import { AiOutlineCar } from 'react-icons/ai'

export default [
  {
    id: 'driving-test',
    title: 'Driving Tests',
    icon: <AiOutlineCar size={20} />,
    children: [
      {
        id: 'driving-test-configuration',
        title: 'Test Configuration',
        icon: <Circle size={12} />,
        navLink: '/driving-test/test-configurations',
        action: 'read',
        resource: 'ETestManagement'
      },
      // {
      //   id: 'driving-test-candidate',
      //   title: 'Test Candidates',
      //   icon: <Circle size={12} />,
      //   navLink: '/driving-test/test-candidates'
      // },
      {
        id: 'driving-test-questions',
        title: 'Manage Questions',
        icon: <Circle size={12} />,
        navLink: '/driving-test/test-questions',
        action: 'manage',
        resource: 'ETestQuestion'
      },

      {
        id: 'manage-test-marksheet',
        title: 'Manage Test Marks',
        icon: <Circle size={12} />,
        navLink: '/driving-test/test-marksheet/add',
        action: 'read',
        resource: 'ETestManagement'
      }
    ]
  }
]

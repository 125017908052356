import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getNonMotoristTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/non-motorist-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllNonMotoristType = async () => {
  const response = await api.get(`${servicePrefix}/non-motorist-types/all`)
  return response.data
}

const addNonMotoristType = async (nonMotoristType) => {
  const response = await api.post(`${servicePrefix}/non-motorist-types`, nonMotoristType)
  return response.data
}

const updateNonMotoristType = async (nonMotoristType) => {
  const { id } = nonMotoristType
  const response = await api.patch(`${servicePrefix}/non-motorist-types/${id}`, nonMotoristType)
  return response.data
}

const deleteNonMotoristType = async (id) => {
  const response = await api.delete(`${servicePrefix}/non-motorist-types/${id}`)
  return response.data
}

const NonMotoristTypeMasterService = {
  getNonMotoristTypes,
  getAllNonMotoristType,
  addNonMotoristType,
  updateNonMotoristType,
  deleteNonMotoristType
}

export default NonMotoristTypeMasterService

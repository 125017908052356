import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getDivisions = async (params) => {
  const response = await api.get(`${servicePrefix}/division`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllDivision = async () => {
  const response = await api.get(`${servicePrefix}/division/all`)
  return response.data
}

const addDivision = async (division) => {
  const response = await api.post(`${servicePrefix}/division`, division)
  return response.data
}

const updateDivision = async (division) => {
  const { id } = division
  const response = await api.patch(`${servicePrefix}/division/${id}`, division)
  return response.data
}

const deleteDivision = async (id) => {
  const response = await api.delete(`${servicePrefix}/division/${id}`)
  return response.data
}

const DivisionMasterService = {
  getDivisions,
  getAllDivision,
  addDivision,
  updateDivision,
  deleteDivision
}

export default DivisionMasterService

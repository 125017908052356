import { Info, Circle, CheckCircle } from 'react-feather'

export default [
  {
    id: 'vehicle-crash-data',
    title: 'Vehicle Crash Data',
    icon: <Info size={20} />,
    children: [
      {
        id: 'vehicle-crash-dashboard',
        title: 'Dashboard',
        icon: <Circle size={12} />,
        navLink: '/vehicle-crash-data/dashboard',
        resource: 'VehicleCrashData'
      },
      {
        id: 'vehicle-crash-list',
        title: 'List & Add',
        icon: <Circle size={12} />,
        navLink: '/crash-information',
        parentOf: ['/crash-information/add', '/crash-information/crash-location/add', '/crash-information/vehicle/add', '/crash-information/driver/add', '/crash-information/passenger/add', '/crash-information/non-motorist/add', '/crash-information/add/:id/document-upload', '/vehicle-crash-data/list/view/'],
        resource: 'VehicleCrashData'
      },
      {
        id: 'vehicle-crash-masters',
        title: 'Master Data',
        icon: <Circle size={12} />,
        resource: 'VehicleCrashData',
        children: [
          {
            id: 'division',
            title: 'Division',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/divisions',
            resource: 'VehicleCrashData'
          },
          {
            id: 'station',
            title: 'Station',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/stations',
            resource: 'VehicleCrashData'
          },
          {
            id: 'intersection-type',
            title: 'Intersection Type',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/intersection-types',
            resource: 'VehicleCrashData'
          },
          {
            id: 'traffic-congestion-condition',
            title: 'Traffic Congestion Conditions',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/traffic-congestion-conditions',
            resource: 'VehicleCrashData'
          },
          {
            id: 'causes-of-accident',
            title: 'Causes of Accident',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/causes-of-accident',
            resource: 'VehicleCrashData'
          },
          {
            id: 'safety-system',
            title: 'Safety System',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/safety-systems',
            resource: 'VehicleCrashData'
          },
          {
            id: 'driver-behaviour',
            title: 'Driver Behaviour',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/driver-behaviour',
            resource: 'VehicleCrashData'
          },
          {
            id: 'vehicle-prior-action',
            title: 'Vehicle Prior Action',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/vehicle-prior-actions',
            resource: 'VehicleCrashData'
          },
          {
            id: 'traffic-control-device',
            title: 'Traffic Control Device Type',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/traffic-control-devices',
            resource: 'VehicleCrashData'
          },
          {
            id: 'collision-types',
            title: 'Collision Type',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/collision-types',
            resource: 'VehicleCrashData'
          },
          {
            id: 'non-collision-types',
            title: 'Non Collision Type',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/non-collision-types',
            resource: 'VehicleCrashData'
          },
          {
            id: 'ejection-codes',
            title: 'Ejection Codes',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/ejection-code',
            resource: 'VehicleCrashData'
          },
          {
            id: 'insurance-types',
            title: 'Insurance Types',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/insurance-type',
            resource: 'VehicleCrashData'
          },
          {
            id: 'injury-status',
            title: 'Injury Status',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/injury-status',
            resource: 'VehicleCrashData'
          },
          {
            id: 'damage-area',
            title: 'Damage Area',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/damage-area',
            resource: 'VehicleCrashData'
          },
          {
            id: 'non-motorist-type',
            title: 'Non Motorist Type',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/non-motorist-type',
            resource: 'VehicleCrashData'
          },
          {
            id: 'non-motorist-location',
            title: 'Non Motorist Location',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/non-motorist-location',
            resource: 'VehicleCrashData'
          },
          {
            id: 'non-motorist-action',
            title: 'Non Motorist Action',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/non-motorist-action',
            resource: 'VehicleCrashData'
          },
          {
            id: 'drug-type',
            title: 'Drug Type',
            icon: <CheckCircle size={20} />,
            navLink: '/master-data/drug-type',
            resource: 'VehicleCrashData'
          }
        ]
      }
    ]
  }
]

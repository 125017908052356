// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import OffenceService from '@services/offence'

export const getTrafficInfringements = createAsyncThunk('trafficInfringements/getTrafficInfringements', async (params) => {
  const response = await OffenceService.getTrafficInfringements(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getTrafficInfringementsById = createAsyncThunk('trafficInfringements/getTrafficInfringementsById', async (id) => {
  const response = await OffenceService.getTrafficInfringementsById(id)
  return response
})

export const addTrafficInfringements = createAsyncThunk('trafficInfringements/addTrafficInfringements', async (trafficInfringements, { rejectWithValue }) => {
  try {
    const response = await OffenceService.addTrafficInfringements(trafficInfringements)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const cancelTrafficInfringements = createAsyncThunk('trafficInfringements/paymentForTrafficInfringements', async (trafficInfringements, { rejectWithValue }) => {
  try {
    const response = await OffenceService.cancelOffenceForBhutanese(trafficInfringements)
    return response
  } catch (err) {
    return rejectWithValue(err.response)
  }
})

export const updateTrafficInfringements = createAsyncThunk('trafficInfringements/updateTrafficInfringements', async (trafficInfringements, { rejectWithValue }) => {
  try {
    const response = await OffenceService.updateTrafficInfringements(trafficInfringements)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteTrafficInfringements = createAsyncThunk('trafficInfringements/deleteInspectionPlaces', async (id, { getState, dispatch }) => {
  await OffenceService.deleteInspectionPlaces(id)
  await dispatch(getInspectionPlaces(getState()?.vehicleFitnesses?.params))
  return id
})

export const paymentForTrafficInfringements = createAsyncThunk('trafficInfringements/paymentForTrafficInfringements', async (paymentInfo, { rejectWithValue }) => {
  try {
    const response = await OffenceService.paymentForTrafficInfringements(paymentInfo)
    return response
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const updateTrafficInfringementsAttachment = createAsyncThunk('trafficInfringements/updateTrafficInfringementsAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await OffenceService.updateTrafficInfringementsAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const cancelOffenceAttachment = createAsyncThunk('trafficInfringements/cancelOffenceAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await OffenceService.cancelOffenceForBhutaneseAttachments(id, data)
    return response.data
  } catch (err) {
    console.log(err.response)
    return rejectWithValue(err.response.data)
  }
})

export const trafficInfringementsSlice = createSlice({
  name: ' trafficInfringements',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualTrafficInfringements: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrafficInfringements.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTrafficInfringementsById.fulfilled, (state, action) => {
        state.individualTrafficInfringements = action.payload
      })
  }
})

export default trafficInfringementsSlice.reducer

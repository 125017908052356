import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import DamageAreaMasterService from '../../../../../services/vehicle-crash/masters/damage-area'

export const getDamageAreas = createAsyncThunk('damageArea/getDamageAreas', async (params, { rejectWithValue }) => {
  try {
    const response = await DamageAreaMasterService.getDamageAreas(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDamageArea = createAsyncThunk('damageArea/addDamageArea', async (damageArea, { rejectWithValue, dispatch, getState }) => {
  try {
    await DamageAreaMasterService.addDamageArea(damageArea)
    dispatch(getDamageAreas(getState()?.damageAreas.params))
    return damageArea
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDamageArea = createAsyncThunk('damageArea/updateDamageArea', async (damageArea, { rejectWithValue, dispatch, getState }) => {
  try {
    await DamageAreaMasterService.updateDamageArea(damageArea)
    dispatch(getDamageAreas(getState()?.damageAreas.params))
    return damageArea
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDamageArea = createAsyncThunk('damageArea/deleteDamageArea', async (id, { dispatch, getState }) => {
  await DamageAreaMasterService.deleteDamageArea(id)
  await dispatch(getDamageAreas(getState()?.damageAreas.params))
  return id
})

const DamageAreasSlice = createSlice({
  name: 'damageAreas',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getDamageAreas.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectDamageAreaById = (state, id) => state.damageAreas.data.find((damageAreas) => damageAreas.id === id)

export default DamageAreasSlice.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_DRIVING_LICENSE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/driving-license/api`

//driving license application
const getAllDrivingLicenseApplication = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/applications`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const addDrivingLicenseApplication = async (application) => {
  const response = await api.post(`${servicePrefix}/applications`, application)
  return response.data
}

const getDrivingLicenseApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/applications/${id}`)
  return response.data
}

const getDrivingLicenseApplicationByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/applications/by-application-no/${applicationNo}`)
  return response.data
}

const updateDrivingLicenseApplication = async (application) => {
  const { id } = application
  const response = await api.patch(`${servicePrefix}/applications/${id}`, application)
  return response.data
}

const updateDrivingLicenseApplicationAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/applications/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const verifyDrivingLicenseApplication = async (id) => {
  const response = await api.patch(`${servicePrefix}/applications/${id}/verify-application`)
  return response.data
}

const rejectDrivingLicenseApplication = async (id, payload) => {
  const response = await api.patch(`${servicePrefix}/applications/${id}/reject`, payload)
  return response.data
}

const updateDrivingLicenseApplicationPayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/applications/${id}/payment`, payment)
  return response.data
}

const deleteDrivingLicenseApplication = async (id) => {
  const response = await api.delete(`${servicePrefix}/applications/${id}`)
  return response.data
}

//driving license endorsement application
const getAllDrivingLicenseEndorsementApplication = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/endorsement-applications`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {}
}

const addDrivingLicenseEndorsementApplication = async (application) => {
  const response = await api.post(`${servicePrefix}/endorsement-applications`, application)
  return response.data
}

const getDrivingLicenseEndorsementApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/endorsement-applications/${id}`)
  return response.data
}

const updateDrivingLicenseEndorsementApplication = async (application) => {
  const { id } = application
  const response = await api.patch(`${servicePrefix}/endorsement-applications/${id}`, application)
  return response.data
}

const updateDrivingLicenseEndorsementApplicationAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/endorsement-applications/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}
const verifyDrivingLicenseEndorsementApplication = async (id) => {
  const response = await api.patch(`${servicePrefix}/endorsement-applications/${id}/verify-application`)
  return response.data
}

const rejectDrivingLicenseEndorsementApplication = async (id, payload) => {
  const response = await api.patch(`${servicePrefix}/endorsement-applications/${id}/reject`, payload)
  return response.data
}

const updateDrivingLicenseEndorsementApplicationPayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/endorsement-applications/${id}/payment`, payment)
  return response.data
}

const deleteDrivingLicenseEndorsementApplication = async (id) => {
  const response = await api.delete(`${servicePrefix}/endorsement-applications/${id}`)
  return response.data
}

//ordinary driving license
const getAllOrdinaryDrivingLicense = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/ordinary-driving-license`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getOrdinaryDrivingLicense = async (id) => {
  const response = await api.get(`${servicePrefix}/ordinary-driving-license/${id}`)
  return response.data
}

const getOrdinaryDrivingLicenseRenewals = async (id) => {
  const response = await api.get(`${servicePrefix}/ordinary-driving-license/renewals/${id}`)
  return response.data
}

const getOrdinaryDrivingLicenseReplacement = async (id) => {
  const response = await api.get(`${servicePrefix}/ordinary-driving-license/replacements/${id}`)
  return response.data
}

const getOrdinaryDrivingLicenseSuspension = async (id) => {
  const response = await api.get(`${servicePrefix}/ordinary-driving-license/suspensions/${id}`)
  return response.data
}

const getOrdinaryDrivingLicenseCancellation = async (id) => {
  const response = await api.get(`${servicePrefix}/ordinary-driving-license/cancellations/${id}`)
  return response.data
}

const updateOrdinaryDrivingLicense = async (application) => {
  const { id } = application
  const response = await api.patch(`${servicePrefix}/ordinary-driving-license/${id}`, application)
  return response.data
}

const deleteOrdinaryDrivingLicense = async (id) => {
  const response = await api.delete(`${servicePrefix}/ordinary-driving-license/${id}`)
  return response.data
}

const searchOrdinaryDrivingLicense = async (search) => {
  const response = await api.get(`${servicePrefix}/ordinary-driving-license/search/${search}`)
  return response.data
}

const searchOrdinaryDrivingLicenseByPisId = async (search) => {
  const response = await api.get(`${servicePrefix}/ordinary-driving-license/search-ordinary-license/${search}`)
  return response.data
}

const searchByLicenseNumber = async (number) => {
  const response = await api.get(`${servicePrefix}/licenses/search/${number}`)
  return response.data
}

export const editLicenseValidity = async (licenseData) => {
  const { licenseNo } = licenseData
  const response = await api.patch(`${servicePrefix}/licenses/edit-date/${licenseNo}`, licenseData)
  return response.data
}

const getLicenseByOwnerId = async (ownerId) => {
  const response = await api.get(`${servicePrefix}/licenses/search-by-owner-id/${ownerId}`)
  return response.data
}

const getLicenseByCid = async (cid) => {
  const response = await api.get(`${servicePrefix}/licenses/search-by-cid/${cid}`)
  return response.data
}

//professional driving license
const getAllProfessionalDrivingLicense = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/professional-driving-license`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getProfessionalDrivingLicense = async (id) => {
  const response = await api.get(`${servicePrefix}/professional-driving-license/${id}`)
  return response.data
}

const getProfessionalDrivingLicenseRenewals = async (id) => {
  const response = await api.get(`${servicePrefix}/professional-driving-license/renewals/${id}`)
  return response.data
}

const getProfessionalDrivingLicenseReplacement = async (id) => {
  const response = await api.get(`${servicePrefix}/professional-driving-license/replacements/${id}`)
  return response.data
}

const getProfessionalDrivingLicenseSuspension = async (id) => {
  const response = await api.get(`${servicePrefix}/professional-driving-license/suspensions/${id}`)
  return response.data
}

const getProfessionalDrivingLicenseCancellation = async (id) => {
  const response = await api.get(`${servicePrefix}/professional-driving-license/cancellations/${id}`)
  return response.data
}

const updateProfessionalDrivingLicense = async (application) => {
  const { id } = application
  const response = await api.patch(`${servicePrefix}/professional-driving-license/${id}`, application)
  return response.data
}

const deleteProfessionalDrivingLicense = async (id) => {
  const response = await api.delete(`${servicePrefix}/professional-driving-license/${id}`)
  return response.data
}

const searchProfessionalDrivingLicense = async (search) => {
  const response = await api.get(`${servicePrefix}/professional-driving-license/search/${search}`)
  return response.data
}

const searchProfessionalDrivingLicenseByPisId = async (search) => {
  const response = await api.get(`${servicePrefix}/professional-driving-license/search-professional-license/${search}`)
  return response.data
}

//renewal
const getAllDrivingLicenseRenewals = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/renewals`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {}
}

const addDrivingLicenseRenewal = async (renewal) => {
  const response = await api.post(`${servicePrefix}/renewals`, renewal)
  return response.data
}

const getDrivingLicenseRenewal = async (id) => {
  const response = await api.get(`${servicePrefix}/renewals/${id}`)
  return response.data
}

const updateDrivingLicenseRenewal = async (renewal) => {
  const { id } = renewal
  const response = await api.patch(`${servicePrefix}/renewals/${id}`, renewal)
  return response.data
}

const updateDrivingLicenseRenewalAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/renewals/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const updateDrivingLicenseRenewalPayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/renewals/${id}/payment`, payment)
  return response.data
}

//replacement
const getAllDrivingLicenseReplacements = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/replacements`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {}
}

const addDrivingLicenseReplacement = async (replacement) => {
  const response = await api.post(`${servicePrefix}/replacements`, replacement)
  return response.data
}

const getDrivingLicenseReplacement = async (id) => {
  const response = await api.get(`${servicePrefix}/replacements/${id}`)
  return response.data
}

const updateDrivingLicenseReplacement = async (replacement) => {
  const { id } = replacement
  const response = await api.patch(`${servicePrefix}/replacements/${id}`, replacement)
  return response.data
}

const updateDrivingLicenseReplacementAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/replacements/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const approveDrivingLicenseReplacement = async (id) => {
  const response = await api.post(`${servicePrefix}/replacements/${id}/verify-application`)
  return response.data
}

const rejectDrivingLicenseReplacement = async (id) => {
  const response = await api.post(`${servicePrefix}/replacements/${id}/reject-application`)
  return response.data
}

const updateDrivingLicenseReplacementPayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/replacements/${id}/payment`, payment)
  return response.data
}

//suspension
const getAllDrivingLicenseSuspension = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/suspensions`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const addDrivingLicenseSuspension = async (suspension) => {
  const response = await api.post(`${servicePrefix}/suspensions`, suspension)
  return response.data
}

const getDrivingLicenseSuspension = async (id) => {
  const response = await api.get(`${servicePrefix}/suspensions/${id}`)
  return response.data
}

const updateDrivingLicenseSuspension = async (suspension) => {
  const { id } = suspension
  const response = await api.patch(`${servicePrefix}/suspensions/${id}`, suspension)
  return response.data
}

const DrivingLicenseSuspensionWithdrawal = async (data) => {
  const { id } = data
  const response = await api.patch(`${servicePrefix}/suspensions/${id}/withdraw`, data)
  return response
}

const updateDrivingLicenseSuspensionAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/suspensions/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//cancellation
const getAllDrivingLicenseCancellation = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/cancellations`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const addDrivingLicenseCancellation = async (cancellation) => {
  const response = await api.post(`${servicePrefix}/cancellations`, cancellation)
  return response.data
}

const getDrivingLicenseCancellation = async (id) => {
  const response = await api.get(`${servicePrefix}/cancellations/${id}`)
  return response.data
}

const updateDrivingLicenseCancellation = async (cancellation) => {
  const { id } = cancellation
  const response = await api.patch(`${servicePrefix}/cancellations/${id}`, cancellation)
  return response.data
}

const updateDrivingLicenseCancellationAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/cancellations/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const postWithdrawDrivingLicenseCancellation = async (data) => {
  const response = await api.post(`${servicePrefix}/driving-license-cancellation-withdrawals`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

const addToPrintList = async (data) => {
  const response = await api.post(`${servicePrefix}/print-lists`, data)
  return response.data
}

const getAllDrivingLicensePrintList = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/print-lists`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getDrivingLicensePrintList = async (id) => {
  const response = await api.get(`${servicePrefix}/print-lists/${id}`)
  return response.data
}

const updateDrivingLicensePrintList = async (printList) => {
  const { id } = printList
  const response = await api.patch(`${servicePrefix}/print-lists/${id}`, printList)
  return response.data
}

//update any payment type
const updatePayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/driving-license-fees/${id}/edit-payment`, payment)
  return response
}

//driving-license-fees get by id
const getDrivingLicenseFeeById = async (id) => {
  const response = await api.get(`${servicePrefix}/driving-license-fees/${id}`)
  return response
}

//Driving License Punch
const getAllDrivingLicensePunch = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/license-punch`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getDrivingLicensePunch = async (id) => {
  const response = await api.get(`${servicePrefix}/license-punch/${id}`)
  return response
}

const getLicensePunchDataByLicenseNo = async (licenseNo) => {
  const response = await api.get(`${servicePrefix}/license-punch/search-by-licenseNo/${licenseNo}`)
  return response.data
}


const addDrivingLicensePunch = async (data) => {
  const response = await api.post(`${servicePrefix}/license-punch`, data)
  return response.data
}

const updateDrivingLicensePunch = async (data) => {
  const { id } = data
  const response = await api.patch(`${servicePrefix}/license-punch/${id}`, data)
  return response
}

const DrivingLicensePunchWithdrawal = async (data) => {
  const { id } = data
  const response = await api.patch(`${servicePrefix}/license-punch/${id}/withdraw`, data)
  return response
}

const getLicenseByLicenseNo = async (licenseNo) => {
  const response = await api.get(`${servicePrefix}/licenses/search/${licenseNo}`)
  return response
}

const getLicenseEndorsementsByLicenseNo = async (licenseNo) => {
  const response = await api.get(`${servicePrefix}/licenses/${licenseNo}/endorsements`)
  return response.data
}

const getLicensePunchByLicenseNo = async (licenseNo) => {
  const response = await api.get(`${servicePrefix}/license-punch/search-by-licenseNo/${licenseNo}`)
  return response.data
}

const getDrivingLicenseDriveTypesAll = async () => {
  const response = await api.get(`${servicePrefix}/drive-types/all`)
  return response.data
}

//Endorsement Cancellations

const getAllEndorsementCancellations = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/endorsement-cancellations`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const addEndorsementCancellation = async (cancellation) => {
  const response = await api.post(`${servicePrefix}/endorsement-cancellations`, cancellation)
  return response.data
}

//Endorsement Cancellations
const getEndorsementCancellation = async (id) => {
  const response = await api.get(`${servicePrefix}/endorsement-cancellations/${id}`)
  return response.data
}

const updateEndorsementCancellationAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/endorsement-cancellations/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const confirmEndorsementCancellation = async (id) => {
  const response = await api.patch(`${servicePrefix}/endorsement-cancellations/${id}/confirm-cancellation`)
  return response.data
}

const getAllOfficeLocationsAtOnce = async () => {
  const response = await api.get(`${servicePrefix}/office-locations/all`)
  return response.data
}


const DrivingLicenseService = {
  getAllOfficeLocationsAtOnce,

  //driving license punch
  getAllDrivingLicensePunch,
  getDrivingLicensePunch,
  addDrivingLicensePunch,
  updateDrivingLicensePunch,
  DrivingLicensePunchWithdrawal,

  //driving license application
  getAllDrivingLicenseApplication,
  getDrivingLicenseApplication,
  getDrivingLicenseApplicationByApplicationNo,
  addDrivingLicenseApplication,
  verifyDrivingLicenseApplication,
  rejectDrivingLicenseApplication,
  updateDrivingLicenseApplication,
  updateDrivingLicenseApplicationAttachment,
  updateDrivingLicenseApplicationPayment,
  deleteDrivingLicenseApplication,

  //driving license endorsement application
  getAllDrivingLicenseEndorsementApplication,
  getDrivingLicenseEndorsementApplication,
  addDrivingLicenseEndorsementApplication,
  verifyDrivingLicenseEndorsementApplication,
  rejectDrivingLicenseEndorsementApplication,
  updateDrivingLicenseEndorsementApplication,
  updateDrivingLicenseEndorsementApplicationAttachment,
  updateDrivingLicenseEndorsementApplicationPayment,
  deleteDrivingLicenseEndorsementApplication,

  //Ordinary driving license
  getAllOrdinaryDrivingLicense,
  getOrdinaryDrivingLicense,
  updateOrdinaryDrivingLicense,
  deleteOrdinaryDrivingLicense,
  searchOrdinaryDrivingLicense,
  getOrdinaryDrivingLicenseRenewals,
  getOrdinaryDrivingLicenseReplacement,
  getOrdinaryDrivingLicenseSuspension,
  getOrdinaryDrivingLicenseCancellation,
  searchOrdinaryDrivingLicenseByPisId,
  searchByLicenseNumber,

  //Professioanl driving license
  getAllProfessionalDrivingLicense,
  getProfessionalDrivingLicense,
  updateProfessionalDrivingLicense,
  deleteProfessionalDrivingLicense,
  searchProfessionalDrivingLicense,
  getProfessionalDrivingLicenseRenewals,
  getProfessionalDrivingLicenseReplacement,
  getProfessionalDrivingLicenseSuspension,
  getProfessionalDrivingLicenseCancellation,
  searchProfessionalDrivingLicenseByPisId,

  //renewal
  getAllDrivingLicenseRenewals,
  addDrivingLicenseRenewal,
  getDrivingLicenseRenewal,
  updateDrivingLicenseRenewal,
  updateDrivingLicenseRenewalAttachment,
  updateDrivingLicenseRenewalPayment,

  //replacements
  getAllDrivingLicenseReplacements,
  approveDrivingLicenseReplacement,
  rejectDrivingLicenseReplacement,
  addDrivingLicenseReplacement,
  getDrivingLicenseReplacement,
  updateDrivingLicenseReplacement,
  updateDrivingLicenseReplacementAttachment,
  updateDrivingLicenseReplacementPayment,

  //suspension
  getAllDrivingLicenseSuspension,
  addDrivingLicenseSuspension,
  getDrivingLicenseSuspension,
  updateDrivingLicenseSuspension,
  DrivingLicenseSuspensionWithdrawal,
  updateDrivingLicenseSuspensionAttachment,

  //cancellation
  getAllDrivingLicenseCancellation,
  addDrivingLicenseCancellation,
  getDrivingLicenseCancellation,
  updateDrivingLicenseCancellation,
  updateDrivingLicenseCancellationAttachment,
  postWithdrawDrivingLicenseCancellation,

  //print list
  addToPrintList,
  getAllDrivingLicensePrintList,
  getDrivingLicensePrintList,
  updateDrivingLicensePrintList,

  //update any payment
  updatePayment,
  getDrivingLicenseFeeById,
  editLicenseValidity,
  getLicenseByLicenseNo,
  getLicenseEndorsementsByLicenseNo,
  getLicenseByOwnerId,
  getLicenseByCid,
  getLicensePunchByLicenseNo,

  getDrivingLicenseDriveTypesAll,

  //Endorsement Cancellation
  addEndorsementCancellation,
  updateEndorsementCancellationAttachment,
  getAllEndorsementCancellations,
  getEndorsementCancellation,
  confirmEndorsementCancellation,

  //License Punch
  getLicensePunchDataByLicenseNo
}
export default DrivingLicenseService

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/common-data'

export const fetchAllDzongkhag = createAsyncThunk('dzongkhag/fetchAllDzongkhag', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllDzongkhag(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const fetchAllDzongkhagForGewogs = createAsyncThunk('dzongkhag/allDzongkhagForGewog', async () => {
  const response = await MasterService.getAllDzongkhags()
  return response
})

export const addDzongkhag = createAsyncThunk('dzongkhag/addDzongkhag', async (dzongkhag, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addDzongkhag(dzongkhag)
    dispatch(fetchAllDzongkhag(getState()?.dzongkhags.params))
    return dzongkhag
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDzongkhag = createAsyncThunk('dzongkhag/addDzongkhag', async (initialPost, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.updateDzongkhag(initialPost)
    dispatch(fetchAllDzongkhag(getState()?.dzongkhags.params))
    return initialPost
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDzongkhag = createAsyncThunk('dzongkhag/deleteDzongkhag', async (id, { dispatch, getState }) => {
  await MasterService.deleteDzongkhag(id)
  await dispatch(fetchAllDzongkhag(getState()?.dzongkhags.params))
  return id
})

const dzongkhagListSlice = createSlice({
  name: 'dzongkhag',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    dzongkhagForGewog: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchAllDzongkhag.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(fetchAllDzongkhagForGewogs.fulfilled, (state, action) => {
        state.dzongkhagForGewog = action.payload
      })
      .addCase(updateDzongkhag.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const dzongkhag = state.data.filter((dzongkha) => dzongkha.id !== id)
        state.dzongkhag = [...dzongkhag, action.payload]
      })
  }
})

export const getAllDzongkhags = (state) => state.dzongkhags.dzongkhag
export const selectDzongkhagById = (state, id) => state.dzongkhags.data.find((dzongkha) => dzongkha.id === id)

export default dzongkhagListSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllPassengerBusInspection = createAsyncThunk('passenger-bus-inspection/getAllPassengerBusInspection', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getAllPassengerBusInspection(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getPassengerBusInspection = createAsyncThunk('passenger-bus-inspection/getPassengerBusInspection', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getPassengerBusInspection(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})
// export const getPassengerBusInspection = createAsyncThunk('passenger-bus-inspection/getPassengerBusInspection', async (id) => {
//   const response = await VehicleService.getPassengerBusInspection(id)
//   // console.log(response)
//   return response
// })

export const addPassengerBusInspection = createAsyncThunk('passenger-bus-inspection/addPassengerBusInspection', async (permit, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addPassengerBusInspection(permit)
    // dispatch(getAllPassengerBusInspection(getState()?.BusInspections.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updatePassengerBusInspection = createAsyncThunk('passenger-bus-inspection/updatePassengerBusInspection', async (permit, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updatePassengerBusInspection(permit)
    // dispatch(getAllPassengerBusInspection(getState()?.BusInspections.params))
    return response
  } catch (err) {
    return rejectWithValue(err)
  }
})

export const deletePassengerBusInspection = createAsyncThunk('passenger-bus-inspection/deletePassengerBusInspection', async (id, { dispatch, getState }) => {
  await VehicleService.deletePassengerBusInspection(id)
  await dispatch(getAllPassengerBusInspection(getState()?.passengerBusInspections.params))
  return id
})

const BusInspection = createSlice({
  name: 'passenger_bus_inspections',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPassengerBusInspection: null,
    selectedVehicleData: null
  },
  reducers: {
    selectVehicle(state, action) {
      state.selectedVehicleData = action.payload
    },
    clearSelectedVehicle(state) {
      state.selectedVehicleData = null
    }
  },

  extraReducers(builder) {
    builder
      .addCase(getAllPassengerBusInspection.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedPassengerBusInspection = null
      })
      .addCase(getPassengerBusInspection.fulfilled, (state, action) => {
        state.selectedPassengerBusInspection = action.payload
      })
      .addCase(updatePassengerBusInspection.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const permit = state.data.filter((permit) => permit.id !== id)
        state.data = [...permit, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export const { selectVehicle, clearSelectedVehicle } = BusInspection.actions

export default BusInspection.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/common-data'

export const getAllRegions = createAsyncThunk('region/getAllRegions', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllRegions(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAllRegionForOffices = createAsyncThunk('region/getAllRegionForOfficeLocation', async () => {
  const response = await MasterService.getAllRegionForOffice()
  return response
})

export const addRegion = createAsyncThunk('region/addRegion', async (region, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addRegion(region)
    dispatch(getAllRegions(getState()?.regions.params))
    return region
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateRegion = createAsyncThunk('region/updateRegion', async (region, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.updateRegion(region)
    dispatch(getAllRegions(getState()?.regions.params))
    return region
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteRegion = createAsyncThunk('region/deleteRegion', async (id, { dispatch, getState }) => {
  await MasterService.deleteRegion(id)
  await dispatch(getAllRegions(getState()?.regions.params))
  return id
})

const RegionListSlice = createSlice({
  name: 'region',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    regionForOffice: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllRegions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getAllRegionForOffices.fulfilled, (state, action) => {
        state.regionForOffice = action.payload
      })
      .addCase(updateRegion.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const region = state.data.filter((region) => region.id !== id)
        state.data = [...region, action.payload]
      })
  }
})
export const selectRegionById = (state, id) => state.regions.data.find((region) => region.id === id)

export default RegionListSlice.reducer

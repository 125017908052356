// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingTestService from '@services/driving-test'

export const getDrivingTestCandidatesByTestConfigurationId = createAsyncThunk('practicalTestCandidates/getDrivingTestCandidatesByTestConfigurationId', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingTestService.getDrivingTestCandidatesByTestConfigurationId(id)
    return response
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

const PracticalTestCandidates = createSlice({
  name: 'practicalTestCandidates',
  initialState: {
    data: [],
    loading: true,
    errors: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getDrivingTestCandidatesByTestConfigurationId.fulfilled, (state, action) => {
        state.data = action.payload
        state.loading = false
        state.error = null
      })
      .addCase(getDrivingTestCandidatesByTestConfigurationId.pending, (state) => {
        state.selectedDrivingTestCandidate = null
        state.loading = true
        state.error = null
      })
      .addCase(getDrivingTestCandidatesByTestConfigurationId.rejected, (state, action) => {
        state.data = null
        state.errors = action.payload
        state.loading = false
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default PracticalTestCandidates.reducer

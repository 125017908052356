import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import PaymentService from '@services/payment'

export const getAllPayment = createAsyncThunk('payment/getAllPayment', async (params, { rejectWithValue }) => {
  try {
    const response = await PaymentService.getAllPayment(params)
    console.log('response', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const getPaymentByApplicationNo = createAsyncThunk('payment/getPaymentByApplicationNo', async (applicationNo, { rejectWithValue }) => {
  try {
    const response = await PaymentService.getPaymentByApplicationNo(applicationNo)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getRmaTransactionsByPaymentId = createAsyncThunk('payment/getRmaTransactionsByPaymentId', async (paymentId, { rejectWithValue }) => {
  try {
    const response = await PaymentService.getRmaTransactionsByPaymentId(paymentId)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

const paymentSlice = createSlice({
  name: 'payment',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedPayment: null,
    rmaTransactionsForSelectedPayment: [],
    loading: true,
    error: null,
    loadingRmaTransactions: true,
    errorRmaTransactions: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllPayment.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedPayment = null
      })
      .addCase(getPaymentByApplicationNo.fulfilled, (state, action) => {
        state.selectedPayment = action.payload
        console.log('action.payload', action.payload)
        state.loading = false
      })
      .addCase(getPaymentByApplicationNo.pending, (state) => {
        state.selectedPayment = null
        state.loading = true
      })
      .addCase(getRmaTransactionsByPaymentId.pending, (state) => {
        state.rmaTransactionsForSelectedPayment = []
        state.loadingRmaTransactions = true
      })
      .addCase(getRmaTransactionsByPaymentId.fulfilled, (state, action) => {
        state.rmaTransactionsForSelectedPayment = action.payload
        state.loadingRmaTransactions = false
      })
      .addCase(getRmaTransactionsByPaymentId.rejected, (state, action) => {
        state.rmaTransactionsForSelectedPayment = []
        state.loadingRmaTransactions = false
        state.errorRmaTransactions = action.payload
      })
  }
})

export default paymentSlice.reducer

// ** Icons Import
import { Circle } from 'react-feather'
import { FaCalculator } from 'react-icons/fa'
import { AiOutlineCalculator } from 'react-icons/ai'
export default [
  {
    id: 'charge-calculator',
    title: 'Charge Calculator',
    icon: <AiOutlineCalculator size={20} />,
    navLink: '/charge-calculator',
    badge: 'light-warning',
    resource: 'Dashboard'
  }
]

import { Circle } from 'react-feather'
import { AiOutlineCar } from 'react-icons/ai'

export default [
  {
    id: 'reports',
    title: 'Reports',
    icon: <AiOutlineCar size={20} />,
    children: [
      {
        id: 'report-configuration',
        title: 'Configurations',
        icon: <Circle size={12} />,
        navLink: '/reports/report-configurations',
        action: 'manage',
        resource: 'Report'
      },
      {
        id: 'generate-report',
        title: 'Generate',
        icon: <Circle size={12} />,
        navLink: '/reports/generate',
        action: 'read',
        resource: 'Report'
      }
      // {
      //   id: 'driving-test-candidate',
      //   title: 'Test Candidates',
      //   icon: <Circle size={12} />,
      //   navLink: '/driving-test/test-candidates'
      // },
      // {
      //   id: 'driving-test-questions',
      //   title: 'Manage Questions',
      //   icon: <Circle size={12} />,
      //   navLink: '/driving-test/test-questions',
      //   action: 'manage',
      //   resource: 'ETestQuestion'
      // },

      // {
      //   id: 'manage-test-marksheet',
      //   title: 'Manage Test Marks',
      //   icon: <Circle size={12} />,
      //   navLink: '/driving-test/test-marksheet/add',
      //   action: 'read',
      //   resource: 'ETestManagement'
      // }
    ]
  }
]

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehicleOwnershipTransfer = createAsyncThunk('ownership-transfer/getAllVehicleOwnershipTransfer', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getAllVehicleOwnershipTransfer(params)
    // console.log('cc', response.data)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addVehicleOwnershipTransfer = createAsyncThunk('ownership-transfer/addVehicleOwnershipTransfer', async (transfer, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleOwnershipTransfer(transfer)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getVehicleOwnershipTransfer = createAsyncThunk('ownership-transfer/getVehicleOwnershipTransfer', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getVehicleOwnershipTransfer(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleOwnershipTransferAttachment = createAsyncThunk('ownership-transfer/updateVehicleOwnershipTransferAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleOwnershipTransferAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleOwnershipTransfer = createAsyncThunk('ownership-transfer/updateVehicleOwnershipTransfer', async (transfer, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehicleOwnershipTransfer(transfer)
    // dispatch(getAllVehicleOwnershipTransfer(getState()?.vehicleOwnershipTransfers.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const deleteVehicleOwnershipTransfer = createAsyncThunk('cancellation-types/deleteVehicleOwnershipTransfer', async (id, { dispatch, getState }) => {
//   await MasterService.deleteVehicleOwnershipTransfer(id)
//   await dispatch(getAllCancellationTypes(getState()?.vehicleOwnershipTransfers.params))
//   return id
// })

const ownershipTransfer = createSlice({
  name: 'ownership_transfer',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedOwnershipTransfer: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllVehicleOwnershipTransfer.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedOwnershipTransfer = null
      })
      .addCase(getVehicleOwnershipTransfer.fulfilled, (state, action) => {
        state.selectedOwnershipTransfer = action.payload
      })

      .addCase(updateVehicleOwnershipTransfer.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const ownership = state.data.filter((ownership) => ownership.id !== id)
        state.data = [...ownership, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default ownershipTransfer.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehicleReplacement = createAsyncThunk('replacement/getAllVehicleReplacement', async (params, { rejectWithValue }) => {
  const response = await VehicleService.getAllVehicleReplacement(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

export const getIndividualVehicleReplacement = createAsyncThunk('replacement/getIndividualVehicleReplacement', async (id) => {
  const response = await VehicleService.getIndividualVehicleReplacement(id)
  // console.log(response)
  return response
})

export const addVehicleReplacement = createAsyncThunk('replacement/addVehicleReplacement', async (replacement, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleReplacement(replacement)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleReplacementAttachment = createAsyncThunk('replacement/updateVehicleReplacementAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleReplacementAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleReplacementPayment = createAsyncThunk('replacement/updateVehicleReplacementPayment', async (payment) => {
  const response = await VehicleService.updateVehicleReplacementPayment(payment)
  return response
})

export const vehicleSlice = createSlice({
  name: 'replacement',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedVehicleReplacement: null
  },
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllVehicleReplacement.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedVehicleReplacement = null
      })
      .addCase(getIndividualVehicleReplacement.fulfilled, (state, action) => {
        state.selectedVehicleReplacement = action.payload
      })
    // .addCase(upre.fulfilled, (state, action) => {
    //   if (!action.payload?.id) {
    //     console.log('Update could not complete')
    //     console.log(action.payload)
    //     return
    //   }
    //   const { id } = action.payload
    //   const replacement = state.data.filter((replacement) => replacement.id !== id)
    //   state.data = [...replacement, action.payload]
    // })
  }
})

export default vehicleSlice.reducer

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import EjectionCodeMasterService from '../../../../../services/vehicle-crash/masters/ejection-code'

export const getEjectionCodes = createAsyncThunk('ejectionCode/getEjectionCodes', async (params, { rejectWithValue }) => {
  try {
    const response = await EjectionCodeMasterService.getEjectionCodes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addEjectionCode = createAsyncThunk('ejectionCode/addEjectionCode', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await EjectionCodeMasterService.addEjectionCode(color)
    dispatch(getEjectionCodes(getState()?.ejectionCodes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateEjectionCode = createAsyncThunk('ejectionCode/updateEjectionCode', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await EjectionCodeMasterService.updateEjectionCode(color)
    dispatch(getEjectionCodes(getState()?.ejectionCodes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteEjectionCode = createAsyncThunk('ejectionCode/deleteEjectionCode', async (id, { dispatch, getState }) => {
  await EjectionCodeMasterService.deleteEjectionCode(id)
  await dispatch(getEjectionCodes(getState()?.ejectionCodes.params))
  return id
})

const ejectionCodeSlice = createSlice({
  name: 'ejectionCodes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getEjectionCodes.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectEjectionCodeById = (state, id) => state.ejectionCodes.data.find((ejectionCodes) => ejectionCodes.id === id)

export default ejectionCodeSlice.reducer

import { lazy } from 'react'
const PisOrganizationList = lazy(() => import('../../views/personal-information/organization/list'))
const PisOrganizationView = lazy(() => import('../../views/personal-information/organization/views'))
const AddPisOrganization = lazy(() => import('../../views/personal-information/organization/add'))

const PisOrganizationRoutes = [
  {
    path: '/personal-information/organization/list',
    element: <PisOrganizationList />,
    meta: {
      action: 'read',
      resource: 'Organization'
    }
  },
  {
    path: '/personal-information/organization/add',
    element: <AddPisOrganization />,
    meta: {
      action: 'create',
      resource: 'Organization'
    }
  },
  {
    path: '/personal-information/organization/view/:id',
    element: <PisOrganizationView />,
    meta: {
      action: 'read',
      resource: 'Organization'
    }
  }
]
export default PisOrganizationRoutes

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchVehicleType = createAsyncThunk('vehicleType/getAllVehicleType', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleMasterService.getAllVehicleType(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewVehicleType = createAsyncThunk('vehicleType/addNewVehicleType', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addVehicleType(type)
    dispatch(fetchVehicleType(getState()?.vehicleTypes?.params))
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleType = createAsyncThunk('vehicleType/updateVehicleType', async (type, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.updateVehicleType(type)
    dispatch(fetchVehicleType(getState()?.vehicleTypes?.params))
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteVehicleType = createAsyncThunk('vehicleType/deleteVehicleType', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteVehicleType(id)
  await dispatch(fetchVehicleType(getState()?.vehicleTypes?.params))
  return id
})

const vehicleTypeSlice = createSlice({
  name: 'vehicleType',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchVehicleType.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateVehicleType.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const type = state.vehicleType.filter((type) => type.id !== id)
        state.vehicleType = [...type, action.payload]
      })
  }
})
export const selectVehicleTypeById = (state, id) => state.vehicleTypes.data.find((type) => type.id === id)
export default vehicleTypeSlice.reducer

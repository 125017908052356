import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import NonMotoristTypeMasterService from '../../../../../services/vehicle-crash/masters/non-motorist-type'

export const getNonMotoristTypes = createAsyncThunk('nonMotoristType/getNonMotoristTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await NonMotoristTypeMasterService.getNonMotoristTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNonMotoristType = createAsyncThunk('nonMotoristType/addNonMotoristType', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await NonMotoristTypeMasterService.addNonMotoristType(color)
    dispatch(getNonMotoristTypes(getState()?.nonMotoristTypes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateNonMotoristType = createAsyncThunk('nonMotoristType/updateNonMotoristType', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await NonMotoristTypeMasterService.updateNonMotoristType(color)
    dispatch(getNonMotoristTypes(getState()?.nonMotoristTypes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteNonMotoristType = createAsyncThunk('nonMotoristType/deleteNonMotoristType', async (id, { dispatch, getState }) => {
  await NonMotoristTypeMasterService.deleteNonMotoristType(id)
  await dispatch(getNonMotoristTypes(getState()?.nonMotoristTypes.params))
  return id
})

const NonMotoristTypeSlice = createSlice({
  name: 'nonMotoristTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getNonMotoristTypes.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectNonMotoristTypeById = (state, id) => state.nonMotoristTypes.data.find((nonMotoristTypes) => nonMotoristTypes.id === id)

export default NonMotoristTypeSlice.reducer

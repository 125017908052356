import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getNonMotoristActions = async (params) => {
  const response = await api.get(`${servicePrefix}/non-motorist-actions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllNonMotoristAction = async () => {
  const response = await api.get(`${servicePrefix}/non-motorist-actions/all`)
  return response.data
}

const addNonMotoristAction = async (nonMotoristAction) => {
  const response = await api.post(`${servicePrefix}/non-motorist-actions`, nonMotoristAction)

  console.log(response)
  return response.data
}

const updateNonMotoristAction = async (nonMotoristAction) => {
  const { id } = nonMotoristAction
  const response = await api.patch(`${servicePrefix}/non-motorist-actions/${id}`, nonMotoristAction)
  return response.data
}

const deleteNonMotoristAction = async (id) => {
  const response = await api.delete(`${servicePrefix}/non-motorist-actions/${id}`)
  return response.data
}

const NonMotoristActionMasterService = {
  getNonMotoristActions,
  getAllNonMotoristAction,
  addNonMotoristAction,
  updateNonMotoristAction,
  deleteNonMotoristAction
}

export default NonMotoristActionMasterService

import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_VEHICLE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle/api`
//vehicle registration application
const getAllVehicleRegistration = async () => {
  const response = await api.get(`${servicePrefix}/registrations`)
  return response.data
}

const getVehicleRegistrations = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/registrations`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const addVehicleRegistration = async (registration) => {
  const response = await api.post(`${servicePrefix}/registrations`, registration)
  return response.data
}

const getVehicleRegistration = async (id) => {
  const response = await api.get(`${servicePrefix}/registrations/${id}`)
  return response.data
}

const updateVehicleRegistration = async (registration) => {
  const { id } = registration
  const response = await api.patch(`${servicePrefix}/registrations/${id}`, registration)
  return response.data
}

const updateVehicleRegistrationAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/registrations/${id}/upload-attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const verifyVehicleRegistration = async (id) => {
  const response = await api.patch(`${servicePrefix}/registrations/${id}/verify`)
  return response.data
}

const rejectVehicleRegistration = async (id) => {
  const response = await api.patch(`${servicePrefix}/registrations/${id}/reject`)
  return response.data
}

const updateVehicleRegistrationPayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/registrations/${id}/update-payment`, payment)
  return response.data
}

const deleteVehicleRegistration = async (id) => {
  const response = await api.delete(`${servicePrefix}/registrations/${id}`)
  return response.data
}

//vehicle
const getAllVehicle = async () => {
  const response = await api.get(`${servicePrefix}/vehicles`)
  return response.data
}

const getVehicles = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/vehicles`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const addVehicle = async (registration) => {
  const response = await api.post(`${servicePrefix}/vehicles`, registration)
  return response.data
}

const updateVehicleOwner = async (id, owner) => {
  const response = await api.patch(`${servicePrefix}/vehicles/${id}/change-organization`, owner)
  return response.data
}

const getVehicle = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}`)
  return response.data
}

const updateVehicle = async (vehicle) => {
  const { id } = vehicle
  const response = await api.patch(`${servicePrefix}/vehicles/${id}`, vehicle)
  return response.data
}

const deleteVehicle = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicles/${id}`)
  return response
}

const searchVehicle = async (search) => {
  const response = await api.get(`${servicePrefix}/vehicles/search/${search}`)
  return response.data
}

const searchVehicleByOrganization = async (organizationName) => {
  const response = await api.get(`${servicePrefix}/vehicles/search-by-organization-name/${organizationName}`)
  return response.data
}

//Emission
//getEmission
const getEmission = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}/emissions`)
  return response.data
}

const getNocIssuance = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}/noc-issuance`)
  return response.data
}

//Vehicle renewal
const getAllVehicleRenewal = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/renewals`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getIndividualVehicleRenewal = async (id) => {
  const response = await api.get(`${servicePrefix}/renewals/${id}`)
  return response.data
}

const getVehicleRenewal = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}/renewals`)
  return response.data
}

const addVehicleRenewal = async (renewal) => {
  const response = await api.post(`${servicePrefix}/renewals`, renewal)
  return response.data
}

const updateVehicleRenewalAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/renewals/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const updateVehicleRenewalPayment = async (renewal) => {
  const { id } = renewal
  const response = await api.patch(`${servicePrefix}/renewals/${id}/payment`, renewal)
  return response.data
}

//Vehicle fitness

const getAllVehicleFitness = async (params) => {
  const response = await api.get(`${servicePrefix}/road-worthiness-certificate-renewals`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleRWC = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}/road-worthiness-certificate-renewals`)
  return response.data
}

const getIndividualVehicleFitness = async (id) => {
  const response = await api.get(`${servicePrefix}/road-worthiness-certificate-renewals/${id}`)
  return response.data
}

const addVehicleFitness = async (fitness) => {
  const response = await api.post(`${servicePrefix}/road-worthiness-certificate-renewals`, stripEmptyObjects(fitness))
  return response.data
}

const updateVehicleFitnessPayment = async (fitness) => {
  const { id } = fitness
  const response = await api.patch(`${servicePrefix}/road-worthiness-certificate-renewals/${id}/payment`, fitness)
  return response.data
}

const updateVehicleFitnessAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/road-worthiness-certificate-renewals/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//vehicle replacement
const getAllVehicleReplacement = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/replacements`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getIndividualVehicleReplacement = async (id) => {
  const response = await api.get(`${servicePrefix}/replacements/${id}`)
  return response.data
}

const addVehicleReplacement = async (replacement) => {
  const response = await api.post(`${servicePrefix}/replacements`, replacement)
  return response.data
}

const updateVehicleReplacementAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/replacements/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const updateVehicleReplacementPayment = async (replacement) => {
  const { id } = replacement
  const response = await api.patch(`${servicePrefix}/replacements/${id}/payment`, replacement)
  return response.data
}

const approveVehicleReplacement = async (id) => {
  const response = await api.post(`${servicePrefix}/replacements/${id}/approve-application`)
  return response.data
}

const rejectVehicleReplacement = async (id) => {
  const response = await api.post(`${servicePrefix}/replacements/${id}/reject-application`)
  return response.data
}

const getVehicleReplacement = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}/replacements`)
  return response.data
}

//Vehicle cancellation
const getAllVehicleCancellation = async (params) => {
  const response = await api.get(`${servicePrefix}/cancellations`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleCancellationHistory = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}/cancellations`)
  return response.data
}

const getVehicleCancellation = async (id) => {
  const response = await api.get(`${servicePrefix}/cancellations/${id}`)
  return response.data
}

const addVehicleCancellation = async (cancellation) => {
  const response = await api.post(`${servicePrefix}/cancellations`, cancellation)
  return response.data
}

const updateVehicleCancellation = async (cancellation) => {
  const { id } = cancellation
  const response = await api.patch(`${servicePrefix}/cancellations/${id}`, cancellation)
  return response.data
}

const deleteVehicleCancellation = async (id) => {
  const response = await api.delete(`${servicePrefix}/cancellations/${id}`)
  return response.data
}

const updateVehicleCancellationAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/cancellations/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const approveVehicleCancellation = async (id) => {
  const response = await api.patch(`${servicePrefix}/cancellations/${id}/approve`)
  return response.data
}

const withdrawVehicleCancellation = async (id, data) => {
  const response = await api.post(`${servicePrefix}/cancellations/${id}/withdrawal`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//Vehicle conversion
const getAllVehicleConversion = async (params) => {
  const response = await api.get(`${servicePrefix}/conversions`, { params: stripEmptyObjects(params) })
  return response.data
}
const getVehicleConversionrHistory = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}/conversions`)
  return response.data
}

const getVehicleConversion = async (id) => {
  const response = await api.get(`${servicePrefix}/conversions/${id}`)
  return response.data
}

const addVehicleConversion = async (conversion) => {
  const response = await api.post(`${servicePrefix}/conversions`, conversion)
  return response.data
}

const updateVehicleConversion = async (conversion) => {
  const { id } = conversion
  const response = await api.patch(`${servicePrefix}/conversions/${id}`, conversion)
  return response.data
}

const deleteVehicleConversion = async (id) => {
  const response = await api.delete(`${servicePrefix}/conversions/${id}`)
  return response.data
}
const updateVehicleConversionAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/conversions/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const vehicleConversionPayment = async (conversion) => {
  const { id } = conversion
  const response = await api.patch(`${servicePrefix}/conversions/${id}/payment`, conversion)
  return response.data
}

//Noc issuance
const getAllVehicleNoc = async (params) => {
  const response = await api.get(`${servicePrefix}/noc-issuance`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleNoc = async (id) => {
  const response = await api.get(`${servicePrefix}/noc-issuance/${id}`)
  return response.data
}

const addVehicleNoc = async (noc) => {
  const response = await api.post(`${servicePrefix}/noc-issuance`, noc)
  return response.data
}

const updateVehicleNoc = async (noc) => {
  const { id } = noc
  const response = await api.patch(`${servicePrefix}/noc-issuance/${id}`, noc)
  return response.data
}

const deleteVehicleNoc = async (id) => {
  const response = await api.delete(`${servicePrefix}/noc-issuance/${id}`)
  return response.data
}

const updateVehicleNocAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/noc-issuance/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const vehicleNocPayment = async (noc) => {
  const { id } = noc
  const response = await api.patch(`${servicePrefix}/noc-issuance/${id}/payment`, noc)
  return response.data
}

//Ownership Transfers
const getAllVehicleOwnershipTransfer = async (params) => {
  const response = await api.get(`${servicePrefix}/ownership-transfers`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleOwnershipTransferHistory = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicles/${id}/ownership-transfers`)
  return response.data
}

const getVehicleOwnershipTransfer = async (id) => {
  const response = await api.get(`${servicePrefix}/ownership-transfers/${id}`)
  return response.data
}

const addVehicleOwnershipTransfer = async (transfer) => {
  const response = await api.post(`${servicePrefix}/ownership-transfers`, transfer)
  return response.data
}

const updateVehicleOwnershipTransfer = async (transfer) => {
  const { id } = transfer
  const response = await api.patch(`${servicePrefix}/ownership-transfers/${id}`, transfer)
  return response.data
}

const deleteVehicleOwnershipTransfer = async (id) => {
  const response = await api.delete(`${servicePrefix}/ownership-transfers/${id}`)
  return response.data
}

const updateVehicleOwnershipTransferAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/ownership-transfers/${id}/attachment`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const vehicleOwnershipTransferPayment = async (transfer) => {
  const { id } = transfer
  const response = await api.patch(`${servicePrefix}/ownership-transfers/${id}/payment`, transfer)
  return response.data
}

const verifyOwnershipTransfer = async (id) => {
  const response = await api.patch(`${servicePrefix}/ownership-transfers/${id}/verify`)
  return response.data
}

const approveOwnership = async (id) => {
  const response = await api.patch(`${servicePrefix}/ownership-transfers/${id}/approve`)
  return response.data
}

const rejectOwnershipTransfer = async (id) => {
  const response = await api.patch(`${servicePrefix}/ownership-transfers/${id}/reject`)
  return response.data
}

//Emission

const getAllVehicleEmission = async (params) => {
  const response = await api.get(`${servicePrefix}/emissions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getVehicleEmission = async (id) => {
  const response = await api.get(`${servicePrefix}/emissions/${id}`)
  return response.data
}

const addVehicleEmission = async (emissionData) => {
  const response = await api.post(`${servicePrefix}/emissions`, emissionData)
  return response.data
}

const updateVehicleEmission = async (emissionData) => {
  const { id } = emissionData
  const response = await api.patch(`${servicePrefix}/emissions/${id}`, emissionData)
  return response.data
}

const deleteVehicleEmission = async (id) => {
  const response = await api.delete(`${servicePrefix}/emissions/${id}`)
  return response.data
}

const updateEmissionAttachment = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/emissions/${id}/testFile`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//vehicle permit
const getAllVehiclePermit = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/vehicle-permits`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const addVehiclePermit = async (permit) => {
  const response = await api.post(`${servicePrefix}/vehicle-permits`, permit)
  return response.data
}

const getVehiclePermit = async (id) => {
  const response = await api.get(`${servicePrefix}/vehicle-permits/${id}`)
  return response.data
}

const updateVehiclePermit = async (permit) => {
  const { id } = permit
  const response = await api.patch(`${servicePrefix}/vehicle-permits/${id}`, permit)
  return response.data
}
const updateVehiclePermitPayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/vehicle-permits/${id}/payment`, payment)
  return response.data
}

const addVehiclePermitRenewal = async (renewal) => {
  const { id } = renewal
  const response = await api.post(`${servicePrefix}/vehicle-permits/${id}/renewal`, renewal)
  return response.data
}

const updateVehiclePermitRenewalPayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/vehicle-permits/renewal/${id}/payment`, payment)
  return response.data
}

const deleteVehiclePermit = async (id) => {
  const response = await api.delete(`${servicePrefix}/vehicle-permits/${id}`)
  return response.data
}

//passenger-bus-inspections
const getAllPassengerBusInspection = async (params) => {
  const response = await api.get(`${servicePrefix}/passenger-bus-inspections`, { params: stripEmptyObjects(params) })
  return response.data
}

const getPassengerBusInspection = async (id) => {
  const response = await api.get(`${servicePrefix}/passenger-bus-inspections/${id}`)
  return response.data
}

const addPassengerBusInspection = async (bus) => {
  const response = await api.post(`${servicePrefix}/passenger-bus-inspections`, bus)
  return response.data
}

const updatePassengerBusInspection = async (bus) => {
  const { id } = bus
  const response = await api.patch(`${servicePrefix}/passenger-bus-inspections/${id}`, bus)
  return response.data
}

const deletePassengerBusInspection = async (id) => {
  const response = await api.delete(`${servicePrefix}/passenger-bus-inspections/${id}`)
  return response.data
}

//update any payment type
const updatePayment = async (payment) => {
  const { id } = payment
  const response = await api.patch(`${servicePrefix}/registration-certificate-fees/${id}/edit-payment`, payment)
  return response
}

//registration-certificate-fees get by id
const getRCFeeById = async (id) => {
  const response = await api.get(`${servicePrefix}/registration-certificate-fees/${id}`)
  return response
}

export const addRegistrationCertificate = async (data) => {
  const response = await api.post(`${servicePrefix}/registration-certificates`, data)
  return response.data
}

export const editRegistrationCertificate = async (rcData) => {
  const { id } = rcData
  const response = await api.patch(`${servicePrefix}/registration-certificates/${id}/edit-dates`, rcData)
  return response.data
}

export const addRoadWorthinessCertificate = async (data) => {
  const response = await api.post(`${servicePrefix}/road-worthiness-certificates`, data)
  return response.data
}

export const editRoadWorthinessCertificate = async (rwcData) => {
  const { id } = rwcData
  const response = await api.patch(`${servicePrefix}/road-worthiness-certificates/${id}/edit-dates`, rwcData)
  return response.data
}

export const calculateRenewalFee = async (data) => {
  const response = await api.post(`${servicePrefix}/renewals/calculate`, data)
  return response.data
}

export const calculateTransferFee = async (data) => {
  const response = await api.post(`${servicePrefix}/ownership-transfers/calculate`, data)
  return response.data
}

const VehicleRegistrationService = {
  //emission
  getEmission,
  getAllVehicleEmission,
  getVehicleEmission,
  addVehicleEmission,
  updateVehicleEmission,
  deleteVehicleEmission,
  updateEmissionAttachment,

  //vehicle application
  getAllVehicleRegistration,
  getVehicleRegistrations,
  getVehicleRegistration,
  addVehicleRegistration,
  updateVehicleRegistration,
  updateVehicleRegistrationAttachment,
  verifyVehicleRegistration,
  rejectVehicleRegistration,
  updateVehicleRegistrationPayment,
  deleteVehicleRegistration,

  //vehicle
  getAllVehicle,
  getVehicles,
  getVehicle,
  addVehicle,
  updateVehicle,
  updateVehicleOwner,
  searchVehicle,
  searchVehicleByOrganization,
  deleteVehicle,
  addRegistrationCertificate,
  editRegistrationCertificate,
  addRoadWorthinessCertificate,
  editRoadWorthinessCertificate,

  //renewal
  getAllVehicleRenewal,
  getIndividualVehicleRenewal,
  getVehicleRenewal,
  addVehicleRenewal,
  updateVehicleRenewalPayment,
  updateVehicleRenewalAttachment,

  //fitness
  getAllVehicleFitness,
  getVehicleRWC,
  addVehicleFitness,
  updateVehicleFitnessPayment,
  updateVehicleFitnessAttachment,
  getIndividualVehicleFitness,

  //replacement
  getAllVehicleReplacement,
  getIndividualVehicleReplacement,
  approveVehicleReplacement,
  rejectVehicleReplacement,
  getVehicleReplacement,
  addVehicleReplacement,
  updateVehicleReplacementAttachment,
  updateVehicleReplacementPayment,

  //Vehicle cancellation
  getAllVehicleCancellation,
  getVehicleCancellationHistory,
  getVehicleCancellation,
  addVehicleCancellation,
  approveVehicleCancellation,
  withdrawVehicleCancellation,
  updateVehicleCancellationAttachment,
  updateVehicleCancellation,
  deleteVehicleCancellation,

  //vehicle conversion
  getAllVehicleConversion,
  getVehicleConversionrHistory,
  getVehicleConversion,
  addVehicleConversion,
  updateVehicleConversion,
  updateVehicleConversionAttachment,
  vehicleConversionPayment,
  deleteVehicleConversion,

  //noc issuance
  getNocIssuance,
  getAllVehicleNoc,
  getVehicleNoc,
  addVehicleNoc,
  updateVehicleNoc,
  updateVehicleNocAttachment,
  vehicleNocPayment,
  deleteVehicleNoc,

  //ownership-transfer
  getAllVehicleOwnershipTransfer,
  getVehicleOwnershipTransferHistory,
  getVehicleOwnershipTransfer,
  addVehicleOwnershipTransfer,
  updateVehicleOwnershipTransfer,
  updateVehicleOwnershipTransferAttachment,
  vehicleOwnershipTransferPayment,
  deleteVehicleOwnershipTransfer,
  verifyOwnershipTransfer,
  rejectOwnershipTransfer,
  approveOwnership,

  //vehicle permit
  getAllVehiclePermit,
  addVehiclePermit,
  getVehiclePermit,
  updateVehiclePermit,
  updateVehiclePermitPayment,
  addVehiclePermitRenewal,
  updateVehiclePermitRenewalPayment,
  deleteVehiclePermit,

  //Passenger Bus Inspection
  getAllPassengerBusInspection,
  getPassengerBusInspection,
  addPassengerBusInspection,
  updatePassengerBusInspection,
  deletePassengerBusInspection,

  //RC payment update
  updatePayment,
  getRCFeeById,

  //Charge calculation
  calculateRenewalFee,
  calculateTransferFee
}
export default VehicleRegistrationService

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import LearnerLicenseService from '@services/learner-license'

export const addLearnerLicenseCancellation = createAsyncThunk('learnerLicenseApplication/addLearnerLicenseCancellation', async (licenseCancellationDetails, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.addLearnerLicenseCancellation(licenseCancellationDetails)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

//AttachmentForSuspension
export const updateLearnerLicenseCancellationAttachment = createAsyncThunk('learnerLicenseApplication/updateLearnerLicenseSuspensionAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await LearnerLicenseService.updateLearnerLicenseCancellationAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAllCancelledList = createAsyncThunk('learnerLicense/getAllCancelledList', async (params, { rejectWithValue }) => {
  try {
    const response = await LearnerLicenseService.getLearnerLicenseCancellation(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getLearnerLicenseCancellationById = createAsyncThunk('learnerLicense/getLearnerLicenseCancellationById', async (id) => {
  const response = await LearnerLicenseService.getLearnerLicenseCancellationById(id)
  //   console.log('res', response)

  return response
})

export const learnerLicenseCancellationWithdrawal = createAsyncThunk('licensePunch/DrivingLicensePunchWithdrawal', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await LearnerLicenseService.learnerLicenseCancellationWithdrawal(id, data)

    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const learnerLicenseCancellationSlice = createSlice({
  name: 'learnerLicenseCancellation',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualLearnerLicenseCancellation: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllCancelledList.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getLearnerLicenseCancellationById.fulfilled, (state, action) => {
        state.individualLearnerLicenseCancellation = action.payload
        state.loading = false
      })
  }
})

export default learnerLicenseCancellationSlice.reducer

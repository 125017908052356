// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getAllDrivingLicenseRenewals = createAsyncThunk('renewal/getAllDrivingLicenseRenewals', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicenseRenewals(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrivingLicenseRenewal = createAsyncThunk('renewal/addDrivingLicenseRenewal', async (renewal, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseRenewal(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseRenewal = createAsyncThunk('renewal/getDrivingLicenseRenewal', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseRenewal(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseRenewalAttachment = createAsyncThunk('renewal/updateDrivingLicenseRenewalAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseRenewalAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseRenewalPayment = createAsyncThunk('renewal/updateDrivingLicenseRenewalPayment', async (renewal, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseRenewalPayment(renewal)
    return response
  } catch (err) {
    return rejectWithValue(err?.response?.data)
  }
})

// export const addDrivingLicenseCancellation = createAsyncThunk('cancellation/addDrivingLicenseCancellation', async (cancellation, { rejectWithValue, dispatch, getState }) => {
//   try {
//     const response = await DrivingLicenseService.addDrivingLicenseCancellation(cancellation)
//     dispatch(getAllDrivingLicenseRenewals(getState()?.drivingLicenseCancellations.params))
//     return response
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

export const updateDrivingLicenseRenewal = createAsyncThunk('renewal/updateDrivingLicenseRenewal', async (renewal, { rejectWithValue, dispatch, getState }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseRenewal(renewal)
    dispatch(getAllDrivingLicenseRenewals(getState()?.drivingLicenseRenewals.params))
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const updateDrivingLicenseRenewalAttachment = createAsyncThunk('cancellation/updateDrivingLicenseRenewalAttachment', async (data, { rejectWithValue }) => {
//   try {
//     const id = data.get('id')
//     const response = await DrivingLicenseService.updateDrivingLicenseRenewalAttachment(id, data)
//     return response.data
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

// export const updateDrivingLicenseRenewalAttachment = createAsyncThunk('cancellation/updateDrivingLicenseRenewalAttachment', async (attachment) => {
//   const response = await DrivingLicenseService.updateDrivingLicenseRenewalAttachment(attachment)
//   // console.log('conversion response', response)
//   return response
// })

const Renewal = createSlice({
  name: 'renewal',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDrivingLicenseRenewal: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicenseRenewals.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicenseRenewal = null
      })
      .addCase(getDrivingLicenseRenewal.fulfilled, (state, action) => {
        state.selectedDrivingLicenseRenewal = action.payload
      })
      .addCase(updateDrivingLicenseRenewal.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const renewal = state.data.filter((renewal) => renewal.id !== id)
        state.data = [...renewal, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default Renewal.reducer

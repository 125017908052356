// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehicleRegistrations = createAsyncThunk('vehicleRegistration/getAllVehicleRegistrations', async () => {
  const response = await VehicleService.getAllVehicleRegistration()
  //   console.log('ff', response)
  return response.data
})

// export const getVehicleRegistrations = createAsyncThunk('vehicleRegistration/getVehicleRegistrations', async (params) => {
//   // const response = await axios.get('/api/users/list/data', params)
//   const response = await VehicleService.getVehicleRegistrations(params)

//   return {
//     params,
//     data: response.data,
//     totalPages: response.meta.itemCount
//   }
// })
export const getVehicleRegistrations = createAsyncThunk('vehicleRegistration/getVehicleRegistrations', async (params, { rejectWithValue }) => {
  const response = await VehicleService.getVehicleRegistrations(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})

export const getVehicleRegistration = createAsyncThunk('vehicleRegistration/getVehicleRegistration', async (id) => {
  const response = await VehicleService.getVehicleRegistration(id)
  return response
})

export const addVehicleRegistration = createAsyncThunk('vehicleRegistration/addVehicleRegistration', async (registration, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleRegistration(registration)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleRegistration = createAsyncThunk('vehicleRegistration/updateVehicleRegistration', async (registration) => {
  const response = await VehicleService.updateVehicleRegistration(registration)
  return response
})

export const updateVehicleRegistrationPayment = createAsyncThunk('vehicleRegistration/updateVehicleRegistrationPayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehicleRegistrationPayment(payment)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleRegistrationAttachment = createAsyncThunk('vehicleRegistration/updateVehicleRegistrationAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateVehicleRegistrationAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const deleteVehicleRegistration = createAsyncThunk('vehicle/deleteVehicleRegistration', async (id, { dispatch, getState }) => {
//   await VehicleService.deleteVehicleRegistration(id)
//   await dispatch(getVehicleRegistrations(getState().params))
//   await dispatch(getVehicleRegistrations(getState()))
//   return id
// })

export const vehicleRegistrationSlice = createSlice({
  name: 'vehicleRegistration',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedApplication: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllVehicleRegistrations.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getVehicleRegistrations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getVehicleRegistration.fulfilled, (state, action) => {
        state.selectedApplication = action.payload
      })
  }
})

export default vehicleRegistrationSlice.reducer

import api from '@services'

const serviceBaseUrl = process.env.REACT_APP_DRIVING_LICENSE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/driving-license/api`

import { stripEmptyObjects } from '@utils'

//Drive types
const getAllDrivingLicenseDriveTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/drive-types`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDrivingLicenseDriveTypesAll = async () => {
  const response = await api.get(`${servicePrefix}/drive-types/all`)
  return response.data
}

const getDrivingLicenseDriveType = async (id) => {
  const response = await api.get(`${servicePrefix}/drive-types/${id}`)
  return response.data
}

const addDrivingLicenseDriveType = async (type) => {
  const response = await api.post(`${servicePrefix}/drive-types`, type)
  return response.data
}

const updateDrivingLicenseDriveType = async (type) => {
  const { id } = type
  const response = await api.patch(`${servicePrefix}/drive-types/${id}`, type)
  return response.data
}

const deleteDrivingLicenseDriveType = async (id) => {
  const response = await api.delete(`${servicePrefix}/drive-types/${id}`)
  return response.data
}

//Life spans
const getAllDrivingLicenseLifeSpan = async (params) => {
  const response = await api.get(`${servicePrefix}/life-spans`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDrivingLicenseLifeSpan = async (id) => {
  const response = await api.get(`${servicePrefix}/life-spans/${id}`)
  return response.data
}

const addDrivingLicenseLifeSpan = async (lifeSpan) => {
  const response = await api.post(`${servicePrefix}/life-spans`, lifeSpan)
  return response.data
}

const updateDrivingLicenseLifeSpan = async (lifeSpan) => {
  const { id } = lifeSpan
  const response = await api.patch(`${servicePrefix}/life-spans/${id}`, lifeSpan)
  return response.data
}

const deleteDrivingLicenseLifeSpan = async (id) => {
  const response = await api.delete(`${servicePrefix}/life-spans/${id}`)
  return response.data
}

//driving license Number Sequences
const getAllDrivingLicenseNumberSequence = async (params) => {
  const response = await api.get(`${servicePrefix}/number-sequences`, { params: stripEmptyObjects(params) })
  return response.data
}

const getDrivingLicenseNumberSequence = async (id) => {
  const response = await api.get(`${servicePrefix}/number-sequences/${id}`)
  return response.data
}

const addDrivingLicenseNumberSequence = async (numberSequence) => {
  const response = await api.post(`${servicePrefix}/number-sequences`, numberSequence)
  return response.data
}

const updateDrivingLicenseNumberSequence = async (numberSequence) => {
  const { id } = numberSequence
  const response = await api.patch(`${servicePrefix}/number-sequences/${id}`, numberSequence)
  return response.data
}

const deleteDrivingLicenseNumberSequence = async (id) => {
  const response = await api.delete(`${servicePrefix}/number-sequences/${id}`)
  return response.data
}

const DrivingLicenseService = {
  //Drive Types
  getAllDrivingLicenseDriveTypes,
  getDrivingLicenseDriveTypesAll,
  getDrivingLicenseDriveType,
  addDrivingLicenseDriveType,
  updateDrivingLicenseDriveType,
  deleteDrivingLicenseDriveType,

  //Life Spans
  getAllDrivingLicenseLifeSpan,
  getDrivingLicenseLifeSpan,
  addDrivingLicenseLifeSpan,
  updateDrivingLicenseLifeSpan,
  deleteDrivingLicenseLifeSpan,

  //driving license number sequence
  getAllDrivingLicenseNumberSequence,
  getDrivingLicenseNumberSequence,
  addDrivingLicenseNumberSequence,
  updateDrivingLicenseNumberSequence,
  deleteDrivingLicenseNumberSequence
}
export default DrivingLicenseService

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getEjectionCodes = async (params) => {
  const response = await api.get(`${servicePrefix}/ejection-code`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllEjectionCode = async () => {
  const response = await api.get(`${servicePrefix}/ejection-code/all`)
  return response.data
}

const addEjectionCode = async (ejectionCode) => {
  const response = await api.post(`${servicePrefix}/ejection-code`, ejectionCode)
  return response.data
}

const updateEjectionCode = async (ejectionCode) => {
  const { id } = ejectionCode
  const response = await api.patch(`${servicePrefix}/ejection-code/${id}`, ejectionCode)
  return response.data
}

const deleteEjectionCode = async (id) => {
  const response = await api.delete(`${servicePrefix}/ejection-code/${id}`)
  return response.data
}

const EjectionCodeMasterService = {
  getEjectionCodes,
  getAllEjectionCode,
  addEjectionCode,
  updateEjectionCode,
  deleteEjectionCode
}

export default EjectionCodeMasterService

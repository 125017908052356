import { lazy } from 'react'

// application
const ApplicationView = lazy(() => import('../../views/vehicles/vehicle-registration/application/view/index'))
const ApplicationList = lazy(() => import('../../views/vehicles/vehicle-registration/application/list/index'))
const NewRegistration = lazy(() => import('../../views/vehicles/vehicle-registration/application/add/index'))
const AddIndividualApplication = lazy(() => import('../../views/vehicles/vehicle-registration/application/add/individual/IndividualVehicleInfoCard'))
const AddOrganizationApplication = lazy(() => import('../../views/vehicles/vehicle-registration/application/add/organization/OrganizationVehicleInfoCard'))
const Attachment = lazy(() => import('../../views/vehicles/vehicle-registration/application/add/Attachment'))
const IndividualApplicationEdit = lazy(() => import('../../views/vehicles/vehicle-registration/application/edit/individual/Stepper'))
const OrganizationApplicationEdit = lazy(() => import('../../views/vehicles/vehicle-registration/application/edit/organization/index'))

//vehicle
const VehicleList = lazy(() => import('../../views/vehicles/vehicle/list/index'))
const VehicleView = lazy(() => import('../../views/vehicles/vehicle/view/index'))

//Renewal
const RenewalPending = lazy(() => import('../../views/vehicles/vehicle/renewal/list/index'))
const RenewalPendingView = lazy(() => import('../../views/vehicles/vehicle/renewal/view/index'))
const Renewal = lazy(() => import('../../views/vehicles/vehicle/renewal/search/search'))
const AddRenewal = lazy(() => import('../../views/vehicles/vehicle/renewal/index'))
const RenewalAttachment = lazy(() => import('../../views/vehicles/vehicle/renewal/Attachment'))
const RenewalPayment = lazy(() => import('../../views/vehicles/vehicle/renewal/payment/Payment'))

//Fitness
// const Fitness = lazy(() => import('../../views/vehicles/vehicle/fitness/search/index'))
const AddFitness = lazy(() => import('../../views/vehicles/vehicle/fitness/add/index'))
const FitnessPayment = lazy(() => import('../../views/vehicles/vehicle/fitness/add/PaymentUpdate'))
const FitnessDetails = lazy(() => import('../../views/vehicles/vehicle/fitness/add/FitnessDetails'))
const RenewalDuration = lazy(() => import('../../views/vehicles/vehicle/fitness/add/FitnessRenewalDuration'))
const FitnessAttachment = lazy(() => import('../../views/vehicles/vehicle/fitness/add/Attachment'))
const FitnessList = lazy(() => import('../../views/vehicles/vehicle/fitness/list/index'))
const FitnessView = lazy(() => import('../../views/vehicles/vehicle/fitness/view/index'))

//Replacement
const ReplacementPending = lazy(() => import('../../views/vehicles/vehicle/replacement/list/index'))
const ReplacementPendingView = lazy(() => import('../../views/vehicles/vehicle/replacement/view/index'))
const Replacement = lazy(() => import('../../views/vehicles/vehicle/replacement/search/search'))
const AddReplacement = lazy(() => import('../../views/vehicles/vehicle/replacement/index'))
const ReplacementAttachment = lazy(() => import('../../views/vehicles/vehicle/replacement/Attachment'))
const ReplacementPayment = lazy(() => import('../../views/vehicles/vehicle/replacement/payment/Payment'))

//Fitness Replacement
// const FitnessReplacement = lazy(() => import('../../views/vehicles/vehicle/search/index'))

//Emission

const Emission = lazy(() => import('../../views/vehicles/vehicle/emissions/index'))
const EmissionDetails = lazy(() => import('../../views/vehicles/vehicle/emissions/EmissionDetails'))
const EmissionAttachment = lazy(() => import('../../views/vehicles/vehicle/emissions/Attachment'))
const EmissionSearch = lazy(() => import('../../views/vehicles/vehicle/emissions/search/search'))
const EmissionList = lazy(() => import('../../views/vehicles/vehicle/emissions/list/index'))
const EmissionView = lazy(() => import('../../views/vehicles/vehicle/emissions/view/index'))
const PrintEmissionCertificate = lazy(() => import('../../views/vehicles/vehicle/emissions/view/PrintEmissionCertificate'))

//Noc
const NocIssuance = lazy(() => import('../../views/vehicles/vehicle/noc-issuance/search/search'))
const AddNoc = lazy(() => import('../../views/vehicles/vehicle/noc-issuance/index'))
const NocAttachment = lazy(() => import('../../views/vehicles/vehicle/noc-issuance/Attachment'))
const NocAttachmentReupload = lazy(() => import('../../views/vehicles/vehicle/noc-issuance/view/attachment-reupload/index'))
const NocPayment = lazy(() => import('../../views/vehicles/vehicle/noc-issuance/payment/Payment'))
const NocList = lazy(() => import('../../views/vehicles/vehicle/noc-issuance/list/index'))
const NocView = lazy(() => import('../../views/vehicles/vehicle/noc-issuance/view/index'))

//Conversion
const Conversion = lazy(() => import('../../views/vehicles/vehicle/conversion/search/search'))
const AddConversion = lazy(() => import('../../views/vehicles/vehicle/conversion/index'))
const ConversionAttachment = lazy(() => import('../../views/vehicles/vehicle/conversion/Attachment'))
const ConversionPayment = lazy(() => import('../../views/vehicles/vehicle/conversion/payment/Payment'))

//Cancellation
const CancellationList = lazy(() => import('../../views/vehicles/vehicle/cancellation/list/index'))
const Cancellation = lazy(() => import('../../views/vehicles/vehicle/cancellation/search/search'))
const AddCancellation = lazy(() => import('../../views/vehicles/vehicle/cancellation/add/index'))
const EditCancellation = lazy(() => import('../../views/vehicles/vehicle/cancellation/edit/index'))
const EditCancellationAttachment = lazy(() => import('../../views/vehicles/vehicle/cancellation/edit/Attachment'))
const CancellationAttachment = lazy(() => import('../../views/vehicles/vehicle/cancellation/add/Attachment'))
const CancellationApprove = lazy(() => import('../../views/vehicles/vehicle/cancellation/add/Approve'))
const CancellationWithdraw = lazy(() => import('../../views/vehicles/vehicle/cancellation/view/withdrawal'))
const CancellationView = lazy(() => import('../../views/vehicles/vehicle/cancellation/view/index'))

//Cancellation Withdrawal
// const CancellationWithdrawal = lazy(() => import('../../views/vehicles/vehicle/search/index'))

//Ownership Transfer
const OwnershipTransferList = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/list/index'))
const OwnershipTransferView = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/view/index'))
const OwnershipTransfer = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/search/search'))
const AddOwnershipTransfer = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/index'))
const AddIndividualOwnerShip = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/individual/IndividualVehicleInfoCard'))
const AddOrganizationOwnerShip = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/organization/OrganizationVehicleInfoCard'))
const OwnerShipAttachment = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/Attachment'))
const OwnerShipAttachmentReUpload = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/view/Attachment'))
const OwnerShipPayment = lazy(() => import('../../views/vehicles/vehicle/ownership-transfer/payment/Payment'))

//vehicle Permit
const VehiclePermitList = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/list/index'))
const AddVehiclePermit = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/add/index'))
const VehiclePermitLicenseSearch = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/add/bhutanese/LicenseSearch'))
const AddBhutaneseVehiclePermit1 = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/add/bhutanese/BhutanesePermit1'))
const AddBhutaneseVehiclePermit2 = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/add/bhutanese/BhutanesePermit2'))
const VehiclePermitView = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/view/index'))
const EditVehiclePermitForeign = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/edit/Foreign'))
const EditVehiclePermitBhutanese = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/edit/Bhutanese'))
const VehiclePermitRenewal = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/renewal/index'))
const VehiclePermitRenewalPayment = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/renewal/payment/Payment'))
const VehiclePermitCertificate = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/view/permit-certificate/index'))
const VehiclePermitPrint = lazy(() => import('../../views/vehicles/vehicle/vehicle-permit/view/vehicle-permit/index'))

// Passenger Bus InspectionList
const PassengerBusInspectionList = lazy(() => import('../../views/vehicles/vehicle/passenger-bus-inspection/list/index'))
const VehicleSearch = lazy(() => import('../../views/vehicles/vehicle/passenger-bus-inspection/add/index'))
const LicenseSearch = lazy(() => import('../../views/vehicles/vehicle/passenger-bus-inspection/add/LicenseSearch'))
const BusInspection = lazy(() => import('../../views/vehicles/vehicle/passenger-bus-inspection/add/Inspection'))
const EditBusInspection = lazy(() => import('../../views/vehicles/vehicle/passenger-bus-inspection/edit/index'))
const ViewBusInspection = lazy(() => import('../../views/vehicles/vehicle/passenger-bus-inspection/view/index'))

//Noc Certificate
const PrintCertificate = lazy(() => import('../../views/vehicles/vehicle/noc-certificate/index'))
const PrintCertificateFromView = lazy(() => import('../../views/vehicles/vehicle/noc-issuance/view/noc-certificate/index'))

const VehicleRoutes = [
  //Application
  {
    path: '/vehicle/application/view/:id',
    element: <ApplicationView />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/application/view/:id/individual-edit',
    element: <IndividualApplicationEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },

  {
    path: '/vehicle/application/view/:id/organization-edit',
    element: <OrganizationApplicationEdit />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },

  {
    path: '/vehicle/registration',
    element: <NewRegistration />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/application/new/individual/:id/vehicle-details',
    element: <AddIndividualApplication />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/application/new/organization/:id/vehicle-details',
    element: <AddOrganizationApplication />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/application/attachment/:id',
    element: <Attachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },

  {
    path: '/vehicle/application/lists',
    element: <ApplicationList />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },

  //Vehicle
  {
    path: '/vehicle/lists',
    element: <VehicleList />,
    meta: {
      action: 'read',
      resource: 'Vehicle'
    }
  },
  {
    path: '/vehicle/view/:id',
    element: <VehicleView />,
    meta: {
      action: 'read',
      resource: 'Vehicle'
    }
  },

  //Renewal
  {
    path: '/vehicle/pending-application/renewal',
    element: <RenewalPending />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/pending-application/renewal/:id/view',
    element: <RenewalPendingView />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/search/renewal',
    element: <Renewal />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/:id/renewal',
    element: <AddRenewal />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/renewal/:id/attachment',
    element: <RenewalAttachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration',
      meta: {
        action: 'manage',
        resource: 'VehicleRegistration'
      }
    }
  },
  {
    path: '/vehicle/renewal/:id/payment',
    element: <RenewalPayment />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },

  //Fitness
  {
    path: '/vehicle/fitness/list',
    element: <FitnessList />,
    meta: {
      action: 'manage',
      resource: 'VehicleFitness'
    }
  },
  {
    path: '/vehicle/fitness/search',
    element: <AddFitness />,
    meta: {
      action: 'view',
      resource: 'VehicleFitness'
    }
  },
  {
    path: '/vehicle/fitness/:id/payment',
    element: <FitnessPayment />,
    meta: {
      action: 'create',
      resource: 'VehicleFitness'
    }
  },
  {
    path: '/vehicle/fitness/fitness-details',
    element: <FitnessDetails />,
    meta: {
      action: 'view',
      resource: 'VehicleFitness'
    }
  },
  {
    path: '/vehicle/fitness/fitness-renewal-duration',
    element: <RenewalDuration />,
    meta: {
      action: 'manage',
      resource: 'VehicleFitness'
    }
  },
  {
    path: '/vehicle/fitness/:id/attachments',
    element: <FitnessAttachment />,
    meta: {
      action: 'update',
      resource: 'VehicleFitness'
    }
  },
  {
    path: '/vehicle/fitness/list/view/:id',
    element: <FitnessView />,
    meta: {
      action: 'view',
      resource: 'VehicleFitness'
    }
  },

  //Replacement
  {
    path: '/vehicle/pending-application/replacement',
    element: <ReplacementPending />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/pending-application/replacement/:id/view',
    element: <ReplacementPendingView />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/search/replacement',
    element: <Replacement />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/:id/replacement',
    element: <AddReplacement />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/replacement/:id/attachment',
    element: <ReplacementAttachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/replacement/:id/payment',
    element: <ReplacementPayment />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },

  //Fitness Replacement
  // {
  //   path: '/vehicle/search/fitness-replacement',
  //   element: <FitnessReplacement />
  // },

  //Emission
  {
    path: '/vehicle/:id/emission',
    element: <Emission />,
    meta: {
      action: 'manage',
      resource: 'VehicleEmission'
    }
  },
  {
    path: '/vehicle/emission/:id/attachment',
    element: <EmissionAttachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleEmission'
    }
  },
  {
    path: '/vehicle/emission/:id/emission-details',
    element: <EmissionDetails />,
    meta: {
      action: 'read',
      resource: 'VehicleEmission'
    }
  },
  {
    path: '/vehicle/emission/search',
    element: <EmissionSearch />,
    meta: {
      action: 'read',
      resource: 'VehicleEmission'
    }
  },
  {
    path: '/vehicle/emission',
    element: <EmissionList />,
    meta: {
      action: 'read',
      resource: 'VehicleEmission'
    }
  },
  {
    path: '/vehicle/emission/view/:id',
    element: <EmissionView />,
    meta: {
      action: 'read',
      resource: 'VehicleEmission'
    }
  },
  {
    path: '/vehicle/emission/view/:id/print-certificate',
    element: <PrintEmissionCertificate />,
    meta: {
      action: 'read',
      resource: 'VehicleEmission',
      layout: 'blank'
    }
  },

  //Conversion
  {
    path: '/vehicle/search/conversion',
    element: <Conversion />,
    meta: {
      action: 'manage',
      resource: 'VehicleConversion'
    }
  },
  {
    path: '/vehicle/:id/conversion',
    element: <AddConversion />,
    meta: {
      action: 'manage',
      resource: 'VehicleConversion'
    }
  },
  {
    path: '/vehicle/conversion/:id/attachment',
    element: <ConversionAttachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleConversion'
    }
  },
  {
    path: '/vehicle/conversion/:id/payment',
    element: <ConversionPayment />,
    meta: {
      action: 'manage',
      resource: 'VehicleConversion'
    }
  },

  //Cancellation
  {
    path: '/vehicle/cancellation/lists',
    element: <CancellationList />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  },
  {
    path: '/vehicle/search/cancellation',
    element: <Cancellation />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  },
  {
    path: '/vehicle/view/:id/cancellation',
    element: <CancellationView />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  },
  {
    path: '/vehicle/:id/cancellation',
    element: <AddCancellation />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  },
  {
    path: '/vehicle/edit/:id/cancellation',
    element: <EditCancellation />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  },
  {
    path: '/vehicle/edit/:id/attachment',
    element: <EditCancellationAttachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  },
  {
    path: '/vehicle/cancellation/:id/attachment',
    element: <CancellationAttachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  },

  {
    path: '/vehicle/cancellation/:id/approve',
    element: <CancellationApprove />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  },

  //Cancellation withdrawal
  // {
  //   path: '/vehicle/search/cancellation-withdrawal',
  //   element: <CancellationWithdrawal />
  // },

  //Ownership Transfer
  {
    path: '/vehicle/ownership-transfer',
    element: <OwnershipTransferList />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },
  {
    path: '/vehicle/ownership-transfer/:id/view',
    element: <OwnershipTransferView />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },
  {
    path: '/vehicle/ownership-transfer/search',
    element: <OwnershipTransfer />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },
  {
    path: '/vehicle/ownership-transfer/:id',
    element: <AddOwnershipTransfer />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },
  {
    path: '/vehicle/ownership-transfer/:id/individual-transfer-details',
    element: <AddIndividualOwnerShip />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },
  {
    path: '/vehicle/ownership-transfer/:id/organization-transfer-details',
    element: <AddOrganizationOwnerShip />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },
  {
    path: '/vehicle/ownership-transfer/:id/attachment',
    element: <OwnerShipAttachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },
  {
    path: '/vehicle/ownership-transfer/:id/attachment-upload',
    element: <OwnerShipAttachmentReUpload />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },
  {
    path: '/vehicle/ownership-transfer/:id/payment',
    element: <OwnerShipPayment />,
    meta: {
      action: 'manage',
      resource: 'VehicleOwnershipTransfer'
    }
  },

  //NOC
  {
    path: '/vehicle/noc/list/add',
    element: <NocIssuance />,
    meta: {
      action: 'manage',
      resource: 'VehicleNocIssuance'
    }
  },
  {
    path: '/vehicle/noc/list',
    element: <NocList />,
    meta: {
      action: 'manage',
      resource: 'VehicleNocIssuance'
    }
  },
  {
    path: '/vehicle/noc/list/view/:id',
    element: <NocView />,
    meta: {
      action: 'manage',
      resource: 'VehicleNocIssuance'
    }
  },
  {
    path: '/vehicle/noc/list/add/:id/noc',
    element: <AddNoc />,
    meta: {
      action: 'manage',
      resource: 'VehicleNocIssuance'
    }
  },
  {
    path: '/vehicle/noc/:id/attachment',
    element: <NocAttachment />,
    meta: {
      action: 'manage',
      resource: 'VehicleNocIssuance'
    }
  },
  {
    path: '/vehicle/noc/list/view/:id/attachment-reupload',
    element: <NocAttachmentReupload />,
    meta: {
      action: 'manage',
      resource: 'VehicleNocIssuance'
    }
  },
  {
    path: '/vehicle/noc/:id/payment',
    element: <NocPayment />,
    meta: {
      action: 'manage',
      resource: 'VehicleNocIssuance'
    }
  },

  //vehicle Permit
  {
    path: '/vehicle/vehicle-permit',
    element: <VehiclePermitList />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/add',
    element: <AddVehiclePermit />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/add/license-search',
    element: <VehiclePermitLicenseSearch />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/add/bhutanese-vehicle-permit-dl',
    element: <AddBhutaneseVehiclePermit1 />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/add/bhutanese-vehicle-permit-pd',
    element: <AddBhutaneseVehiclePermit2 />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/:id/view',
    element: <VehiclePermitView />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/edit/:id/foreign',
    element: <EditVehiclePermitForeign />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/edit/:id/bhutanese',
    element: <EditVehiclePermitBhutanese />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/:id/renewal',
    element: <VehiclePermitRenewal />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/:id/renewal-payment',
    element: <VehiclePermitRenewalPayment />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/view/:id/print-certificate',
    element: <VehiclePermitCertificate />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },
  {
    path: '/vehicle/vehicle-permit/view/:id/vehicle-permit',
    element: <VehiclePermitPrint />,
    meta: {
      action: 'manage',
      resource: 'VehicleRoutePermit'
    }
  },

  //Passenger Bus Inspection
  {
    path: '/vehicle/passenger-bus-inspection',
    element: <PassengerBusInspectionList />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/passenger-bus-inspection/add/vehicle-search',
    element: <VehicleSearch />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/passenger-bus-inspection/add/license-search',
    element: <LicenseSearch />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/passenger-bus-inspection/add/bus-inspection',
    element: <BusInspection />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/passenger-bus-inspection/:id/edit',
    element: <EditBusInspection />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/passenger-bus-inspection/:id/view',
    element: <ViewBusInspection />,
    meta: {
      action: 'manage',
      resource: 'BusInspection'
    }
  },

  //Print NOC Certificate PrintCertificateFromView
  {
    path: '/vehicle/application/view/:id/print-noc-cancellation-certificate',
    element: <PrintCertificate />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/noc/list/view/:id/print-noc-cancellation-certificate',
    element: <PrintCertificateFromView />,
    meta: {
      action: 'manage',
      resource: 'VehicleRegistration'
    }
  },
  {
    path: '/vehicle/view/:id/withdraw',
    element: <CancellationWithdraw />,
    meta: {
      action: 'manage',
      resource: 'VehicleCancellation'
    }
  }
]

export default VehicleRoutes

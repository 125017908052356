import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchEngineType = createAsyncThunk('engineType/getAllEngineType', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleMasterService.getAllEngineType(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewEngineType = createAsyncThunk('engineType/addNewEngineType', async (engineType, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addEngineType(engineType)
    dispatch(fetchEngineType(getState()?.engineTypes?.params))
    return engineType
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateEngineType = createAsyncThunk('engineType/updateEngineType', async (engineType, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.updateEngineType(engineType)
    dispatch(fetchEngineType(getState()?.engineTypes?.params))
    return engineType
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteEngineType = createAsyncThunk('engineType/deleteEngineType', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteEngineType(id)
  await dispatch(fetchEngineType(getState()?.engineTypes?.params))
  return id
})

const engineTypeSlice = createSlice({
  name: 'engineType',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchEngineType.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateEngineType.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const engineType = state.data.filter((engineType) => engineType.id !== id)
        state.data = [...engineType, action.payload]
      })
  }
})

export const getAllEngineType = (state) => state.engineTypes.engineType
export const selectEngineTypeById = (state, id) => state.engineTypes.data.find((engineType) => engineType.id === id)

export default engineTypeSlice.reducer

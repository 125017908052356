import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import MasterService from '@services/masters/common-data'

export const fetchCountry = createAsyncThunk('country/getAllCountry', async (params, { rejectWithValue }) => {
  try {
    const response = await MasterService.getAllCountry(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewCountry = createAsyncThunk('country/addNewCountry', async (country, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.addCountry(country)
    dispatch(fetchCountry(getState()?.countries.params))
    return country
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateCountry = createAsyncThunk('country/updateCountry', async (country, { rejectWithValue, dispatch, getState }) => {
  try {
    await MasterService.updateCountry(country)
    dispatch(fetchCountry(getState()?.countries.params))
    return country
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteCountry = createAsyncThunk('country/deleteCountry', async (id, { dispatch, getState }) => {
  await MasterService.deleteCountry(id)
  await dispatch(fetchCountry(getState()?.countries.params))
  return id
})

const countrySlice = createSlice({
  name: 'country',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchCountry.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateCountry.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const country = state.data.filter((country) => country.id !== id)
        state.country = [...country, action.payload]
      })
  }
})

export const selectCountryById = (state, id) => state.countries.data.find((country) => country.id === id)

export default countrySlice.reducer

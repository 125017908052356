import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleMasterService from '@services/masters/vehicle-master'

export const fetchDealer = createAsyncThunk('dealer/getAllDealer', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleMasterService.getAllDealer(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNewDealer = createAsyncThunk('dealer/addNewDealer', async (dealer, { rejectWithValue, dispatch, getState }) => {
  try {
    await VehicleMasterService.addDealer(dealer)
    dispatch(fetchDealer(getState()?.dealers.params))
    return dealer
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDealer = createAsyncThunk('dealer/updateDealer', async (dealer, { dispatch, getState }) => {
  const response = await VehicleMasterService.updateDealer(dealer)
  dispatch(fetchDealer(getState()?.dealers.params))
  return response.data
})

export const deleteDealer = createAsyncThunk('dealer/deleteDealer', async (id, { dispatch, getState }) => {
  await VehicleMasterService.deleteDealer(id)
  await dispatch(fetchDealer(getState()?.dealers.params))
  return id
})

const dealerSlice = createSlice({
  name: 'dealer',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(fetchDealer.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })

      .addCase(updateDealer.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const dealer = state.dealer.filter((dealer) => dealer.id !== id)
        state.dealer = [...dealer, action.payload]
      })
  }
})

export const selectDealerById = (state, id) => state.dealers.data.find((dealer) => dealer.id === id)

export default dealerSlice.reducer

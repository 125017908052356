import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_LEARNER_LICENSE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/learner-license/api`

//** Learner License Application */

//get learner license applications
const getLearnerLicenseApplications = async (params) => {
  const response = await api.get(`${servicePrefix}/applications`, { params: stripEmptyObjects(params) })
  return response.data
}

//get individual learner license application
const getLearnerLicenseApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/applications/${id}`)
  return response.data
}

//add learner license application
const addLearnerLicenseApplication = async (learnerLicenseInfo) => {
  const response = await api.post(`${servicePrefix}/applications`, learnerLicenseInfo)
  return response.data
}

// verifing learner license application
const verifyLearnerLicenseApplication = async (id) => {
  await api.patch(`${servicePrefix}/applications/${id}/verify-application`)
  return id
}

const rejectLearnerApplication = async (id, payload) => {
  const response = await api.patch(`${servicePrefix}/applications/${id}/reject`, payload)
  return response.data
}

//update learner license application
const updateLearnerLicenseApplication = async (learnerLicenseInfo) => {
  const { id } = learnerLicenseInfo
  const response = await api.patch(`${servicePrefix}/applications/${id}`, learnerLicenseInfo)
  return response.data
}

//getAllDriveTypes
const driveTypeAll = async () => {
  const response = await api.get(`${servicePrefix}/drive-types/all`)
  return response
}

//update payment for learner license
const updatePaymentForLearnerLicense = async (paymentInfo) => {
  const { id } = paymentInfo
  const response = await api.patch(`${servicePrefix}/applications/${id}/payment`, paymentInfo)
  return response.data
}

//Attachment

const updateLearnerLicenseAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/applications/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//**  Learner License  */

//get learner license
const getLearnerLicense = async (params) => {
  const response = await api.get(`${servicePrefix}/learner-licenses`, { params: stripEmptyObjects(params) })
  return response.data
}

//get individual learner license
const getLearnerLicenseById = async (id) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/${id}`)
  return response.data
}

//get suspensions

const getSuspension = async (id) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/${id}/suspensions`)
  return response.data
}

//get cancellation

const getCancellation = async (id) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/${id}/cancellations`)
  return response.data
}

// Checker
const checkLearnerLicenseHolder = async (pisId) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/search/${pisId}`)
  return response.data
}

const searchLearnerLicenseHolder = async (learnerLicense) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/search-learner-by-learner-number/${learnerLicense}`)
  return response.data
}

const searchLearnerLicenseByLicense = async (learnerLicense) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/search-learner-by-learner-number/${learnerLicense}`)
  return response
}

//License Renewal
const getLearnerLicenseRenewal = async (params) => {
  const response = await api.get(`${servicePrefix}/renewals`, { params: stripEmptyObjects(params) })
  return response.data
}

const getLearnerRenewalByLearnerId = async (id) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/${id}/renewals`)
  return response.data
}

const getLearnerLicenseRenewalById = async (id) => {
  const response = await api.get(`${servicePrefix}/renewals/${id}`)
  return response.data
}

const addLearnerLicenseRenewal = async (licenseRenewalDetails) => {
  const response = await api.post(`${servicePrefix}/renewals`, licenseRenewalDetails)
  return response.data
}

const updatePaymentForLearnerLicenseRenewal = async (paymentInfoforRenewal) => {
  const { id } = paymentInfoforRenewal
  const response = await api.patch(`${servicePrefix}/renewals/${id}/payment`, paymentInfoforRenewal)
  return response.data
}

//Attachment

const updateLearnerLicenseRenewalAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/renewals/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//Learner License Replacement

const getLearnerLicenseReplacement = async (params) => {
  const response = await api.get(`${servicePrefix}/replacements`, { params: stripEmptyObjects(params) })
  return response.data
}

const getLearnerLicenseReplacementById = async (id) => {
  const response = await api.get(`${servicePrefix}/replacements/${id}`)
  return response.data
}

const getLearnerReplacementByLearnerId = async (id) => {
  const response = await api.get(`${servicePrefix}/learner-licenses/${id}/replacements`)
  return response.data
}

const addLearnerLicenseReplacement = async (licenseReplacementDetails) => {
  const response = await api.post(`${servicePrefix}/replacements`, licenseReplacementDetails)
  return response.data
}

const updatePaymentForLearnerLicenseReplacement = async (paymentInfoforReplacement) => {
  const { id } = paymentInfoforReplacement
  const response = await api.patch(`${servicePrefix}/replacements/${id}/payment`, paymentInfoforReplacement)
  return response.data
}

const approveApplication = async (id) => {
  await api.post(`${servicePrefix}/replacements/${id}/approve-application`)
  return id
}

const rejectApplication = async (id) => {
  await api.post(`${servicePrefix}/replacements/${id}/reject-application`)
  return id
}

//Attachment

const updateLearnerLicenseReplacementAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/replacements/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//update any payment type
const updatepayment = async (paymentInfo) => {
  const { id } = paymentInfo
  const response = await api.patch(`${servicePrefix}/learner-license-fees/${id}/edit-payment`, paymentInfo)
  return response
}

//learner license fee get by id
const learnerLicenseFeeById = async (id) => {
  const response = await api.get(`${servicePrefix}/learner-license-fees/${id}`)
  return response
}

//License Suspensions
const getLearnerLicenseSuspension = async (params) => {
  const response = await api.get(`${servicePrefix}/suspensions`, { params: stripEmptyObjects(params) })
  return response.data
}

const getLearnerLicenseSuspensionById = async (id) => {
  const response = await api.get(`${servicePrefix}/suspensions/${id}`)
  return response.data
}

const learnerLicenseSuspensionWithdrawal = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/suspensions/${id}/withdraw`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}

const addLearnerLicenseSuspension = async (licenseSuspensionDetails) => {
  const response = await api.post(`${servicePrefix}/suspensions`, licenseSuspensionDetails)
  return response.data
}

const updateLearnerLicenseSuspensionAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/suspensions/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

//License Cancellation
const getLearnerLicenseCancellation = async (params) => {
  const response = await api.get(`${servicePrefix}/cancellations`, { params: stripEmptyObjects(params) })
  return response.data
}

const getLearnerLicenseCancellationById = async (id) => {
  const response = await api.get(`${servicePrefix}/cancellations/${id}`)
  return response.data
}

const addLearnerLicenseCancellation = async (licenseCancellationDetails) => {
  const response = await api.post(`${servicePrefix}/cancellations`, licenseCancellationDetails)
  return response.data
}

const updateLearnerLicenseCancellationAttachment = async (id, data) => {
  const response = await api.post(`${servicePrefix}/cancellations/${id}/attachments`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const learnerLicenseCancellationWithdrawal = async (id, data) => {
  const response = await api.patch(`${servicePrefix}/cancellations/${id}/withdraw`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response
}
//Learner Number Sequences
const getAllLearnerNumberSequence = async (params) => {
  const response = await api.get(`${servicePrefix}/learner-number-sequences`, { params: stripEmptyObjects(params) })
  return response.data
}

const getLearnerleNumberSequence = async (id) => {
  const response = await api.get(`${servicePrefix}/learner-number-sequences/${id}`)
  return response.data
}

const addLearnerleNumberSequence = async (numberSequence) => {
  const response = await api.post(`${servicePrefix}/learner-number-sequences`, numberSequence)
  return response.data
}

const updateLearnerleNumberSequence = async (numberSequence) => {
  const { id } = numberSequence
  const response = await api.patch(`${servicePrefix}/learner-number-sequences/${id}`, numberSequence)
  return response.data
}

const deleteLearnerleNumberSequence = async (id) => {
  const response = await api.delete(`${servicePrefix}/learner-number-sequences/${id}`)
  return response.data
}

//get learner license applications
const getOnlineRegistrationList = async (params) => {
  const response = await api.get(`${servicePrefix}/online-learner-applications`, { params: stripEmptyObjects(params) })
  return response.data
}

const updateOnlinePisApplication = async (id) => {
  const response = await api.patch(`${servicePrefix}/pis-applications/${id}`)
  return response.data
}

const getOnlineApplicationById = async (id) => {
  const response = await api.get(`${servicePrefix}/online-learner-applications/${id}`)
  return response.data
}

const verifyOnlineApplication = async (id) => {
  const response = await api.patch(`${servicePrefix}/online-learner-applications/${id}/verify-application`)
  return response.data
}

const approveOnlineApplication = async (id) => {
  const response = await api.patch(`${servicePrefix}/online-learner-applications/${id}/approve-application`)
  return response.data
}

const rejectLearnerOnlineApplication = async (id, payload) => {
  const response = await api.patch(`${servicePrefix}/online-learner-applications/${id}/reject-application`, payload)
  return response.data
}

const updateOnlinePayment = async (paymentInfo) => {
  const { id } = paymentInfo
  const response = await api.patch(`${servicePrefix}/online-learner-applications/${id}/payment`, paymentInfo)
  return response
}

const updateOnlineLearnerLicenseAttachment = async (data) => {
  const pisId = data.get('pisId')
  const response = await api.post(`${servicePrefix}/online-learner-applications/attachments/${pisId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}

const LearnerLicenseService = {
  getLearnerLicenseApplications,
  getLearnerLicenseApplication,
  addLearnerLicenseApplication,
  verifyLearnerLicenseApplication,
  rejectLearnerApplication,
  updateLearnerLicenseApplication,
  driveTypeAll,
  updatePaymentForLearnerLicense,
  getLearnerLicense,
  getLearnerLicenseById,
  getSuspension,
  getCancellation,
  checkLearnerLicenseHolder,
  searchLearnerLicenseHolder,
  getLearnerLicenseRenewal,
  getLearnerLicenseRenewalById,
  addLearnerLicenseRenewal,
  searchLearnerLicenseByLicense,
  updateLearnerLicenseRenewalAttachment,
  updatePaymentForLearnerLicenseRenewal,
  addLearnerLicenseReplacement,
  updatePaymentForLearnerLicenseReplacement,
  updateLearnerLicenseReplacementAttachment,
  getLearnerLicenseReplacementById,
  getLearnerLicenseReplacement,
  approveApplication,
  rejectApplication,
  updatepayment,
  learnerLicenseFeeById,
  getLearnerLicenseSuspension,
  getLearnerLicenseSuspensionById,
  learnerLicenseSuspensionWithdrawal,
  addLearnerLicenseSuspension,
  updateLearnerLicenseSuspensionAttachment,
  getLearnerLicenseCancellation,
  getLearnerLicenseCancellationById,
  addLearnerLicenseCancellation,
  updateLearnerLicenseCancellationAttachment,
  learnerLicenseCancellationWithdrawal,
  updateLearnerLicenseAttachment,
  updateOnlinePayment,
  //Learner Tabs
  getLearnerRenewalByLearnerId,
  getLearnerReplacementByLearnerId,

  //Lerner Number Sequences
  getAllLearnerNumberSequence,
  getLearnerleNumberSequence,
  addLearnerleNumberSequence,
  updateLearnerleNumberSequence,
  deleteLearnerleNumberSequence,
  getOnlineRegistrationList,
  updateOnlinePisApplication,
  getOnlineApplicationById,
  verifyOnlineApplication,
  approveOnlineApplication,
  rejectLearnerOnlineApplication,
  updateOnlineLearnerLicenseAttachment
}
export default LearnerLicenseService

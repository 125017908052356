import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getPost = createAsyncThunk('post/getPost', async ({ cid }) => {
  const response = await axios.get(`https://6228b36f9fd6174ca82c9ff3.mockapi.io/cidFetch`)
  const data = response.data
  const filter = data.filter((item) => item.citizenshipId === cid || item.fullName === cid)
  return { post: filter }
})

//PersonalInformation
export const getPersonalInfo = createAsyncThunk('get/getPersonalInfo', async (search) => {
  try {
    const response = await axios.get(`https://uat.eralis.cloudbhutan.com/personal-information-service/api/personal-informations/info/${search}`, search)
    console.log('success', response)
    return response.data
  } catch (err) {
    console.log(err.response.data.message)
  }
})

const personalInfoSlice = createSlice({
  name: 'posts',
  initialState: {
    post: [],
    personalInfoData: [],
    error: null,
    loading: false
  },
  extraReducers: {
    [getPost.pending]: (state) => {
      state.loading = true
    },
    [getPost.fulfilled]: (state, action) => {
      state.loading = false
      state.post = action.payload.post
    },
    [getPost.error]: (state, action) => {
      state.loading = false
      state.error = action.payload
    },
    [getPersonalInfo.pending]: (state) => {
      state.loading = true
    },
    [getPersonalInfo.fulfilled]: (state, action) => {
      state.loading = false
      state.personalInfoData = action.payload
    }
  }
})

export default personalInfoSlice.reducer

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

import UserService from '@services/user'

export const getAllUsers = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await UserService.getUsers()
  return response.data
})

export const getUsers = createAsyncThunk('appUsers/getUsers', async (params) => {
  // const response = await axios.get('/api/users/list/data', params)
  const response = await UserService.getUsers(params)
  return {
    params,
    data: response.data,
    totalPages: response.meta.itemCount
  }
})

export const getUsersWithRoles = createAsyncThunk('appUsers/getUsersWithRoles', async (params) => {
  const response = await UserService.getUsersWithRoles({ params })
  return {
    total: response.meta.itemCount,
    params,
    // allData: response.data.allData,
    data: response.data
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async (id) => {
  const response = await UserService.getUser(id)
  return response
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { rejectWithValue }) => {
  try {
    const response = await UserService.addUser(user)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const updateUserRole = createAsyncThunk('appUsers/updateRole', async (role, { rejectWithValue }) => {
  try {
    const response = await UserService.updateUserRole(role)
    return response
  } catch (err) {
    console.log(err.response)
    rejectWithValue(err.response.data)
  }
})

export const updateUser = createAsyncThunk('user/updateUser', async (role, { rejectWithValue }) => {
  try {
    const response = await UserService.updateUserIndividual(role)
    return response
  } catch (err) {
    console.log(err.response.data)
    rejectWithValue(err.response.data)
  }
})

export const passwordReset = createAsyncThunk('appUsers/passwordReset', async (password, { rejectWithValue }) => {
  try {
    const response = await UserService.passwordReset(password)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const appUsersSlice = createSlice({
  name: 'users',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null,
    loading: true
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getUsersWithRoles.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
        state.loading = false
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true
      })
  }
})

export default appUsersSlice.reducer

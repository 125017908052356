import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import InsuranceTypeMasterService from '../../../../../services/vehicle-crash/masters/insurance-type'

export const getInsuranceTypes = createAsyncThunk('insuranceType/getEjectionCodes', async (params, { rejectWithValue }) => {
  try {
    const response = await InsuranceTypeMasterService.getInsuranceTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addInsuranceType = createAsyncThunk('insuranceType/addEjectionCode', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await InsuranceTypeMasterService.addInsuranceType(color)
    dispatch(getInsuranceTypes(getState()?.insuranceTypes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateInsuranceType = createAsyncThunk('insuranceType/updateEjectionCode', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await InsuranceTypeMasterService.updateInsuranceType(color)
    dispatch(getInsuranceTypes(getState()?.insuranceTypes.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteInsuranceType = createAsyncThunk('nsuranceType/deleteEjectionCode', async (id, { dispatch, getState }) => {
  await InsuranceTypeMasterService.deleteInsuranceType(id)
  await dispatch(getInsuranceTypes(getState()?.insuranceTypes.params))
  return id
})

const InsuranceTypeSlice = createSlice({
  name: 'insuranceTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getInsuranceTypes.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectInsuranceTypeById = (state, id) => state.insuranceTypes.data.find((insuranceTypes) => insuranceTypes.id === id)

export default InsuranceTypeSlice.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getDamageAreas = async (params) => {
  const response = await api.get(`${servicePrefix}/damage-areas`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllDamageArea = async () => {
  const response = await api.get(`${servicePrefix}/damage-areas/all`)
  return response.data
}

const addDamageArea = async (damageArea) => {
  const response = await api.post(`${servicePrefix}/damage-areas`, damageArea)
  return response.data
}

const updateDamageArea = async (damageArea) => {
  const { id } = damageArea
  const response = await api.patch(`${servicePrefix}/damage-areas/${id}`, damageArea)
  return response.data
}

const deleteDamageArea = async (id) => {
  const response = await api.delete(`${servicePrefix}/damage-areas/${id}`)
  return response.data
}

const DamageAreaMasterService = {
  getDamageAreas,
  getAllDamageArea,
  addDamageArea,
  updateDamageArea,
  deleteDamageArea
}

export default DamageAreaMasterService

import { Grid, Shield, Circle } from 'react-feather'

export default [
  {
    id: 'roles-permissions',
    title: 'Roles & Permissions',
    icon: <Shield size={20} />,
    children: [
      {
        id: 'roles',
        title: 'Roles',
        icon: <Circle size={12} />,
        navLink: '/roles',
        resource: 'Role'
      },
      {
        id: 'permissions',
        title: 'Permissions',
        icon: <Circle size={12} />,
        navLink: '/permissions',
        resource: 'Permission'
      }
    ]
  }
]

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import PersonalInformationService from '@services/personal-information'

export const getPisOrganizations = createAsyncThunk('pisOrganization/getPisOrganizations', async (params, { rejectWithValue }) => {
  const response = await PersonalInformationService.getPisOrganizations(params)
  try {
    return {
      params,
      data: response.data,
      // allData: response.data,
      totalPages: response.meta.itemCount
    }
  } catch (err) {
    console.log(err)
    return rejectWithValue(err.response.data)
  }
})
export const getPisOrganization = createAsyncThunk('pisOrganization/getPisPrganization', async (id) => {
  const response = await PersonalInformationService.getPisOrganization(id)
  return response
})
//add pis Organization
export const addPisOrganization = createAsyncThunk('pisOrganization/addPisOrganization', async (organization, { rejectWithValue }) => {
  try {
    const response = await PersonalInformationService.addPisOrganization(organization)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updatePisOrganization = createAsyncThunk('pisOrganization/updatePisOrganization', async (organization, { rejectWithValue }) => {
  try {
    const response = await PersonalInformationService.updatePisOrganization(organization)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deletePisOrganization = createAsyncThunk('pisOrganization/deletePisOrganization', async (id, { getState, dispatch }) => {
  await PersonalInformationService.deletePisOrganization(id)
  await dispatch(getPisOrganizations(getState().params))
  await dispatch(getPisOrganizations())
  return id
})
export const pisOrganizationSlice = createSlice({
  name: 'pisOrganization',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedOrganization: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPisOrganizations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getPisOrganization.fulfilled, (state, action) => {
        state.selectedOrganization = action.payload
      })
  }
})

export default pisOrganizationSlice.reducer

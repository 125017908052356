import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import LocationTypeMasterService from '../../../../../services/vehicle-crash/masters/location-type'

export const getLocationTypes = createAsyncThunk('locationTypes/getLocationTypes', async (params, { rejectWithValue }) => {
  try {
    const response = await LocationTypeMasterService.getLocationTypes(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addLocationType = createAsyncThunk('locationTypes/addLocationType', async (locationType, { rejectWithValue, dispatch, getState }) => {
  try {
    await LocationTypeMasterService.addLocationType(locationType)
    dispatch(getLocationTypes(getState()?.locationTypes.params))
    return locationType
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateLocationType = createAsyncThunk('locationTypes/updateLocationType', async (locationType, { rejectWithValue, dispatch, getState }) => {
  try {
    await LocationTypeMasterService.updateLocationType(locationType)
    dispatch(getLocationTypes(getState()?.locationTypes.params))
    return locationType
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteLocationType = createAsyncThunk('locationTypes/deleteLocationType', async (id, { dispatch, getState }) => {
  await LocationTypeMasterService.deleteLocationType(id)
  await dispatch(getLocationTypes(getState()?.locationTypes.params))
  return id
})

const locationTypeSlice = createSlice({
  name: 'locationTypes',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getLocationTypes.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectLocationTypeById = (state, id) => state.locationTypes.data.find((locationTypes) => locationTypes.id === id)

export default locationTypeSlice.reducer

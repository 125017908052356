import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/masters/vehicle-master'

export const getAllVehicNumberSequence = createAsyncThunk('number-sequence/getAllVehicNumberSequence', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getAllVehicNumberSequence(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const getVehicleNumberSequence = createAsyncThunk('number-sequence/getVehicleNumberSequence', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getVehicleNumberSequence(id)
    // console.log('response', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response)
  }
})

export const addVehicleNumberSequence = createAsyncThunk('number-sequence/addVehicleNumberSequence', async (numberSequence, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleNumberSequence(numberSequence)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateVehicleNumberSequence = createAsyncThunk('number-sequence/updateVehicleNumberSequence', async (numberSequence, { rejectWithValue }) => {
  try {
    const response = await VehicleService.updateVehicleNumberSequence(numberSequence)
    // dispatch(getAllVehicNumberSequence(getState()?.number-sequences.params))
    return response
  } catch (err) {
    rejectWithValue(err.response.data)
  }
})

export const deleteVehicleNumberSequence = createAsyncThunk('number-sequence/deleteVehicleNumberSequence', async (id, { dispatch, getState }) => {
  await VehicleService.deleteVehicleNumberSequence(id)
  await dispatch(getAllVehicNumberSequence(getState()?.vehicleNumberSequences.params))
  return id
})

const numberSequenceSlice = createSlice({
  name: 'vehicle_number_sequence',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    individualVehicleNumberSequence: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllVehicNumberSequence.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.individualVehicleNumberSequence = null
      })
      .addCase(getVehicleNumberSequence.fulfilled, (state, action) => {
        state.individualVehicleNumberSequence = action.payload
      })

      .addCase(updateVehicleNumberSequence.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          // console.log('Update could not complete')
          // console.log(action.payload)
          return
        }
        const { id } = action.payload
        const sequence = state.data.filter((sequence) => sequence.id !== id)
        state.data = [...sequence, action.payload]
      })
  }
})

export default numberSequenceSlice.reducer

// ** Icons Import
import { Circle, Clipboard, Loader } from 'react-feather'

export default [
  {
    id: 'learner-license',
    title: 'Learner License',
    icon: <Clipboard size={20} />,
    children: [
      {
        id: 'learner-license-list',
        title: 'Learner History',
        icon: <Circle size={20} />,
        navLink: '/license/learner-license/learner-license-list',
        parentOf: ['/license/learner-license/learner-license-list/learner-details'],
        action: 'read',
        resource: 'LearnerLicense'
      },
      {
        id: 'pending-applicaiton',
        title: 'Pending Application',
        icon: <Circle size={20} />,
        resource: 'LearnerLicense',
        children: [
          {
            id: 'online-registration',
            title: 'Registration',
            icon: <Circle size={20} />,
            navLink: '/license/learner-license/registration',
            resource: 'LearnerLicense'
          },
          {
            id: 'learner-renewal',
            title: 'Renewal',
            icon: <Circle size={20} />,
            navLink: '/license/learner-license/renewal',
            resource: 'LearnerLicenseRenewal'
          },
          {
            id: 'learner-replacement',
            title: 'Replacement',
            icon: <Circle size={20} />,
            navLink: '/license/learner-license/replacement',
            resource: 'LearnerLicenseReplacement'
          }
        ]
      },
      {
        id: 'learner-licenses',
        title: 'New Application',
        icon: <Circle size={20} />,
        navLink: '/license/learner-license/application',
        resource: 'LearnerLicense'
      },

      {
        id: 'learner-license-renewal',
        title: 'Renewal',
        icon: <Circle size={20} />,
        navLink: '/license/learner-license/learner-license-renewal/add',
        resource: 'LearnerLicenseRenewal'
      },
      {
        id: 'learner-license-replacement',
        title: 'Replacement',
        icon: <Circle size={20} />,
        navLink: '/license/learner-license/learner-license-replacement/add',
        resource: 'LearnerLicenseReplacement'
      },
      {
        id: 'learner-license-suspension',
        title: 'Suspension',
        icon: <Circle size={20} />,
        navLink: '/license/learner-license/suspension/list',
        resource: 'LearnerLicense'
      },
      {
        id: 'learner-license-cancellation',
        title: 'Cancellation',
        icon: <Circle size={20} />,
        navLink: '/license/learner-license/cancellation/list',
        resource: 'LearnerLicense'
      }
    ]
  }
]

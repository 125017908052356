// ** React Imports
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'

const UserList = lazy(() => import('../../views/user/list'))
const UserView = lazy(() => import('../../views/user/view'))
const UserEdit = lazy(() => import('../../views/user/edit/user/index'))
const UserRoleUpdate = lazy(() => import('../../views/user/edit/role/index'))

const Roles = lazy(() => import('../../views/roles-permissions/roles'))
const EditRole = lazy(() => import('../../views/roles-permissions/roles/EditRole'))

const Permissions = lazy(() => import('../../views/roles-permissions/permissions'))
const PermissionDetailss = lazy(() => import('../../views/roles-permissions/permissions/PermissionDetails'))
const PasswordReset = lazy(() => import('../../views/user/reset-password/PasswordReset'))

const UserRoutes = [
  {
    element: <UserList />,
    path: '/user/list'
  },
  {
    path: '/user/list/view/:id/edit',
    element: <UserEdit />
  },
  {
    path: '/user/list/view/:id/edit',
    element: <UserEdit />
  },
  {
    element: <UserRoleUpdate />,
    path: '/user/list/view/:id/user-role-update'
  },
  {
    element: <UserView />,
    path: '/user/list/view/:id'
  },
  {
    element: <UserRoleUpdate />,
    path: '/user/list/view/:id/user-role-update'
  },
  {
    element: <PasswordReset />,
    path: '/user/change-password',
    meta: {
      action: 'dashbaord',
      resource: 'Dashboard'
    }
  },
  {
    element: <Roles />,
    path: '/roles'
  },
  {
    element: <EditRole />,
    path: '/roles/view/:id/edit'
  },
  {
    element: <Permissions />,
    path: '/permissions'
  },
  {
    element: <PermissionDetailss />,
    path: '/permissions/view/:id'
  }
]

export default UserRoutes

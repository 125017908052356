// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getAllDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-endorsement-application/getAllDrivingLicenseEndorsementApplication', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicenseEndorsementApplication(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-endorsement-application/getDrivingLicenseEndorsementApplication', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseEndorsementApplication(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-endorsement-application/addDrivingLicenseApplication', async (application, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addDrivingLicenseEndorsementApplication(application)
    return response
    // dispatch(getAllDrivingLicenseApplication(getState()?.drivingLicenseEndorsementApplications.params))
    // return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseEndorsementApplicationAttachment = createAsyncThunk('driving-license-endorsement-application/updateDrivingLicenseEndorsementApplicationAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateDrivingLicenseEndorsementApplicationAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-application/updateDrivingLicenseApplication', async (application, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseEndorsementApplication(application)
    return response
    // dispatch(getAllDrivingLicenseEndorsementApplication(getState()?.drivingLicenseEndorsementApplications.params))
    // return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const drivingLicenseApplicationEndorsementPayment = createAsyncThunk('driving-license-endorsement-application/drivingLicenseEndorsementApplicationPayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseEndorsementApplicationPayment(payment)
    // console.log('conversion response', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-endorsement-application/deleteDrivingLicenseEndorsementApplication', async (id, { dispatch, getState }) => {
  await DrivingLicenseService.deleteDrivingLicenseEndorsementApplication(id)
  await dispatch(getAllDrivingLicenseEndorsementApplication(getState()?.drivingLicenseEndorsementApplications.params))
  return id
})

const DrivingLicenseEndorsement = createSlice({
  name: 'endorsement_application',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedDrivingLicenseEndorsementApplication: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllDrivingLicenseEndorsementApplication.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingLicenseEndorsementApplication = null
      })
      .addCase(getDrivingLicenseEndorsementApplication.fulfilled, (state, action) => {
        state.selectedDrivingLicenseEndorsementApplication = action.payload
      })
      .addCase(updateDrivingLicenseEndorsementApplication.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const endorsementApplication = state.data.filter((endorsementApplication) => endorsementApplication.id !== id)
        state.data = [...endorsementApplication, action.payload]
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default DrivingLicenseEndorsement.reducer

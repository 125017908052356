// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingTestService from '@services/driving-test'

export const getDrivingTestConfigurations = createAsyncThunk('drivingTestConfigurations/getDrivingTestConfigurations', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingTestService.getDrivingTestConfigurations(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addDrivingTestConfiguration = createAsyncThunk('drivingTestConfigurations/addDrivingTestConfiguration', async (data, { rejectWithValue }) => {
  // await axios.post('/apps/users/add-user', user)

  try {
    const response = await DrivingTestService.addDrivingTestConfiguration(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingTestConfiguration = createAsyncThunk('drivingTestConfigurations/updateDrivingTestConfiguration', async (data, { rejectWithValue }) => {
  try {
    const { id } = data
    console.log('hihi', id)
    const response = await DrivingTestService.updateDrivingTestConfiguration(id, data)

    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getTestConfiguration = createAsyncThunk('drivingTestConfigurations/drivingTestConfiguration', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingTestService.getDrivingTestConfigurationWithBookings(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

const DrivingTestConfiguration = createSlice({
  name: 'drivingTestConfiguration',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    loading: true,
    errors: null,
    selectedDrivingTestConfiguration: null,
    candidates: []
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getDrivingTestConfigurations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedDrivingTestConfiguration = null
      })
      .addCase(getTestConfiguration.pending, (state) => {
        state.selectedDrivingTestConfiguration = null
        state.loading = true
      })
      .addCase(getTestConfiguration.fulfilled, (state, action) => {
        state.loading = false
        state.errors = null
        state.selectedDrivingTestConfiguration = action.payload
      })
      .addCase(getTestConfiguration.rejected, (state, action) => {
        state.selectedDrivingTestConfiguration = null
        state.errors = action.payload
        state.loading = false
      })

      .addCase(updateDrivingTestConfiguration.pending, (state) => {
        state.selectedDrivingTestConfiguration = null
        state.loading = true
      })
      .addCase(updateDrivingTestConfiguration.fulfilled, (state, action) => {
        state.loading = false
        state.errors = null
        state.selectedDrivingTestConfiguration = action.payload
      })
      .addCase(updateDrivingTestConfiguration.rejected, (state, action) => {
        state.selectedDrivingTestConfiguration = null
        state.errors = action.payload
        state.loading = false
      })
  }
})

// export const getAllOfficeLocation = (state) => state.officeLocations.office_location
// export const selectOfficeLocationById = (state, id) => state.officeLocations.data.find((office_location) => office_location.id === id)

export default DrivingTestConfiguration.reducer

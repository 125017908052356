import api from '@services'
import { stripEmptyObjects } from '@utils'

const serviceBaseUrl = process.env.REACT_APP_PIS_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/personal-information/api`

//get pis type of individuals
const getPisIndividuals = async (params) => {
  const response = await api.get(`${servicePrefix}/personal-informations`, { params: stripEmptyObjects(params) })
  return response.data
}

//get one pis type of individual
const getPisIndividual = async (id) => {
  const response = await api.get(`${servicePrefix}/personal-informations/${id}`)
  return response.data
}

//add pis type of individual
const addPisIndividual = async (individualInfo) => {
  const response = await api.post(`${servicePrefix}/personal-informations`, individualInfo)
  return response.data
}

//TODO refractor to work with patch after server api changes
const updatePisIndividual = async (id, individualInfo) => {
  const response = await api.patch(`${servicePrefix}/personal-informations/${id}`, stripEmptyObjects(individualInfo))
  return response.data
}

//search pis type of individual
const searchPisIndividual = async (search) => {
  const response = await api.get(`${servicePrefix}/personal-informations`, search)
  return response.data
}

//search personalInfo
const searchPersonalInfo = async (search) => {
  const response = await api.get(`${servicePrefix}/personal-informations/info/${encodeURIComponent(search)}`)
  return response.data
}

//get all countries
const getAllCountries = async () => {
  const response = await api.get(`${servicePrefix}/countries/all`)
  return response.data
}

// get all permanent-addresses
const getAllPermenantAddress = async () => {
  const response = await api.get(`${servicePrefix}/permanent-addresses`)
  return response.data
}
//add personalInfo
const addPersonalInfo = async (personalInfo) => {
  const response = await api.post(`${servicePrefix}/personal-informations`, personalInfo)
  return response.data
}
//add permenant Address
const addPermenantAddress = async (permenantAddress) => {
  const response = await api.post(`${servicePrefix}/permanent-addresses`, permenantAddress)
  return response.data
}
//update permanent Address
const updatePermanentAddress = async (id, permanentAddress) => {
  const response = await api.patch(`${servicePrefix}/permanent-addresses/${id}`, stripEmptyObjects(permanentAddress))
  return response.data
}

//update permanent Address
const updatePresentAddress = async (id, presentAddress) => {
  const response = await api.patch(`${servicePrefix}/present-addresses/${id}`, stripEmptyObjects(presentAddress))
  return response.data
}

//add present Address
const addPresentAddress = async (presentAddress) => {
  const response = await api.post(`${servicePrefix}/present-addresses`, presentAddress)
  return response.data
}
//get all Gewogs
const getAllGewogs = async () => {
  const response = await api.get(`${servicePrefix}/gewogs/all`)
  return response.data
}
//get all dzongkhags with gewogs
const getAllDzongkhags = async () => {
  const response = await api.get(`${servicePrefix}/dzongkhags/all`)
  return response.data
}

//add permenant Address
const addPisIndividualPermanentAddress = async (permanentAddress) => {
  const response = await api.post(`${servicePrefix}/permanent-addresses`, permanentAddress)
  return response.data
}

//add permenant Address
const addPisIndividualPresentAddress = async (presentAddress) => {
  const response = await api.post(`${servicePrefix}/present-addresses`, presentAddress)
  return response.data
}

//get root categories of organization categories
const getRootCategories = async () => {
  const response = await api.get(`${servicePrefix}/organization-categories/root-categories`)
  return response.data
}

//get organization category detail by category ID
const getPisOrganizationCategory = async (id) => {
  const response = await api.get(`${servicePrefix}/organization-categories/${id}`)
  return response.data
}

//get pis type of organizations
const getPisOrganizations = async (params) => {
  const response = await api.get(`${servicePrefix}/organizations`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllOrganization = async () => {
  const response = await api.get(`${servicePrefix}/organizations/all`)
  return response.data
}

//get pis type of organization
const getPisOrganization = async (id) => {
  const response = await api.get(`${servicePrefix}/organizations/${id}`)
  return response.data
}

// update organization
const updatePisOrganization = async (organization) => {
  const { id } = organization
  const response = await api.patch(`${servicePrefix}/organizations/${id}`, organization)
  return response.data
}

//add pis Organization
const addPisOrganization = async (organization) => {
  const response = await api.post(`${servicePrefix}/organizations`, organization)
  return response.data
}

//search organization info
const searchOrganizationInfo = async (search) => {
  const response = await api.get(`${servicePrefix}/organizations/search-organization/${search}`)
  return response
}
//delete pis type of organization
const deletePisOrganization = async (id) => {
  const response = await api.delete(`${servicePrefix}/organizations/${id}`)
  return response
}

//avatar for Pis Individual
const uploadAvatarForPisIndividual = async (id, data) => {
  const response = await api.post(`${servicePrefix}/personal-informations/${id}/avatar`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

//Document for Pis Individual
const documentForPisIndividual = async (id, data) => {
  const response = await api.post(`${servicePrefix}/personal-informations/${id}/document`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })

  return response.data
}

//

const fetchCitizenDetailByCid = async (cid) => {
  const response = await api.get(`${servicePrefix}/personal-informations/fetch-citizen-details-from-data-hub/${cid}`)
  return response.data
}

const PersonalInformationService = {
  updatePisOrganization,
  uploadAvatarForPisIndividual,
  documentForPisIndividual,
  getPisIndividuals,
  getPisIndividual,
  addPisIndividual,
  updatePisIndividual,
  addPisIndividualPermanentAddress,
  updatePermanentAddress,
  updatePresentAddress,
  addPisIndividualPresentAddress,
  searchPisIndividual,
  searchPersonalInfo,
  getAllPermenantAddress,
  addPresentAddress,
  getAllCountries,
  addPersonalInfo,
  addPermenantAddress,
  getAllDzongkhags,
  getAllGewogs,
  //personal information to get all organization
  getRootCategories,
  getPisOrganizationCategory,
  getPisOrganizations,
  searchOrganizationInfo,
  getPisOrganization,
  getAllOrganization,
  deletePisOrganization,
  addPisOrganization,
  fetchCitizenDetailByCid
}

export default PersonalInformationService

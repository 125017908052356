// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import DrivingLicenseService from '@services/driving-license'

export const getAllDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-endorsement-application/getAllDrivingLicenseEndorsementApplication', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllDrivingLicenseEndorsementApplication(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getAllEndorsementCancellations = createAsyncThunk('endorsement-cancellation/getAllEndorsementCancellations', async (params, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getAllEndorsementCancellations(params)
    // console.log('res', response)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-endorsement-application/getDrivingLicenseEndorsementApplication', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getDrivingLicenseEndorsementApplication(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getEndorsementCancellations = createAsyncThunk('endorsement-cancellation/getEndorsementCancellations', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getEndorsementCancellations(id)
    // console.log('reason', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getEndorsementCancellation = createAsyncThunk('endorsement-cancellation/getEndorsementCancellation', async (id, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.getEndorsementCancellation(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addEndorsementCancellation = createAsyncThunk('endorsement-cancellation/addEndorsementCancellation', async (application, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.addEndorsementCancellation(application)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateEndorsementCancellationAttachment = createAsyncThunk('endorsement-cancellation/updateEndorsementCancellationAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await DrivingLicenseService.updateEndorsementCancellationAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-application/updateDrivingLicenseApplication', async (application, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseEndorsementApplication(application)
    return response
    // dispatch(getAllDrivingLicenseEndorsementApplication(getState()?.drivingLicenseEndorsementApplications.params))
    // return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const drivingLicenseApplicationEndorsementPayment = createAsyncThunk('driving-license-endorsement-application/drivingLicenseEndorsementApplicationPayment', async (payment, { rejectWithValue }) => {
  try {
    const response = await DrivingLicenseService.updateDrivingLicenseEndorsementApplicationPayment(payment)
    // console.log('conversion response', response)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteDrivingLicenseEndorsementApplication = createAsyncThunk('driving-license-endorsement-application/deleteDrivingLicenseEndorsementApplication', async (id, { dispatch, getState }) => {
  await DrivingLicenseService.deleteDrivingLicenseEndorsementApplication(id)
  await dispatch(getAllDrivingLicenseEndorsementApplication(getState()?.drivingLicenseEndorsementApplications.params))
  return id
})

const EndorsementCancellation = createSlice({
  name: 'endorsement_cancellation',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedEndorsementCancellation: null,
    selectedDrivingLicenseEndorsementApplication: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllEndorsementCancellations.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedEndorsementCancellation = null
      })
      .addCase(getDrivingLicenseEndorsementApplication.fulfilled, (state, action) => {
        state.selectedDrivingLicenseEndorsementApplication = action.payload
      })
      .addCase(getEndorsementCancellation.fulfilled, (state, action) => {
        state.selectedEndorsementCancellation = action.payload
      })
      .addCase(updateDrivingLicenseEndorsementApplication.fulfilled, (state, action) => {
        if (!action.payload?.id) {
          console.log('Update could not complete')
          console.log(action.payload)
          return
        }
        const { id } = action.payload
        const endorsementApplication = state.data.filter((endorsementApplication) => endorsementApplication.id !== id)
        state.data = [...endorsementApplication, action.payload]
      })
  }
})

export default EndorsementCancellation.reducer

import api from '@services'
import { stripEmptyObjects } from '@utils'
const serviceBaseUrl = process.env.REACT_APP_VEHICLE_CRASH_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle-crash/api`

const getLocationTypes = async (params) => {
  const response = await api.get(`${servicePrefix}/location-type`, { params: stripEmptyObjects(params) })
  return response.data
}

const getAllLocationType = async (params) => {
  const response = await api.get(`${servicePrefix}/location-type/all`, { params: stripEmptyObjects(params) })
  return response.data
}

const addLocationType = async (locationType) => {
  const response = await api.post(`${servicePrefix}/location-type`, locationType)
  return response.data
}

const updateLocationType = async (locationType) => {
  const { id } = locationType
  const response = await api.patch(`${servicePrefix}/location-type/${id}`, locationType)
  return response.data
}

const deleteLocationType = async (id) => {
  const response = await api.delete(`${servicePrefix}/location-type/${id}`)
  return response.data
}

const LocationTypeMasterService = {
  getLocationTypes,
  getAllLocationType,
  addLocationType,
  updateLocationType,
  deleteLocationType
}

export default LocationTypeMasterService

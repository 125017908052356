// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import PermissionService from '@services/permission'
export const getPermissions = createAsyncThunk('permissions/getPermissions', async (params) => {
  const response = await PermissionService.getPermissions(params)
  return {
    total: response.meta.itemCount,
    params,
    // allData: response.data.allData,
    data: response.data
  }
})

export const getAllPermissions = createAsyncThunk('permissions/getAllPermissions', async (params = {}) => {
  const response = await PermissionService.getPermissions(params)

  return {
    allData: response.data
  }
})

export const addPermission = createAsyncThunk('permission/addPermission', async (permission, { rejectWithValue }) => {
  try {
    const response = await PermissionService.addPermission(permission)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updatePermission = createAsyncThunk('permissions/updatePermission', async ({ id, name }, { dispatch, getState }) => {
  await axios.post('/apps/permissions/update-permission', { id, name })
  await dispatch(getPermissions(getState().permissions.params))
  return { id, name }
})

export const deletePermission = createAsyncThunk('permissions/deletePermission', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/permissions/delete', { id })
  await dispatch(getPermissions(getState().permissions.params))
  return id
})

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selected: null
  },
  reducers: {
    selectPermission: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPermissions.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.total = action.payload.total
        state.params = action.payload.params
        // state.allData = action.payload.allData
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.allData = action.payload.allData
      })
  }
})

export const { selectPermission } = permissionsSlice.actions

export default permissionsSlice.reducer

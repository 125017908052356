import { Truck, Circle } from 'react-feather'

export default [
  {
    id: 'payment',
    title: 'Payments',
    icon: <Circle size={12} />,
    navLink: '/payments'
  }
]

// ** Icons Import
import { Home, Circle } from 'react-feather'

export default [
  {
    id: 'dashboards',
    title: 'Dashboards',
    icon: <Home size={20} />,
    navLink: '/dashboard',
    badge: 'light-warning',
    resource: 'Dashboard'
  }
]

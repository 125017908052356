import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import NonMotoristLocationMasterService from '../../../../../services/vehicle-crash/masters/non-motorist-location'

export const getNonMotoristLocations = createAsyncThunk('nonMotoristLocation/getNonMotoristLocations', async (params, { rejectWithValue }) => {
  try {
    const response = await NonMotoristLocationMasterService.getNonMotoristLocations(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addNonMotoristLocation = createAsyncThunk('nonMotoristLocation/addNonMotoristLocation', async (nonMotoristLocation, { rejectWithValue, dispatch, getState }) => {
  try {
    await NonMotoristLocationMasterService.addNonMotoristLocation(nonMotoristLocation)
    dispatch(getNonMotoristLocations(getState()?.nonMotoristLocations.params))
    return nonMotoristLocation
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateNonMotoristLocation = createAsyncThunk('nonMotoristLocation/updateNonMotoristLocation', async (nonMotoristLocation, { rejectWithValue, dispatch, getState }) => {
  try {
    await NonMotoristLocationMasterService.updateNonMotoristLocation(nonMotoristLocation)
    dispatch(getNonMotoristLocations(getState()?.nonMotoristLocations.params))
    return nonMotoristLocation
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteNonMotoristLocation = createAsyncThunk('nonMotoristLocation/deleteNonMotoristLocation', async (id, { dispatch, getState }) => {
  await NonMotoristLocationMasterService.deleteNonMotoristLocation(id)
  await dispatch(getNonMotoristLocations(getState()?.nonMotoristLocations.params))
  return id
})

const NonMotoristLocationSlice = createSlice({
  name: 'nonMotoristLocations',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getNonMotoristLocations.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectNonMotoristLocationById = (state, id) => state.nonMotoristLocations.data.find((nonMotoristLocations) => nonMotoristLocations.id === id)

export default NonMotoristLocationSlice.reducer

import { lazy } from 'react'

const LearnerLicenseApplicationList = lazy(() => import('../../views/learner-license/application/list'))
const LearnerLicenseIndividualView = lazy(() => import('../../views/learner-license/application/view/index'))
const LearnerLicenseServices = lazy(() => import('../../views/learner-license/services/index'))
const LearnerLicensePisSearch = lazy(() => import('../../views/learner-license/application/add'))
const AddLearnerLicenseDetails = lazy(() => import('../../views/learner-license/application/add/LicenseDetails'))
const AttachmentForLearnerLicenseApplication = lazy(() => import('../../views/learner-license/application/view/Attachment/index'))
const AddAttachment = lazy(() => import('../../views/learner-license/application/add/Attachment'))
const LearnerLicenseList = lazy(() => import('../../views/learner-license/learner-license/list/index'))
const LearnerLicenseView = lazy(() => import('../../views/learner-license/learner-license/view/index'))
const LearnerLicenseEdit = lazy(() => import('../../views/learner-license/application/edit/index'))
const LearnerLicenseAttachmentEdit = lazy(() => import('../../views/learner-license/application/edit/Attachment'))
const LearnerLicenseRegistrationList = lazy(() => import('../../views/learner-license/learner-license-registration/list/index'))
const LearnerLicenseOnlineById = lazy(() => import('../../views/learner-license/learner-license-registration/view/index'))
const LearnerLicenseOnlineApplicationEdit = lazy(() => import('../../views/learner-license/learner-license-registration/edit/index'))
const AddAttachmentForm = lazy(() => import('../../views/learner-license/learner-license-registration/view/AddAttachment'))

// Renewal
const LearnerLicenseRenewal = lazy(() => import('../../views/learner-license/learner-license-renewal/add/index'))
const LearnerLicenseRenewalDuration = lazy(() => import('../../views/learner-license/learner-license-renewal/add/LearnerLicenseRenewalDuration'))
const LearnerLicenseRenewalPayment = lazy(() => import('../../views/learner-license/learner-license-renewal/add/LearnerLicensePaymentUpdate'))
const LearnerLicenseRenewalAttachments = lazy(() => import('../../views/learner-license/learner-license-renewal/add/Attachment'))
const LearnerLicenseRenewalList = lazy(() => import('../../views/learner-license/learner-license-renewal/list/index'))
const LearnerLicenseRenewalView = lazy(() => import('../../views/learner-license/learner-license-renewal/view/index'))

//Replacement
const LearnerLicenseReplacement = lazy(() => import('../../views/learner-license/learner-license-replacement/add/index'))
const LearnerLicenseReplacementPayment = lazy(() => import('../../views/learner-license/learner-license-replacement/add/LearnerLicensePaymentUpdate'))
const LearnerLicenseReplacementAttachments = lazy(() => import('../../views/learner-license/learner-license-replacement/add/Attachment'))
const LearnerLicenseReplacementList = lazy(() => import('../../views/learner-license/learner-license-replacement/list/index'))
const LearnerLicenseReplacementView = lazy(() => import('../../views/learner-license/learner-license-replacement/view/index'))

// Certificate
const LearnerLicenseCertificate = lazy(() => import('../../views/learner-license/learner-license/learnerLicenseCertificate/index'))

//Suspension
const LearnerSuspensionList = lazy(() => import('../../views/learner-license/learner-license-suspension/list/index'))
const LearnerLicenseSuspensionAdd = lazy(() => import('../../views/learner-license/learner-license-suspension/add/index'))
const LearnerLicenseSuspensionAddDetails = lazy(() => import('../../views/learner-license/learner-license-suspension/add/SuspensionDetails'))
const LearnerLicenseSuspensionDetails = lazy(() => import('../../views/learner-license/learner-license-suspension/view/index'))
const LearnerLicenseSuspensionAttachments = lazy(() => import('../../views/learner-license/learner-license-suspension/add/Attachment'))
const LearnerLicenseSuspensionWithdrawal = lazy(() => import('../../views/learner-license/learner-license-suspension/view/withdrawal'))

// Cancellation
const LearnerCancellationList = lazy(() => import('../../views/learner-license/learner-license-cancellation/list/index'))
const LearnerLicenseCancellationAdd = lazy(() => import('../../views/learner-license/learner-license-cancellation/add/index'))
const AddLearnerLicenseCancellationDetails = lazy(() => import('../../views/learner-license/learner-license-cancellation/add/CancellationDetails'))
const AddLearnerLicenseCancellationAttachments = lazy(() => import('../../views/learner-license/learner-license-cancellation/add/Attachment'))
const LearnerLicenseCancellationDetails = lazy(() => import('../../views/learner-license/learner-license-cancellation/view/index'))
const LearnerLicenseCancellationWithdrawal = lazy(() => import('../../views/learner-license/learner-license-cancellation/view/withdrawal'))

const LearnerLicenseRoutes = [
  {
    path: '/license/learner-license/application',
    element: <LearnerLicenseApplicationList />,
    meta: {
      action: 'read',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/application/:id',
    element: <LearnerLicenseIndividualView />,
    meta: {
      action: 'read',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/application/:id/edit',
    element: <LearnerLicenseEdit />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/application/:id/edit-attachment',
    element: <LearnerLicenseAttachmentEdit />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/details/:id/attachments',
    element: <AttachmentForLearnerLicenseApplication />,
    meta: {
      action: 'read',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/service',
    element: <LearnerLicenseServices />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/application/add',
    element: <LearnerLicensePisSearch />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/application/add/:id/license-details',
    element: <AddLearnerLicenseDetails />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/application/add/:id/attachment-upload',
    element: <AddAttachment />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-list',
    element: <LearnerLicenseList />,
    meta: {
      action: 'read',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-list/learner-details/:id',
    element: <LearnerLicenseView />,
    meta: {
      action: 'read',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/renewal',
    element: <LearnerLicenseRenewalList />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/registration',
    element: <LearnerLicenseRegistrationList />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/registration/view/:id',
    element: <LearnerLicenseOnlineById />,
    meta: {
      action: 'read',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/registration/:id/edit',
    element: <LearnerLicenseOnlineApplicationEdit />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/registration/:id/attachment-upload/:pisId',
    element: <AddAttachmentForm />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-renewal/add',
    element: <LearnerLicenseRenewal />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/renewal/view/:id',
    element: <LearnerLicenseRenewalView />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-renewal/add/:id/learner-license-duration',
    element: <LearnerLicenseRenewalDuration />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-renewal/add/:id/payment',
    element: <LearnerLicenseRenewalPayment />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-renewal/add/:id/attachments',
    element: <LearnerLicenseRenewalAttachments />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/replacement',
    element: <LearnerLicenseReplacementList />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/replacement/view/:id',
    element: <LearnerLicenseReplacementView />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-replacement/add',
    element: <LearnerLicenseReplacement />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-replacement/add/:id/payment',
    element: <LearnerLicenseReplacementPayment />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-replacement/add/:id/attachments',
    element: <LearnerLicenseReplacementAttachments />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-list/learner-details/:id/print-learner-license-certificate',
    element: <LearnerLicenseCertificate />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/suspension/list',
    element: <LearnerSuspensionList />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-suspension/add',
    element: <LearnerLicenseSuspensionAdd />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-suspension/add/:id/suspension-details',
    element: <LearnerLicenseSuspensionAddDetails />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/suspension/:id/view',
    element: <LearnerLicenseSuspensionDetails />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/suspension/:id/withdraw',
    element: <LearnerLicenseSuspensionWithdrawal />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/learner-license-suspension/add/:id/attachment',
    element: <LearnerLicenseSuspensionAttachments />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },

  {
    path: '/license/learner-license/cancellation/list',
    element: <LearnerCancellationList />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/cancellation/add',
    element: <LearnerLicenseCancellationAdd />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/cancellation/add/:learnerId/details',
    element: <AddLearnerLicenseCancellationDetails />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/cancellation/add/:id/attachment',
    element: <AddLearnerLicenseCancellationAttachments />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/cancellation/:id/view',
    element: <LearnerLicenseCancellationDetails />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  },
  {
    path: '/license/learner-license/cancellation/:id/withdraw',
    element: <LearnerLicenseCancellationWithdrawal />,
    meta: {
      action: 'manage',
      resource: 'LearnerLicense'
    }
  }
]

export default LearnerLicenseRoutes

import mock from '../../mock'

// ** Utils
import { paginateArray } from '../../utils'

const data = {
  vehicles: [
    {
      id: 1,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    },
    {
      id: 2,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 3,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 4,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 5,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 6,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 7,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 8,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 9,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 10,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 11,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 12,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    },   
    {
      id: 13,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 14,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 15,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 16,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 17,
      cidNumber: 10101001821,
      customerName: 'Sonam Dendup',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }, 
    {
      id: 18,
      cidNumber: 10101001821,
      customerName: 'Kezang Thinley',
      gender: 'M',
      presentPhoneNumber: 17422256,
      vehicleNumber: 'BT-1-A7409',
      vehicleTypeName: 'Taxi',
      vehicleModelName: 'BYD Electric Car M3 ANDOTHERS',
      vehicleCompanyName: 'BYD',
      engineCC: 40,
      enginNumber: 'Nill000',
      chassisNumber: 'LCOCE4DB1K0042042',
      engineName:'Electric',
      countryName: 'China',
      dealerName: 'Dhejung Motors',
      price:2268000.00,
      colour:'red',
      description: 'NEW',
      registrationDate: '26/07/2021',
      amountPaid:4550,
      penaltyPaid:0.00,
      receiptNo:'RC452183624966',
      receiptDate:'26/07/2021',
      regionName: 'Thimphu',
      baseOfficeName:'Wangduephodrang Base',
      remarks: 'New Registration from Wangdue'
    }
  ]
}

// ------------------------------------------------
// GET: Return Invoice List
// ------------------------------------------------
mock.onGet('/eralis/vehicle/vehicleList/vehicles').reply((config) => {
  // eslint-disable-next-line object-curly-newline
  // const { q = '', perPage = 10, page = 1, status = null, sort, sortColumn } = config
   const { q = '', perPage = 10, page = 1, sort, sortColumn } = config
  /* eslint-enable */

  const dataAsc = data.vehicles.sort((a, b) => {
    if (a[sortColumn]) {
      return a[sortColumn] < b[sortColumn] ? -1 : 1
    } else {
      const splitColumn = sortColumn.split('.')
      const columnA = a[splitColumn[0]][splitColumn[1]]
      const columnB = b[splitColumn[0]][splitColumn[1]]
      return columnA < columnB ? -1 : 1
    }
  })

  const dataToFilter = sort === 'asc' ? dataAsc.reverse() : dataAsc

  const queryLowered = q.toLowerCase()
  const filteredData = dataToFilter.filter((list) => {
    // if (String('paid').includes(queryLowered) && invoice.balance === 0) {
    //   return invoice.balance === 0
    // } else {
    //   /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
    //   return (invoice.client.companyEmail.toLowerCase().includes(queryLowered) || invoice.client.name.toLowerCase().includes(queryLowered) || String(invoice.id).toLowerCase().includes(queryLowered) || String(invoice.total).toLowerCase().includes(queryLowered) || String(invoice.balance).toLowerCase().includes(queryLowered) || invoice.dueDate.toLowerCase().includes(queryLowered)) && invoice.invoiceStatus.toLowerCase() === (status.toLowerCase() || invoice.invoiceStatus.toLowerCase())
    // }
    return (list.customerName.toLowerCase().includes(queryLowered) || String(list.id).toLowerCase().includes(queryLowered))
  })
  /* eslint-enable  */

  return [
    200,
    {
      allData: data.vehicles,
      total: filteredData.length,
      vehicles: filteredData.length <= perPage ? filteredData : paginateArray(filteredData, perPage, page)
    }
  ]
})

// ------------------------------------------------
// GET: Return Single Invoice
// ------------------------------------------------
// mock.onGet(/\/api\/invoice\/invoices\/\d+/).reply((config) => {
//   // // Get event id from URL
//   const invoiceId = Number(config.url.substring(config.url.lastIndexOf('/') + 1))

//   const invoiceIndex = data.invoices.findIndex((e) => e.id === invoiceId)
//   const responseData = {
//     invoice: data.invoices[invoiceIndex],
//     paymentDetails: {
//       totalDue: '$12,110.55',
//       bankName: 'American Bank',
//       country: 'United States',
//       iban: 'ETD95476213874685',
//       swiftCode: 'BR91905'
//     }
//   }
//   return [200, responseData]
// })

// ------------------------------------------------
// DELETE: Deletes Invoice
// ------------------------------------------------
mock.onDelete('/apps/invoice/delete').reply((config) => {
  // Get invoice id from URL
  let invoiceId = config.id

  // Convert Id to number
  invoiceId = Number(invoiceId)

  const invoiceIndex = data.invoices.findIndex((t) => t.id === invoiceId)
  data.invoices.splice(invoiceIndex, 1)

  return [200]
})

// ------------------------------------------------
// GET: Return Clients
// ------------------------------------------------
mock.onGet('/api/invoice/clients').reply(() => {
  const clients = data.invoices.map((invoice) => invoice.client)
  return [200, clients.slice(0, 5)]
})

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import VehicleService from '@services/vehicle'

export const getAllVehicleEmission = createAsyncThunk('emission/getAllVehicleEmission', async (params, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getAllVehicleEmission(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getVehicleEmissionById = createAsyncThunk('emission/getVehicleEmission', async (id, { rejectWithValue }) => {
  try {
    const response = await VehicleService.getVehicleEmission(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addVehicleEmission = createAsyncThunk('emission/addVehicleEmission', async (emissionData, { rejectWithValue }) => {
  try {
    const response = await VehicleService.addVehicleEmission(emissionData)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateEmissionAttachment = createAsyncThunk('emission/updateEmissionAttachment', async (data, { rejectWithValue }) => {
  try {
    const id = data.get('id')
    const response = await VehicleService.updateEmissionAttachment(id, data)
    return response.data
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

// export const updateVehicleCancellationAttachment = createAsyncThunk('fitness/updateVehicleCancellationAttachment', async (data, { rejectWithValue }) => {
//   try {
//     const id = data.get('id')
//     const response = await VehicleService.updateVehicleCancellationAttachment(id, data)
//     return response.data
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

// export const updateVehicleCancellation = createAsyncThunk('fitness/updateVehicleCancellation', async (cancellation, { rejectWithValue, dispatch, getState }) => {
//   try {
//     const response = await VehicleService.updateVehicleCancellation(cancellation)
//     dispatch(getAllVehicleCancellation(getState()?.vehicleCancellations.params))
//     return response
//   } catch (err) {
//     return rejectWithValue(err.response.data)
//   }
// })

// export const deleteVehicleCancellation = createAsyncThunk('fitness/deleteVehicleCancellation', async (id, { dispatch, getState }) => {
//   await VehicleService.deleteVehicleCancellation(id)
//   await dispatch(getAllVehicleCancellation(getState()?.vehicleCancellations.params))
//   return id
// })

const cancellation = createSlice({
  name: 'emission',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedEmission: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(getAllVehicleEmission.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selectedEmission = null
      })
      .addCase(getVehicleEmissionById.fulfilled, (state, action) => {
        state.selectedEmission = action.payload
      })
  }
})

export default cancellation.reducer

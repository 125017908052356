// // ** Navigation imports
// import apps from './apps'
import masters from './masters'
import users from './users'
import roles from './roles'
import pages from './pages'
// import forms from './forms'
// import tables from './tables'
// import others from './others'
// import charts from './charts'
import vehicle from './vehicle'
import dashboards from './dashboards'
import personalInformation from './pis-individual'
import vehicleCrashData from './vehicle-crash-data'
import learnerLicenseDetails from './learner-license'
import offence from './offence'
import drivingLicense from './driving-license'
import drivingTest from './driving-test'
import report from './report'
import payment from './payment'
import chargeCalculator from './charge-calculator'

// ** Merge & Export
// export default [...dashboards, ...chargeCalculator, ...personalInformation, ...offence, ...vehicle, ...learnerLicenseDetails, ...drivingTest, ...drivingLicense, ...users, ...roles, ...masters, ...payment, ...vehicleCrashData, ...pages]
export default [...dashboards, ...chargeCalculator, ...personalInformation, ...offence, ...vehicle, ...learnerLicenseDetails, ...drivingTest, ...drivingLicense, ...users, ...roles, ...masters, ...payment, ...vehicleCrashData, ...report, ...pages]

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import SafetySystemMasterService from '@services/vehicle-crash/masters/safety-system'

export const getSafetySystems = createAsyncThunk('color/getSafetySystems', async (params, rejectWithValue) => {
  try {
    const response = await SafetySystemMasterService.getSafetySystems(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addSafetySystem = createAsyncThunk('color/addSafetySystem', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await SafetySystemMasterService.addSafetySystem(color)
    dispatch(getSafetySystems(getState()?.safetySystems.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateSafetySystem = createAsyncThunk('color/updateSafetySystem', async (color, { rejectWithValue, dispatch, getState }) => {
  try {
    await SafetySystemMasterService.updateSafetySystem(color)
    dispatch(getSafetySystems(getState()?.safetySystems.params))
    return color
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const deleteSafetySystem = createAsyncThunk('color/deleteColor', async (id, { dispatch, getState }) => {
  await SafetySystemMasterService.deleteSafetySystem(id)
  await dispatch(getSafetySystems(getState()?.safetySystems.params))
  return id
})

const safetySystemSlice = createSlice({
  name: 'safetySystems',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},

  extraReducers(builder) {
    builder.addCase(getSafetySystems.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const selectSafetySystemById = (state, id) => state.safetySystems.data.find((safetySystem) => safetySystem.id === id)

export default safetySystemSlice.reducer

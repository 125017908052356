import api from '@services'

const serviceBaseUrl = process.env.REACT_APP_ADMIN_USER_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/admin-user/api`

const getRoles = async (params) => {
  const response = await api.get(`${servicePrefix}/roles`, { params })
  return response.data
}

const getAllRoles = async () => {
  const response = await api.get(`${servicePrefix}/roles?takeAll=true`)
  return response.data
}

const getAllRoleList = async () => {
  const response = await api.get(`${servicePrefix}/roles/all`)
  return response.data
}

const getRole = async (id) => {
  const response = await api.get(`${servicePrefix}/roles/${id}`)
  return response.data
}

const addRole = async (roles) => {
  const response = await api.post(`${servicePrefix}/roles`, roles)
  return response.data
}

const updateRoleIndividual = async (roles) => {
  const { id } = roles
  const response = await api.patch(`${servicePrefix}/roles/${id}`, roles)
  return response.data
}

const getUsersByRole = async (id) => {
  const response = await api.get(`${servicePrefix}/roles/${id}/users`)
  return response.data
}

const RoleService = {
  getRoles,
  getAllRoles,
  getAllRoleList,
  getRole,
  addRole,
  updateRoleIndividual,
  getUsersByRole
}

export default RoleService

// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ReportService from '@services/report'

export const generateReport = createAsyncThunk('reports/generateReport', async (params, { rejectWithValue }) => {
  try {
    console.log("whythis", params)
    const response = await ReportService.generateReport(params)
    return { params, data: response.data, totalPages: response.meta.itemCount }
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const addReportConfiguration = createAsyncThunk('reportConfigurations/addReportConfiguration', async (data, { rejectWithValue }) => {
  // await axios.post('/apps/users/add-user', user)

  try {
    const response = await ReportService.addReportConfiguration(data)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const updateReportConfiguration = createAsyncThunk('reportConfigurations/updateReportConfiguration', async (data, { rejectWithValue }) => {
  try {
    const { id } = data
    const response = await ReportService.updateDrivingTestConfiguration(id, data)

    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

export const getReportConfiguration = createAsyncThunk('reportConfigurations/reportConfiguration', async (id, { rejectWithValue }) => {
  try {
    const response = await ReportService.getReportConfiguration(id)
    return response
  } catch (err) {
    return rejectWithValue(err.response.data)
  }
})

const Reports = createSlice({
  name: 'reports',
  initialState: {
    reportGenerationStatus: '',
    data: [],
    columns: [],
    total: 1,
    params: {},
    allData: [],
    loading: true,
    errors: null
  },
  reducers: {},

  extraReducers(builder) {
    builder
      .addCase(generateReport.fulfilled, (state, action) => {
        if (action.payload.data.length > 0) {
          const firstItem = action.payload.data[0]
          state.columns = Object.keys(firstItem).map((key) => ({
            name: key,
            selector: key,
            sortable: true
          }))
        } else {
          state.columns = [] // If no data, reset columns to empty array
        }
        state.reportGenerationStatus = action.payload.data.length > 0 ? 'Report generated successfully' : 'No result for this query'
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(generateReport.pending, (state) => {
        state.data = []
        state.reportGenerationStatus = ""
        state.loading = true
        state.params = {}
        state.total = 0
      })
  }
})

export default Reports.reducer
